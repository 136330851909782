import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  Chip,
  Container,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { FormProvider, RHFTextField, RHFUploadSingleFile } from '../../components/hook-form';
import { makeStyles, useTheme } from '@material-ui/core';
import { SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import { CustomFile } from 'src/components/upload/type';
import { eventPrivateerService } from 'src/services/eventPrivateer';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { CSVLink } from 'react-csv';
import { divisionService } from 'src/services/masters/divisions';
import { DivisionList } from 'src/@types/master';
import { PATH_EVENTS } from 'src/routes/paths';
import { useDispatch, useSelector } from 'react-redux';
import { divisionListAction, DivisionState } from 'src/store/divisionSlice';
import { EventRaceList } from 'src/@types/events';
import Iconify from 'src/components/Iconify';
import Scrollbar from 'src/components/Scrollbar';
import { resultService } from 'src/services/results';
import LoadingScreen from 'src/components/LoadingScreen';
import { changeStatusAction } from 'src/store/eventSlice';

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  customAccordion: {
    padding: theme.spacing(0, 2.5),
    transition: theme.transitions.create('all'),
    margin: '5px 0px',
  },

  customAccordionSummary: {
    paddingLeft: 0,
    '& .MuiAccordionSummary-content': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginRight: '30px',
    },
  },
}));

// ----------------------------------------------------------------------

type Props = {
  id: number;
  currentEvent?: EventRaceList;
};

type FormValuesProps = {
  url: string;
  result: CustomFile | string | null;
};

type DivisionSelect = {
  id?: number;
  isExported: boolean;
  isUploaded?: boolean;
}[];

type CsvData = {
  name: string;
};

const headers = [
  { label: 'Position', key: 'position' },
  { label: 'Racer name', key: 'name' },
  { label: 'Hometown', key: 'hometown' },
  { label: 'Motos', key: 'motos' },
  { label: 'Manufacturer/Machine', key: 'manufacturer' },
  { label: 'Status', key: 'status' },
  { label: 'HoleShot', key: 'holeshot' }
];

const motosports_header = [
  { label: 'Position', key: 'position' },
  { label: 'Racer name', key: 'name' },
  { label: 'Hometown', key: 'hometown' },
  { label: 'Motos', key: 'motos' },
  { label: 'Manufacturer/Machine', key: 'manufacturer' },
  { label: 'Status', key: 'status' },
];

const team_sports_header = [
  { label: 'Team name', key: 'name' },
  { label: 'Status', key: 'status' },
];

export default function EventUploadResult({ id, currentEvent }: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const divisionStore = useSelector(
    (state: { division: DivisionState }) => state.division.divisions
  );
  const [csvData, setCsvData] = useState<CsvData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const [expanded, setExpanded] = useState<number | false>(0);
  const [exportStatus, setExportStatus] = useState<DivisionSelect>([]);
  const [progress, setProgress] = useState(true);
  const csvRef = useRef<any>();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [disabled, setDisable] = useState(false)

  useEffect(() => {
    console.log('')
    console.log('')
    console.log('')
    console.log("GETTING IN USE EFFECT")
    console.log(currentEvent)
    console.log(!divisionStore.length)
    if ((!divisionStore.length || divisionStore.length === 1) && currentEvent?.sportsId === 1) {
      console.log("INSIDE DIVISION")
      divisionService
        .getDivisionList({})
        .then((data) => {
          dispatch(divisionListAction({ divisions: data?.result, totalDivisions: data?.count }));
          const temp = data?.result.map((div: DivisionList) => ({
            id: div.id,
            isExported: false,
            isUploaded: false,
          }));
          console.log(temp)
          setProgress(false);
          setExportStatus(temp);
        })
        .catch((error) => {
          enqueueSnackbar(error.response.data.message, { variant: 'error' });
        });
    }

    else if (currentEvent?.sportsId != 1) {
      setExportStatus([{
        isExported: false
      }]);

      setProgress(false);
    }

    else {
      setProgress(false);
      const temp = divisionStore?.map((div: DivisionList) => ({
        id: div.id,
        isExported: false,
        isUploaded: false,
      }));
      setExportStatus(temp);
    }
    console.log('')
    console.log('')
    console.log('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const UploadResultSchema = Yup.object().shape({
    // url: Yup.string().trim().required('URL is required').max(280, 'Too long url'),
    result: Yup.mixed().required('File is required'),
  });

  const defaultValues = {
    url: '',
    result: null,
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(UploadResultSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    resetField,
    formState: { isSubmitting },
    reset,
  } = methods;

  const getSelectedRacers = async (division?: number) => {
    console.log('')
    console.log('')
    console.log("getting selected riders!")
    console.log('')
    console.log('')
    setIsLoading(true);
    try {
      if (!!division) {
        const data = await eventPrivateerService.getRacers(id, division);

        let modifiedList;

        if (currentEvent?.conductedBy === 'Motocross') {
          modifiedList = data.result.map((racer: any) => ({
            name: racer.privateer_data.name,
            status: '1',
            holeshot: '0'
          }));
        } else {
          modifiedList = data.result.map((racer: any) => ({
            racer_id: racer.racerId,
            rider: racer.privateer_data.name,
            status: '1',
            holeshot: '0'
          }));
        }

        setCsvData(modifiedList);
        setIsLoading(false);

        let temp = [...exportStatus];
        let index = temp.findIndex((div) => div.id === division);
        temp[index].isExported = true;
        setExportStatus(temp);
        setExpanded(division);

        setTimeout(() => {
          csvRef.current.link.click();
        });
      }
      else {
        console.log('')
        console.log('')
        console.log("UPLOADING HERE", id, currentEvent?.sportsId)
        const sid = currentEvent?.sportsId
        const data = await eventPrivateerService.getRacersBySportId(id, sid);
        let modifiedList;
        modifiedList = data.result.map((racer: any) => ({
          name: racer.privateer_data.name,
          status: '1'
        }));
        setCsvData(modifiedList);
        setIsLoading(false);
        let temp = [...exportStatus];
        temp[0].isExported = true;
        setExportStatus(temp);
        setTimeout(() => {
          csvRef.current.link.click();
        });

        console.log('')
        console.log('')
      }
    } catch (error) {
      console.log(error)
      setIsLoading(false);
      enqueueSnackbar(`${error.response.data.message}. Please add racers.`, { variant: 'error' });
    }
  };

  const getSelectedTeams = async (division?: number) => {
    setIsLoading(true);
    try {
      console.log('')
      console.log('')
      console.log("UPLOADING HERE", id, currentEvent?.sportsId)
      const sid = currentEvent?.sportsId
      const data = await eventPrivateerService.getRacersBySportId(id, sid);
      console.log(data)
      let modifiedList;
      modifiedList = data.result.map((team: any) => ({
        name: team.teams_data.name,
        status: 'winner or loser'
      }));
      setCsvData(modifiedList);
      setIsLoading(false);
      let temp = [...exportStatus];
      temp[0].isExported = true;
      setExportStatus(temp);
      setTimeout(() => {
        csvRef.current.link.click();
      });

    } catch (error) {
      console.log(error)
      setIsLoading(false);
      enqueueSnackbar(`${error.response.data.message}. Please add racers.`, { variant: 'error' });
    }
  };

  const onSubmit = async (data: FormValuesProps, divisionName: string) => {
    try {
      console.log('')
      console.log('uploading results!')
      console.log(data.result)
      await resultService.uploadResult(divisionName, id.toString(), data.url, data.result);
      reset();
      let temp = [...exportStatus];
      const division = divisionStore.find((div) => div.name === divisionName);
      let index = temp.findIndex((div) => div.id === division?.id);
      temp[index].isUploaded = true;
      setExportStatus(temp);
      setExpanded(false);
      enqueueSnackbar('Result Uploaded Successfully');
    } catch (error) {
      enqueueSnackbar(error.response.data.message, { variant: 'error' });
    }
  };

  const onComputeResult = async () => {
    setDisable(true)
    setSelectedId(0);
    setIsLoading(true);
    try {
      const result = await resultService.computeResult(id);
      console.log(result)
      dispatch(changeStatusAction(id));
      setIsLoading(false);
      enqueueSnackbar(result.message);
      navigate(PATH_EVENTS.root);
    } catch (error) {
      console.log(error)
      console.log(error, 'error catch')
      setIsLoading(false);
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
    }
  };

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          'result',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  const handleRemove = () => {
    resetField('result');
  };

  const handleChange = (panel: number) => (event: SyntheticEvent, newExpanded: boolean) => {
    reset();
    const division = exportStatus.find((div) => div.id === panel);
    if (division?.isUploaded) {
      setExpanded(false);
    } else {
      setExpanded(newExpanded ? panel : false);
    }
  };
  console.log("exportStatus -", exportStatus)
  console.log("currentEvent -", currentEvent)
  console.log("csvData -", csvData)
  console.log("csvRef -", csvRef)
  console.log(divisionStore)

  return (
    <Container maxWidth={'xl'}>
      {progress ? (
        <LoadingScreen isDashboard={true} />
      ) : (
        <Grid container spacing={3} sx={{ mt: 12 }}>
          <Grid item xs={12} md={12}>
            {
              currentEvent?.sports_data?.class === 'Dirt'
              &&
              divisionStore?.map((division) => (
                <Card key={division.id} sx={{ mb: 2, border: `solid 1px ${theme.palette.divider}` }}>
                  <Accordion
                    className={classes.customAccordion}
                    expanded={expanded === division.id}
                    onChange={handleChange(division.id)}
                  >
                    <AccordionSummary
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                      className={classes.customAccordionSummary}
                      expandIcon={<Iconify icon={'dashicons:arrow-down-alt2'} />}
                    >
                      <Typography variant="h6">Class: {division.name}</Typography>
                      {exportStatus.some((obj) => obj.id === division.id && !obj.isExported) && (
                        <Stack alignItems="flex-end">
                          <LoadingButton
                            variant="contained"
                            loading={selectedId === division.id ? isLoading : false}
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedId(division.id);
                              getSelectedRacers(division.id);
                            }}
                          >
                            <CSVLink
                              headers={headers}
                              filename="Result.csv"
                              data={csvData}
                              ref={csvRef}
                              onClick={(e: any) => {
                                e.stopPropagation();
                              }}
                            />
                            Export CSV
                          </LoadingButton>
                        </Stack>
                      )}

                      {exportStatus.some((obj) => obj.id === division.id && obj.isUploaded) && (
                        <Chip label="Uploaded" />
                      )}
                    </AccordionSummary>
                    
                    {exportStatus.some((obj) => obj.id === division.id && obj.isExported) ? (
                      <Scrollbar sx={{ overflowX: 'hidden' }}>
                        <AccordionDetails sx={{ maxHeight: '500px' }}>
                          <FormProvider
                            methods={methods}
                            onSubmit={handleSubmit((data) => onSubmit(data, division.name))}
                          >
                            {/* <RHFTextField name="url" label="Event URL" /> */}
                            <Box sx={{ pt: 3 }}>
                              <RHFUploadSingleFile
                                name="result"
                                accept={{ 'text/csv': [] }}
                                onDrop={handleDrop}
                                onRemove={handleRemove}
                              />
                            </Box>
                            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                              <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
                                <CSVLink
                                  headers={motosports_header}
                                  filename="Result.csv"
                                  data={csvData}
                                  ref={csvRef}
                                  onClick={(e: any) => {
                                    e.stopPropagation();
                                  }}
                                />
                                Upload Result
                              </LoadingButton>
                            </Stack>
                          </FormProvider>
                        </AccordionDetails>
                      </Scrollbar>
                    ) : (
                      <AccordionDetails>
                        <Typography>Please export CSV for the result.</Typography>
                      </AccordionDetails>
                    )}
                  </Accordion>
                </Card>
              ))
            }
            {

              currentEvent?.sports_data?.class === 'Formula1'
              &&
              <Card sx={{ mb: 2, border: `solid 1px ${theme.palette.divider}` }}>
                <Accordion
                  className={classes.customAccordion}
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                    className={classes.customAccordionSummary}
                    expandIcon={<Iconify icon={'dashicons:arrow-down-alt2'} />}
                  >
                    <Typography variant="h6">Upload results for f1 like races here please :)</Typography>
                    {exportStatus.some((obj) => !obj.isExported) && (
                      <Stack alignItems="flex-end">
                        <LoadingButton
                          variant="contained"
                          loading={isLoading}
                          onClick={(e) => {
                            e.stopPropagation();
                            //setSelectedId(division.id);
                            getSelectedRacers();
                          }}
                        >
                          <CSVLink
                            headers={motosports_header}
                            filename="Result.csv"
                            data={csvData}
                            ref={csvRef}
                            onClick={(e: any) => {
                              e.stopPropagation();
                            }}
                          />
                          Export CSV
                        </LoadingButton>
                      </Stack>
                    )}

                    {exportStatus.some((obj) => obj.isUploaded) && (
                      <Chip label="Uploaded" />
                    )}
                  </AccordionSummary>
                  {exportStatus.some((obj) => obj.isExported) ? (
                    <Scrollbar sx={{ overflowX: 'hidden' }}>
                      <AccordionDetails sx={{ maxHeight: '500px' }}>
                        <FormProvider
                          methods={methods}
                          onSubmit={handleSubmit((data) => onSubmit(data, 'test'))}
                        >
                          {/* <RHFTextField name="url" label="Event URL" /> */}
                          <Box sx={{ pt: 3 }}>
                            <RHFUploadSingleFile
                              name="result"
                              accept={{ 'text/csv': [] }}
                              onDrop={handleDrop}
                              onRemove={handleRemove}
                            />
                          </Box>
                          <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                            <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
                              <CSVLink
                                headers={
                                  currentEvent?.sportsId === 0
                                    ? headers
                                    : motosports_header
                                }
                                filename="Result.csv"
                                data={csvData}
                                ref={csvRef}
                                onClick={(e: any) => {
                                  e.stopPropagation();
                                }}
                              />
                              Upload Result
                            </LoadingButton>
                          </Stack>
                        </FormProvider>
                      </AccordionDetails>
                    </Scrollbar>
                  ) : (
                    <AccordionDetails>
                      <Typography>Please export CSV for the result.</Typography>
                    </AccordionDetails>
                  )}
                </Accordion>
              </Card>
            }
            {

              currentEvent?.sports_data?.class === 'Team'
              &&
              <Card sx={{ mb: 2, border: `solid 1px ${theme.palette.divider}` }}>
                <Accordion
                  className={classes.customAccordion}
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                    className={classes.customAccordionSummary}
                    expandIcon={<Iconify icon={'dashicons:arrow-down-alt2'} />}
                  >
                    <Typography variant="h6">Upload results for team here please :)</Typography>
                    {exportStatus.some((obj) => !obj.isExported) && (
                      <Stack alignItems="flex-end">
                        <LoadingButton
                          variant="contained"
                          loading={isLoading}
                          onClick={(e) => {
                            e.stopPropagation();
                            //setSelectedId(division.id);
                            getSelectedTeams();
                          }}
                        >
                          <CSVLink
                            headers={team_sports_header}
                            filename="Result.csv"
                            data={csvData}
                            ref={csvRef}
                            onClick={(e: any) => {
                              e.stopPropagation();
                            }}
                          />
                          Export CSV
                        </LoadingButton>
                      </Stack>
                    )}

                    {exportStatus.some((obj) => obj.isUploaded) && (
                      <Chip label="Uploaded" />
                    )}
                  </AccordionSummary>
                  {exportStatus.some((obj) => obj.isExported) ? (
                    <Scrollbar sx={{ overflowX: 'hidden' }}>
                      <AccordionDetails sx={{ maxHeight: '500px' }}>
                        <FormProvider
                          methods={methods}
                          onSubmit={handleSubmit((data) => onSubmit(data, 'test'))}
                        >
                          {/* <RHFTextField name="url" label="Event URL" /> */}
                          <Box sx={{ pt: 3 }}>
                            <RHFUploadSingleFile
                              name="result"
                              accept={{ 'text/csv': [] }}
                              onDrop={handleDrop}
                              onRemove={handleRemove}
                            />
                          </Box>
                          <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                            <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
                              <CSVLink
                                headers={team_sports_header}
                                filename="Result.csv"
                                data={csvData}
                                ref={csvRef}
                                onClick={(e: any) => {
                                  e.stopPropagation();
                                }}
                              />
                              Upload Result
                            </LoadingButton>
                          </Stack>
                        </FormProvider>
                      </AccordionDetails>
                    </Scrollbar>
                  ) : (
                    <AccordionDetails>
                      <Typography>Please export CSV for the result.</Typography>
                    </AccordionDetails>
                  )}
                </Accordion>
              </Card>
            }

            <Stack alignItems="flex-end" mt={5}>
              <LoadingButton
                variant="contained"
                loading={selectedId === 0 && isLoading}
                onClick={onComputeResult}
                disabled={disabled}
              >
                Compute Result
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      )}
    </Container>
  );
}
