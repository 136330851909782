import axios from 'axios';
import { baseEndpoints } from './config';

const getUserList = async (body: any) => {
  const { data } = await axios.post(`${baseEndpoints.users}/list`, body);

  return data;
};

const getUserById = async (id: number) => {
  const { data } = await axios.post(`${baseEndpoints.users}/get/${id}`, {});

  return data;
};

const editUserStatus = async (id: number, body: { isActive: boolean }) => {
  const { data } = await axios.post(`${baseEndpoints.users}/update-status/${id}`, body);

  return data;
};

const updateUser = async (id: number, body: { isActive: boolean }) => {
  const { data } = await axios.post(`${baseEndpoints.users}/update/${id}`, body);

  return data;
};

const deleteUser = async (id: number) => {
  const { data } = await axios.post(`${baseEndpoints.users}/delete/${id}`, {});

  return data;
};

const deleteMultipleUser = async (body: { ids: number[] }) => {
  const { data } = await axios.post(`${baseEndpoints.users}/multidelete`, body);

  return data;
};

const listReferredUser = async (id: number, body: any) => {
  const { data } = await axios.post(`${baseEndpoints.users}/referral-users/${id}`, body);

  return data;
};

const searchReferredUser = async (id: number, body: any) => {
  const { data } = await axios.post(`${baseEndpoints.users}/referral-users/search/${id}`, body);

  return data;
};

const searchUser = async (body: any) => {
  const { data } = await axios.post(`${baseEndpoints.users}/search`, body);

  return data;
};

const listSubscriptionHistory = async (id: number, body: any) => {
  const { data } = await axios.post(`${baseEndpoints.users}/subscription-history/${id}`, body);

  return data;
};

const getBillingDetails = async (id: number) => {
  const { data } = await axios.post(`${baseEndpoints.users}/billing-details/${id}`, {});

  return data;
};

export const userService = {
  getUserList,
  getUserById,
  editUserStatus,
  deleteUser,
  deleteMultipleUser,
  listReferredUser,
  searchReferredUser,
  searchUser,
  listSubscriptionHistory,
  getBillingDetails,
  updateUser
};
