import { forwardRef, ReactNode } from 'react';
// @mui
import { Container, ContainerProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import useResponsive from 'src/hooks/useResponsive';
import useCollapseDrawer from 'src/hooks/useCollapseDrawer';

// ----------------------------------------------------------------------

const RootStyle = styled(Container)(({ theme }) => ({
  position: 'fixed',
  top: '58px',
  zIndex: 1000,
  backgroundColor: 'white',
  paddingTop: '38px',
  paddingBottom: '38px',
}));

// ----------------------------------------------------------------------

interface Props extends ContainerProps {
  children: ReactNode;
}

const CustomConatiner = forwardRef<HTMLDivElement, Props>(({ children, ...other }, ref) => {
  const isDesktop = useResponsive('up', 'lg');
  const { collapseClick } = useCollapseDrawer();

  return (
    <RootStyle
      {...other}
      sx={
        isDesktop
          ? collapseClick
            ? { width: 'calc(100% - 148px)' }
            : { width: 'calc(100% - 340px)' }
          : { width: '100%' }
      }
    >
      {children}
    </RootStyle>
  );
});

export default CustomConatiner;
