import {
  Container,
  Card,
  TableContainer,
  Table,
  TableBody,
  TablePagination,
  FormControlLabel,
  Switch,
  Box,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Wagers } from 'src/@types/events';
import LoadingScreen from 'src/components/LoadingScreen';
import Scrollbar from 'src/components/Scrollbar';
import { TableHeadCustom, TableNoData } from 'src/components/table';
import useTable, { getComparator } from 'src/hooks/useTable';
import { eventService } from 'src/services/events';
import UserTableToolbar from '../Users/UserTableToolbar';
import LinkedWagerTableRow from './LinkedWagerTableRow';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'Sr.No', align: 'center' },
  { id: 'type', label: 'Wager Type', align: 'center' },
  { id: 'placement', label: 'Placement', align: 'center' },
  { id: 'createdBy_data', label: 'Created By', align: 'center' },
  { id: 'chipsBet', label: 'Total Bids', align: 'center' },
  { id: '', label: 'Action', align: 'center' }
];

// ----------------------------------------------------------------------

type Props = {
  id: number;
};

export default function EventLinkedWagers({ id }: Props) {
  const { dense, order, orderBy, onChangeDense, selected } = useTable();

  const [tableData, setTableData] = useState<Wagers[]>([]);
  const [wagersCount, setWagersCount] = useState<number>(0);
  const [loader, setLoader] = useState(true);
  const [filterName, setFilterName] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [voidSatus, setVoidStatus] = useState(false)
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    eventService
      .getWagersByEventId(id, {
        skip: 0,
        limit: rowsPerPage,
      })
      .then((data) => {
        setTableData(data.result);
        setWagersCount(data.count);
        setLoader(false);
      setVoidStatus(false)

      })
      .catch((error) => {
        setTableData([]);
        setWagersCount(0);
        setLoader(false);
        if(!voidSatus){
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        setVoidStatus(false)

        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voidSatus]);

  const onChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rows = parseInt(event.target.value);

    setLoader(true);
    eventService
      .getWagersByEventId(id, {
        skip: 0,
        limit: rows,
      })
      .then((data) => {
        setTableData(data.result);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
      });

    setRowsPerPage(rows);
    setPage(0);
  };

  const onChangePage = (event: unknown, newPage: number) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    if (page < newPage) {
      if (tableData.length > newPage * rowsPerPage) {
        setPage(newPage);
        return;
      }
      setLoader(true);
      eventService
        .getWagersByEventId(id, {
          skip: newPage * rowsPerPage,
          limit: rowsPerPage,
        })
        .then((data) => {
          setTableData((prevState) => [...prevState, ...data.result]);
          setLoader(false);
        })
        .catch((error) => {
          setLoader(false);
          enqueueSnackbar(error.response.data.message, { variant: 'error' });
        });
    }

    setPage(newPage);
  };

  const handleFilterName = (filterName: string) => {
    setFilterName(filterName);

    if (filterName?.length >= 3) {
      setLoader(true);

      eventService
        .searchWagersByEventId(id, { search: filterName })
        .then((data) => {
          setTableData(data.result);
          setWagersCount(data.result.length);
          setLoader(false);
        })
        .catch((error) => {
          setLoader(false);
          enqueueSnackbar(error.response.data.message, { variant: 'error' });
        });
    }

    if (!filterName) {
      setLoader(true);
      eventService
        .getWagersByEventId(id, {
          skip: 0,
          limit: rowsPerPage,
        })
        .then((data) => {
          setTableData(data.result);
          setWagersCount(data.count);
          setLoader(false);
        })
        .catch((error) => {
          setLoader(false);
          enqueueSnackbar(error.response.data.message, { variant: 'error' });
        });
    }

    setPage(0);
  };

  const handleVoid = (id:any) => {
    setLoader(true);
    eventService.voidWager(id).then((res) => {
      enqueueSnackbar('Wager void successfull', { variant: 'success' });
      setVoidStatus(true)
    }).catch((error)=>{
      enqueueSnackbar(error.response.data.message, { variant: 'error' });
      setVoidStatus(false)
    })
  }

  const dataFiltered = applySortFilter({
    eventRaceList: tableData,
    comparator: getComparator(order, orderBy),
    filterName,
  });

  const isNotFound = !tableData.length;

  return (
    <Container maxWidth={'xl'} sx={{ mt: 12 }}>
      {loader && <LoadingScreen isDashboard={true} />}
      <Card>
        <UserTableToolbar
          placeholder="Search by user..."
          isSearch={false}
          filterName={filterName}
          onFilterName={handleFilterName}
        />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
            <Table size={dense ? 'small' : 'medium'}>
              <TableHeadCustom
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={tableData.length}
                minWidth={'128px'}
              />

              <TableBody>
                {dataFiltered ? (
                  dataFiltered
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <LinkedWagerTableRow
                        key={row.id}
                        index={page === 0 ? index : rowsPerPage * page + index}
                        row={row}
                        selected={selected.includes(row.id)}
                        onVoid={(id:any) => handleVoid(id)}
                      />
                    ))
                ) : (
                  <></>
                )}

                <TableNoData isNotFound={isNotFound} />
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        <Box sx={{ position: 'relative' }}>
          <TablePagination
            rowsPerPageOptions={[5, 15, 25]}
            component="div"
            count={wagersCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
          />

          <FormControlLabel
            control={<Switch checked={dense} onChange={onChangeDense} />}
            label="Dense"
            sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
          />
        </Box>
      </Card>
    </Container>
  );
}

// ----------------------------------------------------------------------

function applySortFilter({
  eventRaceList,
  comparator,
  filterName,
}: {
  eventRaceList?: Wagers[];
  comparator: (a: any, b: any) => number;
  filterName: string;
}) {
  const stabilizedThis = eventRaceList?.map((el, index) => [el, index] as const);

  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  eventRaceList = stabilizedThis?.map((el) => el[0]);

  return eventRaceList;
}
