import { Stack, InputAdornment, TextField } from '@mui/material';
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

type Props = {
  filterName: string;
  onFilterName: (value: string) => void;
  isSearch?: boolean;
  placeholder?: string;
  onBlurField?: (value: string) => void;
};

export default function UserTableToolbar({
  filterName,
  onFilterName,
  isSearch,
  placeholder,
  onBlurField,
}: Props) {
  return (
    <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} sx={{ py: 2.5, px: 3 }}>
      <TextField
        disabled={isSearch}
        fullWidth
        value={filterName}
        onChange={(event) => onFilterName(event.target.value)}
        onBlur={(event) => {
          if (onBlurField) {
            onBlurField(event.target.value);
          }
        }}
        placeholder={placeholder ? placeholder : 'Search...'}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify
                icon={'eva:search-fill'}
                sx={{ color: 'text.disabled', width: 20, height: 20 }}
              />
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
}
