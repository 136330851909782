import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RacerList } from 'src/@types/master';

export interface RacerState {
  racers: RacerList[];
  totalRacers: number;
}

const initialState: RacerState = {
  racers: [],
  totalRacers: 0,
};

export const racerSlice = createSlice({
  name: 'racer',
  initialState,
  reducers: {
    racerListAction: (state, action: PayloadAction<RacerState>) => {
       if (!state.racers.length) {
        state.racers = action.payload.racers;
      } else {
         state.racers = [...state.racers, ...action.payload.racers];
      }

       state.totalRacers = action.payload.totalRacers;
    },
    resetAndListRacerListAction: (state, action: PayloadAction<RacerState>) => {
      // Replace the existing racers array with the new one from the action's payload
    
      state.racers = action.payload.racers;
      // Update the totalRacers state with the new value from the action's payload
      state.totalRacers = action.payload.totalRacers;
    },    
    createRacerAction: (state, action: PayloadAction<RacerList>) => {
      state.racers.unshift(action.payload);
      state.totalRacers += 1;
    },
    deleteRacerAction: (state, action: PayloadAction<number>) => {
      const index = state.racers.findIndex((racer) => racer.id === action.payload);
      state.racers.splice(index, 1);
      state.totalRacers -= 1;
    },
    editRacerAction: (state, action: PayloadAction<any>) => {
      const index = state.racers.findIndex((racer) => racer.id === action.payload.id);
      state.racers[index] = action.payload;
    },
    emptyRacerListAction: (state) => {
      state.racers = [];
      state.totalRacers = 0;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  racerListAction,
  createRacerAction,
  deleteRacerAction,
  editRacerAction,
  emptyRacerListAction,
  resetAndListRacerListAction
} = racerSlice.actions;

export const racerReducer = racerSlice.reducer;
