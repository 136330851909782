import axios from 'axios';
import { EventRaceListBody } from 'src/@types/events';
import { baseEndpoints } from './config';

const getEventsList = async (body: any) => {
  const { data } = await axios.post(`${baseEndpoints.events}/list`, body);

  return data;
};

const getEventById = async (id: number) => {
  const { data } = await axios.post(`${baseEndpoints.events}/get/${id}`);

  return data;
};

const addEvent = async (body: EventRaceListBody) => {
  const { data, status } = await axios.post(`${baseEndpoints.events}/add`, body);

  return { data: data, status: status };
};

const deleteEvent = async (id: number) => {
  const { data } = await axios.delete(`${baseEndpoints.events}/delete/${id}`);

  return data;
};

const editEvent = async (id: number, body: EventRaceListBody) => {
  const { data } = await axios.post(`${baseEndpoints.events}/edit/${id}`, body);

  return data;
};

const getWagersByEventId = async (id: number, body: any) => {
  const { data } = await axios.post(`${baseEndpoints.wagers}/list/${id}`, body);

  return data;
};

const voidWager = async (id: number) => {
  const { data } = await axios.get(`${baseEndpoints.wagers}/cancelWager/${id}`);

  return data;
};

const getResultsByEventId = async (id: number, body: any) => {
  const { data } = await axios.post(`${baseEndpoints.results}/list/${id}`, body);

  return data;
};

const deleteMultipleEvents = async (body: { ids: number[] }) => {
  const { data } = await axios.post(`${baseEndpoints.events}/multidelete`, body);

  return data;
};

const searchEvent = async (body: { search: string }) => {
  const { data } = await axios.post(`${baseEndpoints.events}/search`, body);

  return data;
};

const searchWagersByEventId = async (id: number, body: { search: string }) => {
  const { data } = await axios.post(`${baseEndpoints.wagers}/search/${id}`, body);

  return data;
};

const searchResultsByEventId = async (id: number, body: any) => {
  const { data } = await axios.post(`${baseEndpoints.results}/search/${id}`, body);

  return data;
};

export const eventService = {
  getEventsList,
  getEventById,
  deleteEvent,
  getWagersByEventId,
  addEvent,
  editEvent,
  getResultsByEventId,
  deleteMultipleEvents,
  searchEvent,
  searchWagersByEventId,
  searchResultsByEventId,
  voidWager
};
