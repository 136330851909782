import axios from 'axios';
import { Ads, AdsListBody } from 'src/@types/ads';
import { baseEndpoints } from './config';

const getAdsList = async (body: any) => {
  //const { data } = await axios.get(`${baseEndpoints.ads}/getall/1`, body);
  const { data } = await axios.get(`${baseEndpoints.ads}/getall/1`, body);
  console.log(data)
  return data;
};

const createAd = async (body: AdsListBody) => {
  const { data, status } = await axios.post(`${baseEndpoints.ads}/create`, body);
  return { data: data, status: status };
};
const deleteAd = async (id: number) => {
  const { data, status } = await axios.delete(`${baseEndpoints.ads}/delete/${id}`);
  return { data: data, status: status };
};
const updateAd = async (id: number, body: AdsListBody) => {
  const { data, status } = await axios.post(`${baseEndpoints.ads}/update/${id}`, body);
  return { data: data, status: status };
};
const getAdById = async (id: number) => {
  const { data } = await axios.get(`${baseEndpoints.ads}/getadbyid/${id}`);
  return data;
};
const adsAnalytics = async (id: number) => {
  const { data } = await axios.get(`${baseEndpoints.ads}/analytics/${id}`)
  return data;
}

export const adService = {
  createAd,
  getAdsList,
  deleteAd,
  updateAd,
  getAdById,
  adsAnalytics
};
