import * as Yup from 'yup';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Autocomplete, Box, Card, Container, Grid, Stack, TextField } from '@mui/material';
import { FormProvider, RHFSelect, RHFTextField } from '../../components/hook-form';
import { EventRaceList, EventRaceListBody } from 'src/@types/events';
import { DateTimePicker } from '@mui/x-date-pickers';
import { useNavigate } from 'react-router';
import { eventService } from 'src/services/events';
import { useDispatch } from 'react-redux';
import { createEventAction, editEventAction } from 'src/store/eventSlice';
import { PATH_EVENTS } from 'src/routes/paths';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { SportState } from 'src/store/sportsSlice';

// ----------------------------------------------------------------------

type Props = {
  isEdit: boolean;
  isView: boolean;
  id: number;
  currentEvent?: EventRaceList;
  onModifyTabs: Function;
};

export default function EventGeneral({ isEdit, isView, id, currentEvent, onModifyTabs }: Props) {
  const dispatch = useDispatch();
  const conductedList = ['Motocross', 'Supercross'];
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const sports = useSelector((state: { sports: SportState }) => state.sports);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [sportSelected, setSportSelected] = useState<any>({ type: 'event' });

  useEffect(() => {
    if (isEdit && currentEvent) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    eventService
      .getEventsList({})
      .then((data) => { })
      .catch((error) => {
        console.log(error)
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentEvent]);

  const NewRaceSchema = Yup.object().shape({
    name: Yup.string().trim().required('Name is required'),
    startTime: isEdit
      ? Yup.date().typeError('Please enter a valid date').required('Start Time is required')
      : Yup.date()
        .typeError('Please enter a valid date')
        .min(new Date(), 'Date cannot be in the past')
        .required('Start Time is required'),
    endTime: Yup.date()
      .typeError('Please enter a valid date')
      .test({
        message: 'End Date should be greater than start date',
        test: function (value) {
          if (typeof value !== 'undefined') {
            if (value > this.parent.startTime) {
              return true;
            }
          }
          return false;
        },
      })
      .required('End Time is required'),
    conductedBy: Yup.string().required('Conducted By is required'),
    //totalRacers: Yup.number().min(1, 'Number of racers is required'),
  });

  const defaultValues = useMemo(
    () => ({
      name: currentEvent?.name || '',
      startTime: currentEvent?.startTime || '',
      sportsId: currentEvent?.sportsId || 1,
      endTime: currentEvent?.endTime || '',
      conductedBy: currentEvent?.sportsId === 1 ? "Motocross" : "Formula1",
      totalRacers: currentEvent?.totalRacers || 0,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentEvent]
  );

  const methods = useForm<EventRaceListBody>({
    resolver: yupResolver(NewRaceSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: any) => {

    const body = sportSelected?.type === 'event'
      ?
      {
        name: data.name,
        sportsId: data.sportsId,
        startTime: new Date(data.startTime).toISOString(),
        endTime: new Date(data.endTime).toISOString(),
        conductedBy: data.conductedBy,
        totalRacers: data?.totalRacers,
      }
      :
      {
        name: data.name,
        sportsId: data.sportsId,
        startTime: new Date(data.startTime).toISOString(),
        endTime: new Date(data.endTime).toISOString(),
        conductedBy: data.conductedBy,
        totalRacers: 2,
      }
    console.log('')
    console.log('')
    console.log('')
    console.log('CHECKER')
    console.log(body)
    console.log('')
    console.log('')
    console.log('')
    if (isEdit) {
      try {
        const result = await eventService.editEvent(id, body);
        dispatch(editEventAction(result.result));
        reset();
        enqueueSnackbar('Update success!');
        navigate(PATH_EVENTS.root);
      } catch (error) {
        console.error(error);
        reset();
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
      }
    }
    if (!isEdit && !isView) {
      try {
        const result = await eventService.addEvent(body);

        if (result.status === 200) {
          reset();
          console.log('results -', result.data.result)
          dispatch(createEventAction(result.data.result));
          enqueueSnackbar('Create success! Please add Racers now.');
          onModifyTabs(result.data.result);
        }
      } catch (error) {
        console.error(error);
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
      }
    }
  };
  //CREATE AN EVENT
  return (
    <Container maxWidth={'xl'}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3} sx={{ mt: 12 }}>
          <Grid item xs={12} md={8}>
            <Card sx={{ p: 3 }}>
              <Box
                sx={{
                  display: 'grid',
                  columnGap: 2,
                  rowGap: 3,
                  gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                }}
              >
                <Controller
                  name="name"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Autocomplete
                      disabled={isView}
                      freeSolo
                      fullWidth
                      options={suggestions}
                      onChange={(event, item) => {
                        onChange(item);
                      }}
                      value={value}
                      renderInput={(params: any) => (
                        <TextField
                          multiline
                          maxRows={5}
                          {...params}
                          onChange={(e) => {
                            onChange(e.target.value);
                          }}
                          value={value}
                          label="Event Name"
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  )}
                />

                <Controller
                  name="sportsId"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      variant='outlined'
                      id="outlined-select-currency-native"
                      select
                      label="Sport Selected"
                      onChange={(e) => {
                        console.log(e.target.value); // Logs the selected value

                        // Parse the value to a number if necessary, or compare as strings depending on how the IDs are set up
                        const selectedId = e.target.value;

                        // Find the item in the array that matches the id
                        const selectedItem = sports.sports.find(sport => sport.id.toString() === selectedId);

                        if (selectedItem) {
                          console.log(selectedItem); // Logs the matched item
                          // Call the onChange function with the found item
                          setSportSelected(selectedItem)
                          onChange(selectedItem?.id);
                        } else {
                          onChange(1);
                        }
                      }}
                      defaultValue={sports?.sports[0]?.id}
                      SelectProps={{
                        native: true,
                      }}
                      sx={{
                        minWidth: '200px'
                      }}
                    >
                      {sports.sports.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </TextField>
                  )}
                />

                <Controller
                  name="startTime"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <DateTimePicker
                      disabled={isEdit || isView}
                      {...field}
                      label="Start Time"
                      disablePast={!isEdit}
                      inputFormat="MM/dd/yyyy hh:mm a"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={!!error}
                          helperText={error?.message}
                          fullWidth
                        />
                      )}
                    />
                  )}
                />

                <Controller
                  name="endTime"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <DateTimePicker
                      disabled={isEdit || isView}
                      {...field}
                      label="End Time"
                      disablePast={!isEdit}
                      inputFormat="MM/dd/yyyy hh:mm a"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={!!error}
                          helperText={error?.message}
                          fullWidth
                        />
                      )}
                    />
                  )}
                />


                {
                  /*
                  <RHFSelect disabled={isView} name="conductedBy" label="Conducted By">
                  {conductedList?.map((conduct) => (
                    <option key={conduct} value={conduct}>
                      {conduct}
                    </option>
                  ))}
                </RHFSelect>
                  */
                }

                {
                  sportSelected?.type === 'event'
                  &&
                  <RHFTextField
                    disabled={isView}
                    name="totalRacers"
                    type="number"
                    label="Total Racers"
                  />
                }
              </Box>

              {!isView && (
                <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                  <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                    {isEdit ? 'Save Changes' : 'Add Event'}
                  </LoadingButton>
                </Stack>
              )}
            </Card>
          </Grid>
        </Grid>
      </FormProvider>
    </Container>
  );
}
