import axios from 'axios';
import { baseEndpoints } from './config';

const getAllSports = async () => {
  const { data } = await axios.get(`${baseEndpoints.sports}/getall`);
  return data;
};

export const sportsService = {
    getAllSports
};
