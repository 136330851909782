import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { UserList } from 'src/@types/user';

export interface UserState {
  users: UserList[];
  totalUsers: number;
}

const initialState: UserState = {
  users: [],
  totalUsers: 0,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    userListAction: (state, action: PayloadAction<UserState>) => {
      if (!state.users.length) {
        state.users = action.payload.users;
      } else {
        state.users = [...state.users, ...action.payload.users];
      }

      state.totalUsers = action.payload.totalUsers;
    },
    editUserAction: (state, action: PayloadAction<UserList>) => {
      const index = state.users.findIndex((user) => user.id === action.payload.id);
      if (index !== -1) {
        state.users.splice(index, 1);
        state.totalUsers -= 1;
      } else {
        state.users.push(action.payload);
        state.totalUsers += 1;
      }
    },
    deleteUserAction: (state, action: PayloadAction<number>) => {
      const index = state.users.findIndex((user) => user.id === action.payload);
      state.users.splice(index, 1);
    },
    emptyListAction: (state) => {
      state.users = [];
      state.totalUsers = 0;
    },
  },
});

// Action creators are generated for each case reducer function
export const { userListAction, editUserAction, emptyListAction, deleteUserAction } =
  userSlice.actions;

export const userReducer = userSlice.reducer;
