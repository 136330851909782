import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormProvider, RHFEditor } from 'src/components/hook-form';
import Page from 'src/components/Page';
import { cmsService } from 'src/services/cms';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import LoadingScreen from 'src/components/LoadingScreen';
import CustomConatiner from 'src/components/upload/CustomContainer';

// ----------------------------------------------------------------------

export default function PrivacyPolicy() {
  const [policy, setPolicy] = useState('');
  const [loader, setLoader] = useState(true);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    cmsService
      .getPrivacyPolicy()
      .then((data) => {
        setPolicy(data.result);
        setLoader(false);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        setLoader(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const NewPolicySchema = Yup.object().shape({
    description: Yup.string().required('Policy is required'),
  });

  const defaultValues = useMemo(
    () => ({
      description: policy,
    }),
    [policy]
  );

  const methods = useForm({
    resolver: yupResolver(NewPolicySchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (policy) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policy]);

  const onSubmit = async (data: any) => {
    const body = {
      content: data.description,
    };
    cmsService
      .updatePrivacyPolicy(body)
      .then((data) => {
        setPolicy(data.result);
        enqueueSnackbar('Update Successful');
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
      });
  };

  return !loader ? (
    <Page title="Privacy Policy">
      <CustomConatiner maxWidth={'xl'}>
        <Typography variant="h4" sx={{ mb: 2 }}>
          Privacy Policy
        </Typography>
      </CustomConatiner>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid sx={{ px: 3, pt: 9 }}>
          <RHFEditor simple name="description" />
          <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '10px' }}>
            <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
              Save Changes
            </LoadingButton>
          </div>
        </Grid>
      </FormProvider>
    </Page>
  ) : (
    <LoadingScreen />
  );
}
