import axios from 'axios';
import { baseEndpoints } from './config';

const getPrivacyPolicy = async () => {
  const { data } = await axios.get(`${baseEndpoints.cms}/privacy_policy`, {
    params: {
      isAdmin: true,
    },
  });

  return data;
};
const getTermsAndConditions = async () => {
  const { data } = await axios.get(`${baseEndpoints.cms}/terms_of_use`, {
    params: {
      isAdmin: true,
    },
  });

  return data;
};

const updatePrivacyPolicy = async (body: { content: string }) => {
  const { data } = await axios.post(`${baseEndpoints.cms}/update/privacy_policy`, body, {
    params: {
      isAdmin: true,
    },
  });

  return data;
};

const updateTermsAndConditions = async (body: { content: string }) => {
  const { data } = await axios.post(`${baseEndpoints.cms}/update/terms_of_use`, body, {
    params: {
      isAdmin: true,
    },
  });

  return data;
};

const getFaqs = async (body: any) => {
  const { data } = await axios.post(`${baseEndpoints.faqs}/list`, {});

  return data;
};

const addFaq = async (body: any) => {
  const { data } = await axios.post(`${baseEndpoints.faqs}/add`, body);

  return data;
};

const editFaq = async (id: number, body: any) => {
  const { data } = await axios.post(`${baseEndpoints.faqs}/edit/${id}`, body);

  return data;
};

const deleteFaq = async (id: number) => {
  const { data } = await axios.post(`${baseEndpoints.faqs}/delete/${id}`, {});

  return data;
};

export const cmsService = {
  getPrivacyPolicy,
  getTermsAndConditions,
  updatePrivacyPolicy,
  updateTermsAndConditions,
  getFaqs,
  addFaq,
  editFaq,
  deleteFaq,
};
