import { Suspense, lazy, ElementType, Children } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import LoadingScreen from '../components/LoadingScreen';
import Login from 'src/views/Auth/Login';
import ManufacturerCreate from 'src/views/Masters/Manufacturers/ManufacturerCreate';
import DivisionCreate from 'src/views/Masters/Divisions/DivisionCreate';
import RacerCreate from 'src/views/Masters/Racers/RacerCreate';
import MultiplierCreate from 'src/views/Masters/Multipliers/MultiplierCreate';
import UserEdit from 'src/views/Users/UserEdit';
import AuthGuard from 'src/guards/AuthGuard';
import EventRaceCreate from 'src/views/Events/EventRaceCreate';
import Requests from 'src/views/WithdrawalRequests/Requests';
import PrivacyPolicy from 'src/views/CMS/PrivacyPolicy';
import TermsConditions from 'src/views/CMS/TermsConditions';
import FAQ from 'src/views/CMS/FAQ';
import AdSystem from 'src/views/Ads/AdSystem';
import AdCreate from 'src/views/Ads/AdCreate';
import AdAnalytics from 'src/views/Ads/AdAnalytics';
import Team from 'src/views/Masters/Teams/Team';
import TeamCreate from 'src/views/Masters/Teams/TeamCreate';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <Navigate to="/auth/login" replace />,
    },
    {
      path: 'auth',
      children: [{ path: 'login', element: <Login /> }],
    },

    {
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: 'dashboard', element: <Dashboard /> },
        {
          path: 'users',
          children: [
            { path: '', element: <Users /> },
            { path: ':id/edit', element: <UserEdit /> },
            { path: ':id/view', element: <UserEdit /> },
          ],
        },
        {
          path: 'master',
          children: [
            { path: '', element: <Navigate to="/master/classes" replace /> },
            {
              path: 'classes',
              children: [
                { path: '', element: <Division /> },
                { path: 'new', element: <DivisionCreate /> },
                { path: ':id/edit', element: <DivisionCreate /> },
              ],
            },
            {
              path: 'manufacturers',
              children: [
                { path: '', element: <Manufacturer /> },
                { path: 'new', element: <ManufacturerCreate /> },
                { path: ':id/edit', element: <ManufacturerCreate /> },
              ],
            },
            {
              path: 'racers',
              children: [
                { path: '', element: <Racer /> },
                { path: 'new', element: <RacerCreate /> },
                { path: ':id/edit', element: <RacerCreate /> },
              ],
            },
            {
              path: 'teams',
              children: [
                { path: '', element: <Team/>},
                { path: 'new', element: <TeamCreate/> },
                { path: ':id/edit', element: <TeamCreate/> },
              ],
            },
            {
              path: 'multipliers',
              children: [
                { path: '', element: <Multiplier /> },
                { path: 'new', element: <MultiplierCreate /> },
                { path: ':id/edit', element: <MultiplierCreate /> },
              ],
            },
          ],
        },
        {
          path: 'events',
          children: [
            { path: '', element: <EventRaces /> },
            { path: ':id/edit', element: <EventRaceCreate /> },
            { path: ':id/view', element: <EventRaceCreate /> },
            { path: 'new', element: <EventRaceCreate /> },
          ],
        },
        {
          path: 'ads',
          children: [
            { path: '', element: <AdSystem /> },
            { path: ':id/edit', element: <AdCreate /> },
            // { path: ':id/view', element: <EventRaceCreate /> },
            { path: 'new', element: <AdCreate /> },
            { path: ':id/ads-analytics', element:<AdAnalytics /> }
          ],
        },
        {
          path: 'withdrawal-requests',
          children: [{ path: '', element: <Requests /> }],
        },
        {
          path: 'cms',
          children: [
            { path: '', element: <Navigate to="/cms/privacy-policy" replace /> },
            {
              path: 'privacy-policy',
              element: <PrivacyPolicy />,
            },
            {
              path: 'terms-and-conditions',
              element: <TermsConditions />,
            },
            {
              path: 'faqs',
              element: <FAQ />,
            },
          ],
        },
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

const Dashboard = Loadable(lazy(() => import('../views/Dashboard/Dashboard')));
const Users = Loadable(lazy(() => import('../views/Users/Users')));
const Division = Loadable(lazy(() => import('../views/Masters/Divisions/Division')));
const Manufacturer = Loadable(lazy(() => import('../views/Masters/Manufacturers/Manufacturer')));
const Racer = Loadable(lazy(() => import('../views/Masters/Racers/Racer')));
const Multiplier = Loadable(lazy(() => import('../views/Masters/Multipliers/Multiplier')));
const EventRaces = Loadable(lazy(() => import('../views/Events/EventRaces')));
const NotFound = Loadable(lazy(() => import('../views/Page404')));
