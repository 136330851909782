import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { WithdrawRequest } from 'src/@types/requests';

export interface RequestState {
  requests: WithdrawRequest[];
  totalRequests: number;
  pendingRequests: number;
  tabSelected?: string;
}

const initialState: RequestState = {
  requests: [],
  totalRequests: 0,
  pendingRequests: 0,
  tabSelected: 'all',
};

export const requestSlice = createSlice({
  name: 'requests',
  initialState,
  reducers: {
    requestListAction: (state, action: PayloadAction<RequestState>) => {
      if (!state.requests.length) {
        state.requests = action.payload.requests;
      } else {
        state.requests = [...state.requests, ...action.payload.requests];
      }

      state.totalRequests = action.payload.totalRequests;
      state.pendingRequests = action.payload.pendingRequests;
    },
    editRequestAction: (state, action: PayloadAction<WithdrawRequest>) => {
      const index = state.requests.findIndex((request) => request.id === action.payload.id);
      state.requests[index] = { ...state.requests[index], status: action.payload.status };
      if (state.pendingRequests > 0) {
        state.pendingRequests -= 1;
      }
    },
    emptyRequestListAction: (state) => {
      state.requests = [];
      state.totalRequests = 0;
    },
    changeTabAction: (state, action: PayloadAction<string>) => {
      state.tabSelected = action.payload;
    },
    // requestStripeBalance: (state, action: PayloadAction<any>) => {
    //   state.stripeBalance = action.payload
    // }
  },
});

// Action creators are generated for each case reducer function
export const { requestListAction, editRequestAction, emptyRequestListAction, changeTabAction } =
  requestSlice.actions;

export const requestReducer = requestSlice.reducer;
