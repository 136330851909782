import * as Yup from 'yup';
import { useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Avatar,
  Box,
  Button,
  Card,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
  Stack
} from '@mui/material';
import { makeStyles } from '@material-ui/core';
import { UserList } from '../../@types/user';
import { FormProvider, RHFTextField } from '../../components/hook-form';
import Label from 'src/components/Label';
import Iconify from 'src/components/Iconify';
import { userService } from 'src/services/users';
import { editUserAction } from 'src/store/userSlice';
import { useDispatch } from 'react-redux';
import LoadingScreen from 'src/components/LoadingScreen';
import { LoadingButton } from '@mui/lab';


// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  avatarBox: {
    width: 144,
    height: 144,
    margin: 'auto',
    borderRadius: '50%',
    padding: '8px',
    border: `1px dashed ${theme.palette.grey[500]}`,
    marginBottom: 30,
  },
  customAvatar: {
    zIndex: 0,
    width: '100%',
    height: '100%',
    outline: 'none',
    display: 'flex',
    overflow: 'hidden',
    borderRadius: '50%',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    '& > *': { width: '100%', height: '100%' },
    '&:hover': {
      cursor: 'pointer',
      '& .placeholder': {
        zIndex: 9,
      },
    },
  },
}));

type Props = {
  isEdit: boolean;
  currentUser?: UserList;
};

interface FormValuesProps extends UserList { }

export default function UserPersonal({ isEdit, currentUser }: Props) {
  const [open, setOpen] = useState(false);
  const [progress, setProgress] = useState(false);
  const [isActive, setIsActive] = useState(currentUser?.isActive || false);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const handleStatus = () => {
    setProgress(true);
    userService
      .editUserStatus(currentUser?.id!, { isActive: isActive })
      .then((data) => {
        dispatch(editUserAction(data.result));
        setProgress(false);
      })
      .catch((error) => {
        setProgress(false);
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
      });
  };

  const NewUserSchema = Yup.object().shape({
    email: Yup.string().required('Username is required').email(),
  });

  const defaultValues = useMemo(
    () => ({
      email: currentUser?.email || '',
      username: currentUser?.username || '',
      walletBalance: currentUser?.walletBalance || 0,
      isVerified: currentUser?.isVerified || false,
      isActive: currentUser?.isActive || false,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser]
  );

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const { reset, handleSubmit, formState: { isSubmitting } } = methods;

  useEffect(() => {
    if (isEdit && currentUser) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentUser]);

  const onSubmit = async (data: FormValuesProps) => {
    try {
      if (currentUser?.id && data) {
        userService.updateUser(currentUser?.id, data)
          .then((result) => {
            enqueueSnackbar('Update success!');
          })
          .catch((error) => {
            enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
          })
      }
      // await new Promise((resolve) => setTimeout(resolve, 500));
      // reset();
      // enqueueSnackbar('Update success!');
    } catch (error) {
      enqueueSnackbar(error.response.data.message, { variant: 'error' });
    }
  };

  return (
    <>
      {progress && <LoadingScreen isDashboard={true} />}

      <Container maxWidth={'xl'}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3} sx={{ mt: 12 }}>
            <Grid item xs={12} md={4}>
              <Card sx={{ py: 10, px: 3 }}>
                <Label
                  color={isActive ? 'success' : 'error'}
                  sx={{ textTransform: 'uppercase', position: 'absolute', top: 24, right: 24 }}
                >
                  {isActive ? 'Active' : 'Barred'}
                </Label>

                <Box className={classes.avatarBox}>
                  <Avatar
                    className={classes.customAvatar}
                    alt={currentUser?.username}
                    src={currentUser?.userLogo === null ? 'temp' : currentUser?.userLogo}
                  />
                </Box>

                {isEdit && (
                  <>
                    <FormControlLabel
                      labelPlacement="start"
                      control={
                        <Controller
                          name="isActive"
                          defaultValue={isActive}
                          render={({ field }) => (
                            <Switch
                              {...field}
                              checked={!isActive}
                              onChange={(event) => {
                                setOpen(true);
                                setIsActive(!event.target.checked);
                                field.onChange(event.target.checked);
                              }}
                            />
                          )}
                        />
                      }
                      label={
                        <>
                          <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                            Barred
                          </Typography>
                          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            Apply disable account
                          </Typography>
                        </>
                      }
                      sx={{ mx: 0, mb: 3, width: 1, justifyContent: 'space-between' }}
                    />

                    <Dialog open={open}>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description" paddingTop={3}>
                          Are you sure that you want to change the active status of
                          <strong> "{currentUser?.username}"</strong>?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={() => {
                            setOpen(false);
                            setIsActive((prev) => !prev);
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={() => {
                            handleStatus();
                            setOpen(false);
                          }}
                          autoFocus
                        >
                          Yes
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </>
                )}

                <div
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                >
                  <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                    Email Verified
                  </Typography>
                  <Iconify
                    icon={
                      defaultValues.isVerified ? 'eva:checkmark-circle-fill' : 'eva:clock-outline'
                    }
                    sx={{
                      width: 20,
                      height: 20,
                      color: 'success.main',
                      ...(!defaultValues.isVerified && { color: 'warning.main' }),
                    }}
                  />
                </div>
              </Card>
            </Grid>

            <Grid item xs={12} md={8}>
              <Card sx={{ p: 3 }}>
                <Box
                  sx={{
                    display: 'grid',
                    columnGap: 2,
                    rowGap: 3,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                  }}
                >
                  <RHFTextField
                    name="username"
                    label="Username"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <RHFTextField
                    name="email"
                    label="Email"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  {(
                    <RHFTextField
                      type="number"
                      placeholder='0'
                      name="walletBalance"
                      label="Wallet Balance"
                      InputProps={{
                        readOnly: !isEdit
                      }}
                    />
                  )}
                </Box>
                {isEdit &&
                  <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                    <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                      Save Changes
                    </LoadingButton>
                  </Stack>
                }
              </Card>
            </Grid>
          </Grid>
        </FormProvider>
      </Container>
    </>
  );
}
