import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, Stack, Typography, TextField, Container } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useMemo, useEffect, useState, useCallback } from 'react';
import { useForm, Controller, useFormContext } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router';
import { CustomFile } from 'src/components/upload';
import { FormProvider, RHFTextField, RHFSelect, RHFUploadSingleFile, RHFUploadAvatar } from 'src/components/hook-form';
import { PATH_MASTER, PATH_ADS } from 'src/routes/paths';
import { Ads, AdsListBody } from 'src/@types/ads';
import { divisionService } from 'src/services/masters/divisions';
import { useDispatch, useSelector } from 'react-redux';
import LoadingScreen from 'src/components/LoadingScreen';
import Iconify from 'src/components/Iconify';
import CustomContainer from 'src/components/upload/CustomContainer';
import { DateTimePicker } from '@mui/x-date-pickers';
import Label from 'src/components/Label';
import Scrollbar from 'src/components/Scrollbar';
import { AdState, createAdAction, editAdAction } from 'src/store/adSlice';
import { adService } from 'src/services/ads';
import AWS from 'aws-sdk'
import Dashboard from '../Dashboard/Dashboard';
import { current } from '@reduxjs/toolkit';
AWS.config.update({
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY_ID
})
const S3_BUCKET = process.env.REACT_APP_BUCKETNAME
const REGION = process.env.REACT_APP_REGION
const myBucket = new AWS.S3({
  apiVersion: '2006-03-01',
  params: { Bucket: S3_BUCKET },
  region: REGION,
})

window.Buffer = window.Buffer || require('buffer').Buffer
type FormValuesProps = {
  name: string;
  image: string;
  url: string;
  target: string;
  startTime: string;
  endTime: string
};

export default function AdCreate() {
  const [currentAd, setCurrentAd] = useState<Ads>();
  const adList = useSelector(
    (state: { ads: AdState }) => state.ads.ads
  );
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isEdit = pathname.includes('edit');
  const [loader, setLoader] = useState(false);

  const { id = '' } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (isEdit && !currentAd) {
      const index = adList.findIndex((ad) => ad.id === parseInt(id));
      if (index !== -1) {
        setCurrentAd(adList[index]);
        setLoader(false);
      } else {
        adService
          .getAdById(parseInt(id))
          .then((data: any) => {
            setCurrentAd(data?.result);
            setLoader(false);
          })
          .catch((error) => {
            setLoader(false);
            enqueueSnackbar(error.response.data.message, { variant: 'error' });
            navigate(PATH_ADS.root);
          });
      }
    }
    if (isEdit && currentAd) {
      reset(defaultValues);
    }
    if (!isEdit) {
      setLoader(false);
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentAd]);

  const NewAdSchema = Yup.object().shape({
    // image: Yup.string().trim().required('Image is required'),
    name: Yup.string().trim().required('Name is required'),
    url: Yup.string().trim().required('URL is required'),
    startTime: Yup.string().trim().required('StartTime is required'),
    endTime: Yup.string().trim().required('End Time is required'),
    target: Yup.string().trim().required('Target Page is required'),
  });

  const defaultValues = {
    name: currentAd?.name || '',
    image: '',
    url: currentAd?.url || '',
    target: currentAd?.target || '',
    startTime: currentAd?.startTime || '',
    endTime: currentAd?.endTime || ''
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NewAdSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    setValue,
    resetField,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: AdsListBody) => {
    if (isEdit) {
      try {
        const result = await adService.updateAd(parseInt(id), {
          name: data.name,
          url: data?.url,
          image: images,
          startTime: data?.startTime,
          endTime: data.endTime,
          target: data?.target
        });

        dispatch(editAdAction({ id: parseInt(id), ads: result.data?.result }));
        enqueueSnackbar('Update success!');
        navigate(PATH_ADS.root);
      } catch (error) {
        console.error(error);
        reset();
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
      }
    } else {
      try {
        const result = await adService.createAd({
          name: data.name,
          url: data.url,
          image: images,
          startTime: data?.startTime,
          endTime: data.endTime,
          target: data?.target

        });

        if (result.status === 200) {
          reset();
          // dispatch(createAdAction(result.data.result));
          enqueueSnackbar('Create success!');
          navigate(PATH_ADS.root);
        }
      } catch (error) {
        console.error(error);
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
      }
    }
  };
  const [images, setLocalImg] = useState('')
  const handleDrop = async (e: any) => {
    if (e && e[0]) {
      let file = e[0]
      setLoader(true)
      try {
        const fileUrl = await myBucket
          .upload({
            ACL: 'public-read',
            Bucket: 'betzon-motobookie',
            Key: file.name,
            Body: file,
            ContentEncoding: 'base64'
          })
          .promise()
        // setValue(
        //   'image',
        //   fileUrl?.Location,
        // );
        // setLocalImg(Object.assign(file, {
        //   preview: URL.createObjectURL(file),
        // }))
        setLocalImg(fileUrl?.Location)
        setValue(
          'image',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );

      } catch (error) {
        console.log(error)
      }
      setLoader(false)
    }
  }

  const handleRemove = () => {
    resetField('image');
    setLocalImg('')
  };

  return !loader ? (
    <Container maxWidth={'xl'}>
      <div style={{ display: 'flex', alignItems: 'baseline' }}>
        <Iconify
          icon={'akar-icons:arrow-left'}
          sx={{ cursor: 'pointer', mr: 2, fontSize: 20 }}
          onClick={() => navigate(PATH_ADS.root)}
        />
        <Typography variant="h3" component="h1" paragraph>
          {!isEdit ? 'Create Ad' : 'Edit Ad'}
        </Typography>
      </div>

      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3} sx={{ mt: 0 }}>
          <Grid item xs={12} md={10}>

            <Card sx={{ m: 4 }}>
              <Scrollbar>
                <Box
                  sx={{
                    display: 'grid',
                    columnGap: 2,
                    rowGap: 3,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                  }}
                >
                  <RHFUploadSingleFile
                    accept={{ 'image/*': [] }}
                    onDrop={(e) => handleDrop(e)}
                    onRemove={handleRemove}
                    name='image'
                  />
                  {/* {isEdit &&
                    <p style={{ textOverflow: 'wrap' }}> {currentAd?.image} </p>
                  } */}
                  <RHFTextField name="name" label="Ad Name" />
                  <RHFTextField name="url" label="Ad URL" />
                  <RHFSelect name="target" label="Target Page">
                    <option key={0} value={''} disabled>
                      Target Page
                    </option>
                    <option key={1} value='chip'>
                      Chip
                    </option>
                    <option key={2} value='loading'>
                      Loading
                    </option>
                    <option key={3} value='createWager' >
                      Create Wager
                    </option>
                    <option key={4} value='dashboard'>
                      Dashboard
                    </option>
                    <option key={5} value='all'>
                      All
                    </option>
                  </RHFSelect>

                  <Controller
                    name="startTime"
                    // control={control}
                    render={({ field, fieldState: { error } }) => (
                      <DateTimePicker
                        // disabled={isEdit || isView}
                        {...field}
                        label="Start Time"
                        disablePast={!isEdit}
                        inputFormat="MM/dd/yyyy hh:mm a"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={!!error}
                            helperText={error?.message}
                            fullWidth
                          />
                        )}
                      />
                    )}
                  />
                  <Controller
                    name="endTime"
                    // control={control}
                    render={({ field, fieldState: { error } }) => (
                      <DateTimePicker
                        // disabled={isEdit || isView}
                        {...field}
                        label="End Time"
                        disablePast={!isEdit}
                        inputFormat="MM/dd/yyyy hh:mm a"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={!!error}
                            helperText={error?.message}
                            fullWidth
                          />
                        )}
                      />
                    )}
                  />
                </Box>
              </Scrollbar>


              <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                  {!isEdit ? 'Create Ad' : 'Save Changes'}
                </LoadingButton>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </FormProvider>
    </Container>
  ) : (
    <LoadingScreen />
  );
}
