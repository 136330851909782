// @mui
import { List, Box } from '@mui/material';
//
import { NavSectionProps } from '../type';
import { ListSubheaderStyle } from './style';
import NavList from './NavList';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { requestService } from 'src/services/requests';
import { RequestState, requestListAction } from 'src/store/requestSlice';

// ----------------------------------------------------------------------

export default function NavSectionVertical({ navConfig, isCollapse, ...other }: NavSectionProps) {
  const dispatch = useDispatch();
  const requestsList = useSelector((state: { requests: RequestState }) => state.requests);

  useEffect(() => {
    if (!requestsList.requests.length) {      
      requestService
        .getWithdrawalRequests({ skip: 0, limit: 5 })
        .then((data) => {
          dispatch(
            requestListAction({
              requests: data?.result,
              totalRequests: data?.count,
              pendingRequests: data?.pendingCount,
            })
          );
        })
        .catch((error) => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box {...other}>
      {navConfig.map((group) => (
        <List key={group.subheader} disablePadding sx={{ px: 2 }}>
          <ListSubheaderStyle
            sx={{
              ...(isCollapse && {
                opacity: 0,
              }),
            }}
          >
            {group.subheader}
          </ListSubheaderStyle>

          {group.items.map((list) => (
            <NavList
              key={list.title + list.path}
              data={list}
              depth={1}
              hasChildren={!!list.children}
              isCollapse={isCollapse}
            />
          ))}
        </List>
      ))}
    </Box>
  );
}
