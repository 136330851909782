import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EventRaceList } from 'src/@types/events';

export interface EventState {
  events: EventRaceList[];
  totalEvents: number;
}

const initialState: EventState = {
  events: [],
  totalEvents: 0,
};

export const eventSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    eventListAction: (state, action: PayloadAction<EventState>) => {

      // if (!state.events.length) {
      //   state.events = action.payload.events;
      // } else {
      //   state.events = [...state.events, ...action.payload.events];
      // }
      state.events = action.payload.events;
      // console.log('TESTING HERE')
      // console.log(state.events)
      // console.log(state.totalEvents)
      // console.log(action.payload)
      
      state.totalEvents = action.payload.totalEvents;
    },
    createEventAction: (state, action: PayloadAction<EventRaceList>) => {
      state.events.unshift(action.payload);
      state.totalEvents += 1;
    },
    deleteEventAction: (state, action: PayloadAction<number>) => {
      const index = state.events.findIndex((event) => event.id === action.payload);
      state.events.splice(index, 1);
      state.totalEvents -= 1;
    },
    editEventAction: (state, action: PayloadAction<EventRaceList>) => {
      const index = state.events.findIndex((event) => event.id === action.payload.id);
      state.events[index] = action.payload;
    },
    emptyListAction: (state) => {
      state.events = [];
      state.totalEvents = 0;
    },
    changeStatusAction: (state, action: PayloadAction<number>) => {
      console.log('TESTING HERE')
      console.log(state.events)
      console.log(state.totalEvents)
      console.log(action.payload)
      const index = state.events.findIndex((event) => event.id === action.payload);
      console.log(state.events[index])
      state.events[index].resultStatus = 'completed'
    }
  },
});

export const {
  eventListAction,
  deleteEventAction,
  createEventAction,
  editEventAction,
  emptyListAction,
  changeStatusAction
} = eventSlice.actions;

export const eventReducer = eventSlice.reducer;
