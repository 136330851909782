import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { MultiplierList } from 'src/@types/master';

export interface MultiplierState {
  multipliers: MultiplierList[];
  totalMultipliers: number;
}

const initialState: MultiplierState = {
  multipliers: [],
  totalMultipliers: 0,
};

export const multiplierSlice = createSlice({
  name: 'multiplier',
  initialState,
  reducers: {
    multiplierListAction: (state, action: PayloadAction<MultiplierState>) => {
      if (!state.multipliers.length) {
        state.multipliers = action.payload.multipliers;
      } else {
        state.multipliers = [...state.multipliers, ...action.payload.multipliers];
      }

      state.totalMultipliers = action.payload.totalMultipliers;
    },
    createMultiplierAction: (state, action: PayloadAction<MultiplierList>) => {
      state.multipliers.unshift(action.payload);
      state.totalMultipliers += 1;
    },
    deleteMultiplierAction: (state, action: PayloadAction<number>) => {
      const index = state.multipliers.findIndex((multiplier) => multiplier.id === action.payload);
      state.multipliers.splice(index, 1);
      state.totalMultipliers -= 1;
    },
    editMultiplierAction: (state, action: PayloadAction<any>) => {
      const index = state.multipliers.findIndex(
        (multiplier) => multiplier.id === action.payload.id
      );
      state.multipliers[index] = action.payload.multiplier;
    },
    emptyMultiplierListAction: (state) => {
      state.multipliers = [];
      state.totalMultipliers = 0;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  multiplierListAction,
  createMultiplierAction,
  deleteMultiplierAction,
  editMultiplierAction,
  emptyMultiplierListAction,
} = multiplierSlice.actions;

export const multiplierReducer = multiplierSlice.reducer;
