import {
  TableRow,
  TableCell,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useTheme,
} from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import { WithdrawRequest } from 'src/@types/requests';
import Label from 'src/components/Label';

// ----------------------------------------------------------------------

type Props = {
  index: number;
  row: WithdrawRequest;
  selected: boolean;
  onApprove: Function;
};

export default function RequestsTableRow({ index, row, selected, onApprove }: Props) {
  const theme = useTheme();

  const { user_data, chips, beforeReqBalance, createdAt, status } = row;
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(0);
  const [responseStatus, setResponseStatus] = useState('');

  return (
    <TableRow hover selected={selected}>
      <TableCell align="center">{index + 1}</TableCell>

      <TableCell align="center">
        <Typography variant="subtitle2" noWrap>
          {user_data.username}
        </Typography>
      </TableCell>

      <TableCell align="center">{user_data.email}</TableCell>

      <TableCell align="center">{user_data.walletBalance}</TableCell>

      <TableCell align="center">{chips}</TableCell>

      <TableCell align="center">{beforeReqBalance}</TableCell>

      <TableCell align="center">{moment(createdAt).format('MM-DD-YYYY')}</TableCell>

      <TableCell align="center" sx={{ display: 'flex', justifyContent: 'center' }}>
        {status === 'pending' ? (
          <>
            <Button
              variant="contained"
              color="success"
              sx={{ py: 1, mr: 1 }}
              onClick={() => {
                setOpen(true);
                setId(row.id);
                setResponseStatus('Approve');
              }}
            >
              Approve
            </Button>
            <Button
              variant="contained"
              color="error"
              sx={{ py: 1 }}
              onClick={() => {
                setOpen(true);
                setId(row.id);
                setResponseStatus('Decline');
              }}
            >
              Decline
            </Button>
          </>
        ) : (
          <Label
            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
            color={
              status === 'approved'
                ? 'success'
                : status === 'pending'
                ? 'primary'
                : status === 'decline'
                ? 'error'
                : 'warning'
            }
            sx={{
              textTransform: 'capitalize',
              height: '40px',
              borderRadius: '8px',
              width: '100%',
              fontSize: '0.875rem',
            }}
          >
            {status}
          </Label>
        )}

        {id === row.id && (
          <Dialog open={open}>
            <DialogTitle>{responseStatus} Request ? </DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description" paddingTop={3}>
                Are you sure that you want to{' '}
                <strong>
                  {responseStatus.toLowerCase()} "{row.user_data.username}"
                </strong>{' '}
                request?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setOpen(false);
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  onApprove(responseStatus);
                  setOpen(false);
                }}
                autoFocus
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </TableCell>
    </TableRow>
  );
}
