import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { AuthState } from 'src/store/authSlice';
import { baseEndpoints } from './config';

let isRetry = false;

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const userDetails = localStorage.getItem('user');
  const userDetailsParsed: AuthState = userDetails ? JSON.parse(userDetails) : null;

  const accessToken = localStorage.getItem('accessToken');

  if (!config) {
    config = {};
  }
  if (!config.headers) {
    config.headers = {};
  }

  config.headers['x-api-key'] = process.env.REACT_APP_X_API_KEY || '';
  config.headers.platform = process.env.REACT_APP_PLATFORM || '';

  if (config.url?.includes('login')) {
    return config;
  } else if (config.url?.includes('generate-access-token')) {
    config.headers.authorization = 'Bearer ' + userDetailsParsed.refreshToken;
    return config;
  } else {
    config.headers.authorization = 'Bearer ' + accessToken;
  }

  return config;
};

const onResponse = (response: AxiosResponse): AxiosResponse => response;

const onResponseError = async (error: AxiosError): Promise<any> => {
  const originalRequest = error.config;

  if (error?.response?.status === 401 && originalRequest && !isRetry) {
    isRetry = true;
    try {
      const { data } = await axios.post(`${baseEndpoints.auth}/generate-access-token`, {});
      localStorage.setItem('accessToken', data.token.accessToken);

      return await axios.request(originalRequest);
    } catch (error) {
      console.log(error.message);
    }
  }
  if (error?.response?.status === 401 && originalRequest && isRetry) {
    localStorage.removeItem('user');
    localStorage.removeItem('accessToken');
    setTimeout(() => {
      window.location.href = 'https://admin.motobookie.com/auth/login';
    }, 1000);
  }
  throw error;
};

export function setupInterceptorsTo(axiosInstance: AxiosInstance): AxiosInstance {
  axiosInstance.interceptors.request.use(onRequest);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
}
