import {  Dialog, DialogContent, DialogTitle } from '@mui/material';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import { Faqs } from 'src/@types/faqs';
import { useForm } from 'react-hook-form';
import { RHFTextField, FormProvider } from 'src/components/hook-form';
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  dialogButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(2.5, 0),
  },
}));

// ----------------------------------------------------------------------

type Props = {
  faq?: Faqs;
  onEdit: any;
  isLoading: boolean;
  openEdit: boolean;
  onCloseAlert: VoidFunction;
};

export default function FaqEditPopup({ faq, openEdit, onCloseAlert, onEdit, isLoading }: Props) {
  const classes = useStyles();

  const NewFaqSchema = Yup.object().shape({
    question: Yup.string().trim().required('Question is required'),
    answer: Yup.string()
      .trim()
      .required('Answer is required')
  });

  const defaultValues = useMemo(
    () => ({
      question: faq?.question || '',
      answer: faq?.answer || '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [faq]
  );

  const methods = useForm<Faqs>({
    resolver: yupResolver(NewFaqSchema),
    defaultValues,
  });

  const { handleSubmit } = methods;

  return (
    <Dialog open={openEdit} onClose={onCloseAlert}>
      <DialogTitle sx={{ pb: 3 }}>{faq ? 'Edit FAQ ' : 'Add FAQ '} </DialogTitle>
      <DialogContent sx={{ pb: 0 }}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onEdit)}>
          <RHFTextField name="question" label="Question" sx={{ my: 2 }} />
          <RHFTextField multiline maxRows={3} name="answer" label="Answer" sx={{ my: 2 }} />

          <div className={classes.dialogButtons}>
            <LoadingButton type="submit" variant="contained" loading={isLoading}>
              {faq ? 'Save Changes' : 'Create FAQ'}
            </LoadingButton>
          </div>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}
