import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EventRaceList } from 'src/@types/events';

export interface SportState {
    sports: any[];
    sportSelected: any;
}

const initialState: SportState = {
    sports: [],
    sportSelected: null
};

export const sportsSlice = createSlice({
    name: 'sports',
    initialState,
    reducers: {
        sportAction: (state, action: PayloadAction<SportState>) => {

            if (!state.sports.length) {
                state.sports = action.payload.sports;
            } else {
                state.sports = [...state.sports, ...action.payload.sports];
            }

            // console.log('TESTING HERE')
            // console.log(action.payload)
        },
        setSportsAction: (state, action: PayloadAction<any>) => {
            state.sports = action.payload
            state.sportSelected = action.payload[0]
        },
        setSelectedSportAction: (state, action: PayloadAction<any>) => {
            state.sportSelected = action.payload
        }
    },
});

export const {
    sportAction,
    setSportsAction,
    setSelectedSportAction

} = sportsSlice.actions;

export const sportsReducer = sportsSlice.reducer;
