import Iconify from 'src/components/Iconify';
import SvgIconStyle from '../../../components/SvgIconStyle';

const getIcon = (name: string) => (
  <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  user: getIcon('ic_user'),
  dashboard: getIcon('ic_dashboard'),
};

const navConfig = [
  {
    subheader: '',
    items: [
      { title: 'Dashboard', path: '/dashboard', icon: ICONS.dashboard },
      { title: 'Users', path: '/users', icon: ICONS.user },
      {
        title: 'Master',
        path: '/master',
        icon: <Iconify icon={'eva:award-fill'} />,
        children: [
          { title: 'Class', path: '/master/classes' },
          { title: 'Manufacturers', path: '/master/manufacturers' },
          { title: 'Racers', path: '/master/racers' },
          { title: 'Teams', path: '/master/teams' },
          { title: 'Multipliers', path: '/master/multipliers' },
        ],
      },
      {
        title: 'Events',
        path: '/events',
        icon: <Iconify icon={'maki:racetrack'} />
      },
      {
        title: 'Withdrawal Requests',
        path: '/withdrawal-requests',
        icon: <Iconify icon={'ic:outline-request-quote'} />,
        info: (<></>)
      },
      {
        title: 'CMS',
        path: '/cms',
        icon: <Iconify icon={'eva:info-outline'} />,
        children: [
          { title: 'Privacy Policy', path: '/cms/privacy-policy' },
          { title: 'Terms & Conditions', path: '/cms/terms-and-conditions' },
          { title: 'FAQ', path: '/cms/faqs' },
        ],
      },
      {
        title: 'Ads Management',
        path: '/ads',
        icon: <Iconify icon={'ic:outline-request-quote'} />,
        children: [
          { title: 'Ads', path: '/ads' },
          // { title: 'Ad Analytics', path: '/ads-analytics'},
        ],
      },
    ],
  },
];

export default navConfig;
