import './utils/highlight';
import 'react-quill/dist/quill.snow.css';
import { setupInterceptorsTo } from './services/interceptor';
import axios from 'axios';
import 'simplebar/src/simplebar.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';
//import ReactDOM from 'react-dom/client';
import { createRoot } from 'react-dom/client';

import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { SettingsProvider } from './contexts/SettingsContext';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';
//
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import { Provider } from 'react-redux';
import { store, persistor } from './store/store'; // Adjust the path as necessary
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

// ----------------------------------------------------------------------
setupInterceptorsTo(axios);

//const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Provider store={store}>

    <PersistGate loading={null} persistor={persistor}>
      <HelmetProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <SettingsProvider>
            <CollapseDrawerProvider>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </CollapseDrawerProvider>
          </SettingsProvider>
        </LocalizationProvider>
      </HelmetProvider>
    </PersistGate>

  </Provider>
);
