import { capitalCase } from 'change-case';
import { Tab, Box, Tabs, Typography, Container, Grid } from '@mui/material';
import useTabs from '../../hooks/useTabs';
import Iconify from '../../components/Iconify';
import { useLocation, useNavigate, useParams } from 'react-router';
import EventLinkedWagers from './EventLinkedWagers';
import EventGeneral from './EventGeneral';
import EventResults from './EventResults';
import { useState, useEffect } from 'react';
import { EventRaceList, EventRaceListResponseById } from 'src/@types/events';
import { PATH_EVENTS } from 'src/routes/paths';
import { eventService } from 'src/services/events';
import { useSelector } from 'react-redux';
import { EventState } from 'src/store/eventSlice';
import { useSnackbar } from 'notistack';
import LoadingScreen from 'src/components/LoadingScreen';
import { makeStyles } from '@material-ui/core';
import EventUploadResult from './EventUploadResult';
import RacerList from './RacerList';
import moment from 'moment';
import EventRacersTable from './EventRacersTable';

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  header: {
    position: 'fixed',
    top: '58px',
    zIndex: 1000,
    backgroundColor: 'white',
    paddingTop: '38px',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    width: '100%',
  },
}));

// ----------------------------------------------------------------------

type CustomTab = {
  value: string;
  icon: JSX.Element;
  component: JSX.Element;
}[];

export default function EventRaceCreate() {
  const [currentEvent, setCurrentEvent] = useState<EventRaceList>();
  const eventList = useSelector((state: { events: EventState }) => state.events.events);
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { currentTab, setCurrentTab, onChangeTab } = useTabs('general');
  const { pathname } = useLocation();
  const { id = '' } = useParams();

  const classes = useStyles();

  const isEdit = pathname.includes('edit');
  const isView = pathname.includes('view');

  const [editTabs, setEditTabs] = useState<CustomTab>();

  useEffect(() => {
    if (isEdit || isView) {
      const index = eventList.findIndex((event) => event.id === parseInt(id));
      console.log('OMG -', eventList[index])
      if (index !== -1) {
        setCurrentEvent(eventList[index]);
        modifyEditTabs(eventList[index]);
        setLoader(false);
      } else {
        eventService
          .getEventById(parseInt(id))
          .then((data: EventRaceListResponseById) => {
            console.log('EVENT GET BY ID - ', data?.result)
            setCurrentEvent(data?.result);
            modifyEditTabs(data?.result);
            setLoader(false);
          })
          .catch((error) => {
            console.log(error)
            setLoader(false);
            enqueueSnackbar(error.response.data.message, { variant: 'error' });
            navigate(PATH_EVENTS.root);
          });
      }
    } else {
      console.log('MAGA PENIS -')
      setEditTabs([
        {
          value: 'general',
          icon: <Iconify icon={'ic:round-account-box'} width={20} height={20} />,
          component: (
            <EventGeneral
              isEdit={isEdit}
              isView={isView}
              id={parseInt(id)}
              onModifyTabs={(event: EventRaceList) => modifyEditTabs(event)}
            />
          ),
        },
      ]);
      setLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  const modifyEditTabs = (event: EventRaceList) => {
    let arr = [
      {
        value: 'general',
        icon: <Iconify icon={'ic:round-account-box'} width={20} height={20} />,
        component: (
          <EventGeneral
            isEdit={isEdit}
            isView={isView}
            id={parseInt(id)}
            currentEvent={event}
            onModifyTabs={(event: EventRaceList) => modifyEditTabs(event)}
          />
        ),
      },
    ];

    if (moment(event.startTime).add(15, 'minutes') > moment()) {
      console.log('JACKASS -', event)
      arr.push({
        value: 'add racers',
        icon: <Iconify icon={'fluent:add-circle-16-filled'} width={20} height={20} />,
        component: <RacerList eventId={event.id} event={event} />,
      });
    }

    if (new Date(event.endTime) < new Date() && event.resultStatus === 'pending') {
      arr.push({
        value: 'upload result',
        icon: <Iconify icon={'material-symbols:upload-rounded'} width={20} height={20} />,
        component: <EventUploadResult id={event.id} currentEvent={event} />,
      });
    }
    setEditTabs(arr);
    if (!isEdit && !isView) {
      setCurrentTab('add racers');
    }
    return arr;
  };

  const ACCOUNT_TABS = [
    {
      value: 'general',
      icon: <Iconify icon={'ic:round-account-box'} width={20} height={20} />,
      component: (
        <EventGeneral
          isEdit={isEdit}
          isView={isView}
          id={parseInt(id)}
          currentEvent={currentEvent}
          onModifyTabs={(event: EventRaceList) => modifyEditTabs(event)}
        />
      ),
    },
    {
      value: 'racers',
      icon: <Iconify icon={'ooui:user-group-ltr'} width={20} height={20} />,
      component: <EventRacersTable id={parseInt(id)} />,
    },
    {
      value: 'linked wagers',
      icon: <Iconify icon={'eva:link-2-outline'} width={20} height={20} />,
      component: <EventLinkedWagers id={parseInt(id)} />,
    },
    {
      value: 'results',
      icon: <Iconify icon={'fluent:trophy-48-filled'} width={20} height={20} />,
      component: <EventResults id={parseInt(id)} />,
    },
  ];

  return !loader ? (
    <Container maxWidth={'xl'}>
      <Grid className={classes.header}>
        <Grid item sx={{ display: 'flex', alignItems: 'baseline' }}>
          <Iconify
            icon={'akar-icons:arrow-left'}
            sx={{ cursor: 'pointer', mr: 2, fontSize: 20 }}
            onClick={() => navigate(PATH_EVENTS.root)}
          />
          <Typography variant="h4" component="h1" paragraph>
            {isEdit
              ? `Edit  Event - ${currentEvent?.name} `
              : isView
                ? `Event Details - ${currentEvent?.name}  `
                : 'Create Event'}
          </Typography>
        </Grid>
        <Grid item sx={{ ml: 4.5 }}>
          <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={currentTab}
            onChange={onChangeTab}
          >
            {isView
              ? ACCOUNT_TABS.map((tab) => (
                <Tab
                  disableRipple
                  key={tab.value}
                  label={capitalCase(tab.value)}
                  icon={tab.icon}
                  value={tab.value}
                />
              ))
              : editTabs?.map((tab) => (
                <Tab
                  disableRipple
                  key={tab.value}
                  label={capitalCase(tab.value)}
                  icon={tab.icon}
                  value={tab.value}
                />
              ))}
          </Tabs>
        </Grid>
      </Grid>

      {isView
        ? ACCOUNT_TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && <Box key={tab.value}>{tab.component}</Box>;
        })
        : editTabs?.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && <Box key={tab.value}>{tab.component}</Box>;
        })}
    </Container>
  ) : (
    <LoadingScreen />
  );
}
