import axios from 'axios';
import { DivisionListBody } from 'src/@types/master';
import { baseEndpoints } from '../config';

const createManufacturer = async (body: DivisionListBody) => {
  const { data, status } = await axios.post(`${baseEndpoints.manufacturers}/add`, body);

  return { data: data, status: status };
};

const getManufacturerList = async (body: any) => {
  const { data } = await axios.post(`${baseEndpoints.manufacturers}/list`, body);
  return data;
};

const getManufacturerById = async (id: number) => {
  const { data } = await axios.get(`${baseEndpoints.manufacturers}/get/${id}`);

  return data;
};

const deleteManufacturer = async (id: number) => {
  const { data } = await axios.delete(`${baseEndpoints.manufacturers}/delete/${id}`);

  return data;
};

const editManufacturer = async (id: number, body: DivisionListBody) => {
  const { data } = await axios.post(`${baseEndpoints.manufacturers}/edit/${id}`, body);

  return data;
};

const deleteMultipleManufacturer = async (body: { ids: number[] }) => {
  const { data } = await axios.post(`${baseEndpoints.manufacturers}/multidelete`, body);

  return data;
};

const searchManufacturer = async (body: { search: string }) => {
  const { data } = await axios.post(`${baseEndpoints.manufacturers}/search`, body);

  return data;
};

export const manufacturerService = {
  createManufacturer,
  getManufacturerList,
  getManufacturerById,
  deleteManufacturer,
  editManufacturer,
  deleteMultipleManufacturer,
  searchManufacturer,
};
