import axios from 'axios';
import { DivisionListBody } from 'src/@types/master';
import { baseEndpoints } from '../config';

const createDivision = async (body: DivisionListBody) => {
  const { data, status } = await axios.post(`${baseEndpoints.divisions}/add`, body);

  return { data: data, status: status };
};

const getDivisionList = async (body: any) => {
  const { data } = await axios.post(`${baseEndpoints.divisions}/list`, body);

  return data;
};

const getDivisionById = async (id: number) => {
  const { data } = await axios.get(`${baseEndpoints.divisions}/get/${id}`);

  return data;
};

const deleteDivision = async (id: number) => {
  const { data } = await axios.delete(`${baseEndpoints.divisions}/delete/${id}`);

  return data;
};

const editDivision = async (id: number, body: DivisionListBody) => {
  const { data } = await axios.post(`${baseEndpoints.divisions}/edit/${id}`, body);

  return data;
};

const deleteMultipleDivision = async (body: { ids: number[] }) => {
  const { data } = await axios.post(`${baseEndpoints.divisions}/multidelete`, body);

  return data;
};

const searchDivision = async (body: { search: string }) => {
  const { data } = await axios.post(`${baseEndpoints.divisions}/search`, body);

  return data;
};

export const divisionService = {
  createDivision,
  getDivisionList,
  getDivisionById,
  deleteDivision,
  editDivision,
  deleteMultipleDivision,
  searchDivision,
};
