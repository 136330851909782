import { TableRow, TableCell, Typography } from '@mui/material';
import Label from 'src/components/Label';
import { fDate } from 'src/utils/formatTime';
import { ReferredUser } from '../../@types/user';
import { useTheme } from '@mui/material/styles';

// ----------------------------------------------------------------------

type Props = {
  row: ReferredUser;
  index: number;
};

export default function UserReferredRow({ row, index }: Props) {
  const { user_data } = row;
  const theme = useTheme();

  return (
    <TableRow hover>
      <TableCell align="center">{index + 1}</TableCell>

      <TableCell align="center">
        <Typography variant="subtitle2" noWrap>
          {user_data.username}
        </Typography>
      </TableCell>

      <TableCell align="center"> {user_data.email}</TableCell>

      <TableCell align="center"> {fDate(user_data.createdAt)}</TableCell>

      <TableCell align="center">
        <Label
          variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
          color={(user_data.isActive === false && 'error') || 'success'}
          sx={{ textTransform: 'capitalize' }}
        >
          {user_data.isActive ? 'Active' : 'Barred'}
        </Label>
      </TableCell>
    </TableRow>
  );
}
