import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { persistor } from 'src/store/store';

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const isAuthenticated = !!localStorage.getItem('user');

  if (!isAuthenticated) {
    persistor.purge()
    return <Navigate to="/auth/login" />;
  }
  return <>{children}</>;
}
