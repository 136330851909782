import { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import Scrollbar from 'src/components/Scrollbar';
import { eventPrivateerService } from 'src/services/eventPrivateer';
import { useSnackbar } from 'notistack';
import LoadingScreen from 'src/components/LoadingScreen';
import { eventTeamsService } from 'src/services/eventTeam';

// ----------------------------------------------------------------------

function not(a: any, b: any) {
  return a.filter((value: any) => b.indexOf(value) === -1);
}

function intersection(a: any, b: any) {
  return a.filter((value: any) => b.indexOf(value) !== -1);
}

function union(a: any, b: any) {
  return [...a, ...not(b, a)];
}

type Props = {
  racerList: [];
  division?: { id: number; name: string };
  event: any;
  homeOrAwayType?: string;
};

// ----------------------------------------------------------------------

export default function MultiSelectRacer({ racerList, division, event, homeOrAwayType }: Props) {
  const [loader, setLoader] = useState(false);
  const [checked, setChecked] = useState<number[]>([]);
  const [left, setLeft] = useState<{ id: number; name: string }[]>(racerList);
  const [right, setRight] = useState<{ id: number; name: string }[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!!homeOrAwayType) {
      getSelectedTeams()
    }
    else {
      getSelectedRacers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSelectedRacers = () => {
    setLoader(true);
    if (!!division) {

      console.log('CALLING WITH DIVISION')
      eventPrivateerService
        .getRacers(event.id, division.id)
        .then((data) => {
          console.log('GEETING SELECTED EVENTS')
          console.log(data)
          const modifiedRight = data.result.map((racer: any) => ({
            id: racer.racerId,
            name: racer.privateer_data.name,
          }));
          setRight(modifiedRight);

          let modifiedLeft = [];
          for (let j = 0; j < left.length; j++) {
            let flag = true;
            for (let i = 0; i < modifiedRight.length; i++) {
              if (modifiedRight[i].id === left[j].id) {
                flag = false;
                break;
              }
            }
            if (flag) {
              modifiedLeft.push(left[j]);
            }
          }
          setLeft(modifiedLeft);
          setIsUpdate(true);
          setLoader(false);
        })
        .catch((error) => {
          if (error.response.status !== 403) {
            enqueueSnackbar(error.response.data.message, { variant: 'error' });
          }
          setLoader(false);
        });
    }
    else {
      console.log('CALLING WITHOUT DIVISION')
      console.log(event.id, event.sports_data.id)
      eventPrivateerService
        .getRacersBySportId(event.id, event.sports_data.id)
        .then((data) => {
          console.log('TAKING A LOOK AT RIDERS SPLITTING')
          console.log(data)
          const modifiedRight = data.result.map((racer: any) => ({
            id: racer.racerId,
            name: racer.privateer_data.name,
          }));
          setRight(modifiedRight);

          let modifiedLeft = [];
          for (let j = 0; j < left.length; j++) {
            let flag = true;
            for (let i = 0; i < modifiedRight.length; i++) {
              if (modifiedRight[i].id === left[j].id) {
                flag = false;
                break;
              }
            }
            if (flag) {
              modifiedLeft.push(left[j]);
            }
          }
          setLeft(modifiedLeft);
          setIsUpdate(true);
          setLoader(false);
        })
        .catch((error) => {
          console.log(error)
          if (error.response.status !== 403) {
            enqueueSnackbar(error.response.data.message, { variant: 'error' });
          }
          setLoader(false);
        });
    }
  };

  const getSelectedTeams = () => {
    setLoader(true);
    eventTeamsService
      .getTeams(event.id)
      .then((data) => {
        const modifiedRight = data.result.teams
          .filter((team: any) => {
            console.log(team)
            if (homeOrAwayType === "home" && team.teamsId === event.homeTeamId) {
              return true;
            } else if (homeOrAwayType === "away" && team.teamsId === event.awayTeamId) {
              return true;
            }
            return false;
          })
          .map((team: any) => ({
            id: team.teamsId,
            name: team.teams_data.name,
          }));

        setRight(modifiedRight);

        let modifiedLeft = [];
        for (let j = 0; j < left.length; j++) {
          let flag = true;
          for (let i = 0; i < modifiedRight.length; i++) {
            if (modifiedRight[i].id === left[j].id ) {
              flag = false;
              break;
            }
          }
          if (flag) {
            modifiedLeft.push(left[j]);
          }
        }
        setLeft(modifiedLeft);
        setIsUpdate(true);
        setLoader(false);
      })
      .catch((error) => {
        console.log(error)
        if (error.response.status !== 403) {
          enqueueSnackbar(error.response.data.message, { variant: 'error' });
        }
        setLoader(false);
      });
  };

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);

    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    console.log(1)
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  const numberOfChecked = (items: readonly number[]) => intersection(checked, items).length;

  const handleToggleAll = (items: readonly number[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleSubmit = () => {
    setIsSubmitting(true);
    const racerIds = rightChecked.map((e: any) => e.id);

    if (!!homeOrAwayType) {
      //ADD EVENT TEAMS SERVICES!
      if (isUpdate) {
        console.log('WE ARE UPDATING BRO')
        eventTeamsService
          .updateTeams(event.id, { teams: racerIds, homeOrAwayType: homeOrAwayType })
          .then((data) => {
            setIsSubmitting(false);
            setIsUpdate(true);
            enqueueSnackbar(data.message);
          })
          .catch((error) => {
            console.log(error)
            enqueueSnackbar(error.response.data.message, { variant: 'error' });
            setIsSubmitting(false);
          });
      }
      else {
        eventTeamsService
          .addTeams(event.id, { teams: racerIds, homeOrAwayType: homeOrAwayType })
          .then((data) => {
            setIsSubmitting(false);
            setIsUpdate(true);
            enqueueSnackbar(data.message);
          })
          .catch((error) => {
            console.log(error)
            enqueueSnackbar(error.response.data.message, { variant: 'error' });
            setIsSubmitting(false);
          });
      }

    }
    else {
      if (!!division) {
        if (isUpdate) {
          eventPrivateerService
            .updateRacers(event.id, { divisionId: division.id, racers: racerIds })
            .then((data) => {
              setIsSubmitting(false);
              setIsUpdate(true);
              enqueueSnackbar(data.message);
            })
            .catch((error) => {
              enqueueSnackbar(error.response.data.message, { variant: 'error' });
              setIsSubmitting(false);
            });
        } else {
          eventPrivateerService
            .addRacers(event.id, { divisionId: division.id, racers: racerIds })
            .then((data) => {
              setIsSubmitting(false);
              setIsUpdate(true);
              enqueueSnackbar(data.message);
            })
            .catch((error) => {
              enqueueSnackbar(error.response.data.message, { variant: 'error' });
              setIsSubmitting(false);
            });
        }
      }
      else {
        if (isUpdate) {
          eventPrivateerService
            .updateRacers(event.id, { racers: racerIds })
            .then((data) => {
              setIsSubmitting(false);
              setIsUpdate(true);
              enqueueSnackbar(data.message);
            })
            .catch((error) => {
              enqueueSnackbar(error.response.data.message, { variant: 'error' });
              setIsSubmitting(false);
            });
        } else {
          eventPrivateerService
            .addRacers(event.id, { racers: racerIds })
            .then((data) => {
              setIsSubmitting(false);
              setIsUpdate(true);
              enqueueSnackbar(data.message);
            })
            .catch((error) => {
              console.log(error)
              enqueueSnackbar(error.response.data.message, { variant: 'error' });
              setIsSubmitting(false);
            });
        }
      }
    }
  };

  const customList = (title: any, items: any) => (
    <Paper
      sx={{
        height: 300,
        overflow: 'auto',
        border: `1px solid ${theme.palette.primary.light}`,
        mx: 2,
      }}
    >
      <Scrollbar>
        <CardHeader
          sx={{ px: 2, py: 1 }}
          avatar={
            <Checkbox
              onClick={handleToggleAll(items)}
              checked={numberOfChecked(items) === items.length && items.length !== 0}
              indeterminate={
                numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
              }
              disabled={items.length === 0}
              inputProps={{
                'aria-label': 'all items selected',
              }}
            />
          }
          title={title}
          subheader={`${numberOfChecked(items)}/${items.length} selected`}
        />
        <Divider />
        <List dense component="div" role="list">
          {items.map((value: any) => {
            const labelId = `transfer-list-item-${value}-label`;

            return (
              <ListItem
                key={value.id}
                role="listitem"
                button
                onClick={handleToggle(value)}
                sx={{ py: 1 }}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      'aria-labelledby': labelId,
                    }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={value.name} />
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      </Scrollbar>
    </Paper>
  );

  return (
    <>
      {loader && <LoadingScreen isDashboard={true} />}{' '}
      {
        event.sports_data.id === 1 ?
          <Card sx={{ p: 3, mb: 3 }}>
            {
              !!division ?
                <Typography variant="h6">Class: {division.name}</Typography>
                :
                <Typography variant="h6">Racers</Typography>
            }
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
              <Grid item flex={2}>
                {customList('Select', left)}
              </Grid>
              <Grid item flex={1}>
                <Grid container direction="column" alignItems="center">
                  <Button
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleAllRight}
                    disabled={left.length === 0}
                    aria-label="move all right"
                  >
                    ≫
                  </Button>
                  <Button
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleCheckedRight}
                    disabled={leftChecked.length === 0}
                    aria-label="move selected right"
                  >
                    &gt;
                  </Button>
                  <Button
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleCheckedLeft}
                    disabled={rightChecked.length === 0}
                    aria-label="move selected left"
                  >
                    &lt;
                  </Button>
                  <Button
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleAllLeft}
                    disabled={right.length === 0}
                    aria-label="move all left"
                  >
                    ≪
                  </Button>
                </Grid>
              </Grid>
              <Grid item flex={2}>
                {customList('Selected', right)}
              </Grid>
            </Box>
            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton
                type="submit"
                variant="contained"
                disabled={!right.length}
                loading={isSubmitting}
                onClick={handleSubmit}
              >
                {isUpdate ? 'Update Racers' : 'Add Racers'}
              </LoadingButton>
            </Stack>
          </Card>
          :
          <Card sx={{ p: 3, mb: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
              <Grid item flex={2}>
                {customList('Select', left)}
              </Grid>
              <Grid item flex={1}>
                <Grid container direction="column" alignItems="center">
                  <Button
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleAllRight}
                    disabled={left.length === 0}
                    aria-label="move all right"
                  >
                    ≫
                  </Button>
                  <Button
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleCheckedRight}
                    disabled={leftChecked.length === 0}
                    aria-label="move selected right"
                  >
                    &gt;
                  </Button>
                  <Button
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleCheckedLeft}
                    disabled={rightChecked.length === 0}
                    aria-label="move selected left"
                  >
                    &lt;
                  </Button>
                  <Button
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleAllLeft}
                    disabled={right.length === 0}
                    aria-label="move all left"
                  >
                    ≪
                  </Button>
                </Grid>
              </Grid>
              <Grid item flex={2}>
                {customList('Selected', right)}
              </Grid>
            </Box>
            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton
                type="submit"
                variant="contained"
                disabled={!right.length}
                loading={isSubmitting}
                onClick={handleSubmit}
              >
                {
                  isUpdate ?
                    'Update Racers'
                    :
                    !!homeOrAwayType
                      ?
                      'Add Team'
                      :
                      'Add Racers'}
              </LoadingButton>
            </Stack>
          </Card>
      }
    </>
  );
}
