import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useMemo, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router';
import { FormProvider, RHFTextField } from 'src/components/hook-form';
import { PATH_MASTER } from 'src/routes/paths';
import { MultiplierList, MultiplierListResponseById } from 'src/@types/master';
import { useDispatch, useSelector } from 'react-redux';
import { multiplierService } from 'src/services/masters/multipliers';
import {
  createMultiplierAction,
  editMultiplierAction,
  MultiplierState,
} from 'src/store/multiplierSlice';
import LoadingScreen from 'src/components/LoadingScreen';
import Iconify from 'src/components/Iconify';
import CustomContainer from 'src/components/upload/CustomContainer';

export default function MultiplierCreate() {
  const [currentMultiplier, setCurrentMultiplier] = useState<MultiplierList>();
  const dispatch = useDispatch();
  const multiplierList = useSelector(
    (state: { multiplier: MultiplierState }) => state.multiplier.multipliers
  );
  const { pathname } = useLocation();
  const isEdit = pathname.includes('edit');
  const [loader, setLoader] = useState(true);

  const { id = '' } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (isEdit && !currentMultiplier) {
      const index = multiplierList.findIndex((multiplier) => multiplier.id === parseInt(id));

      if (index !== -1) {
        setCurrentMultiplier(multiplierList[index]);
        setLoader(false);
      } else {
        multiplierService
          .getMultiplierById(parseInt(id))
          .then((data: MultiplierListResponseById) => {
            setCurrentMultiplier(data?.result);
            setLoader(false);
          })
          .catch((error) => {
            enqueueSnackbar(error.response.data.message, { variant: 'error' });
            navigate(PATH_MASTER.multiplier);
            setLoader(false);
          });
      }
    }

    if (isEdit && currentMultiplier) {
      reset(defaultValues);
    }
    if (!isEdit) {
      setLoader(false);
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentMultiplier]);

  const NewMultiplierSchema = Yup.object().shape({
    multiplier: Yup.number().min(1, 'Multiplier is required'),
    description: Yup.string()
      .trim()
      .required('Description is required')
      .matches(/^[a-zA-Z0-9\s]+$/, 'Only letters and numbers are allowed')
      .max(250, 'Too long descritpion'),
  });

  const defaultValues = useMemo(
    () => ({
      multiplier: currentMultiplier?.multiplier || 0,
      description: currentMultiplier?.description || '',
      name: currentMultiplier?.name || '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentMultiplier]
  );

  const methods = useForm<MultiplierList>({
    resolver: yupResolver(NewMultiplierSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: MultiplierList) => {
    if (isEdit) {
      try {
        const result = await multiplierService.editMultiplier(parseInt(id), {
          multiplier: data.multiplier,
          description: data.description,
          name: `${data.multiplier} to 1`,
        });

        dispatch(editMultiplierAction({ id: parseInt(id), multiplier: result.result }));
        enqueueSnackbar('Update success!');
        navigate(PATH_MASTER.multiplier);
      } catch (error) {
        console.error(error);
        reset();
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
      }
    } else {
      try {
        const result = await multiplierService.createMultiplier({
          multiplier: data.multiplier,
          description: data.description,
          name: `${data.multiplier} to 1`,
        });

        if (result.status === 200) {
          reset();
          dispatch(createMultiplierAction(result.data.result));
          enqueueSnackbar(!isEdit ? 'Create success!' : 'Update success!');
          navigate(PATH_MASTER.multiplier);
        }
      } catch (error) {
        console.error(error);
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
      }
    }
  };

  return !loader ? (
    <CustomContainer maxWidth={'xl'}>
      <div style={{ display: 'flex', alignItems: 'baseline' }}>
        <Iconify
          icon={'akar-icons:arrow-left'}
          sx={{ cursor: 'pointer', mr: 2, fontSize: 20 }}
          onClick={() => navigate(PATH_MASTER.multiplier)}
        />
        <Typography variant="h3" component="h1" paragraph>
          {!isEdit ? 'Create Multiplier' : `Edit Multiplier - ${currentMultiplier?.name}`}
        </Typography>
      </div>

      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3} marginTop={5}>
          <Grid item xs={12} md={8}>
            <Card sx={{ p: 3 }}>
              <Box
                sx={{
                  display: 'grid',
                  columnGap: 2,
                  rowGap: 3,
                  gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                }}
              >
                <RHFTextField
                  name="multiplier"
                  label="Multiplier"
                  placeholder="0"
                  type="number"
                  sx={{
                    width: 180,
                    '&:after': {
                      content: '"-to-1"',
                      position: 'absolute',
                      right: '-48px',
                      top: '12px',
                      fontSize: '20px',
                    },
                  }}
                />

                <RHFTextField multiline maxRows={10} name="description" label="Description" />
              </Box>

              <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                  {!isEdit ? 'Create Multiplier' : 'Save Changes'}
                </LoadingButton>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </FormProvider>
    </CustomContainer>
  ) : (
    <LoadingScreen />
  );
}
