import { useDropzone } from 'react-dropzone';
import { styled } from '@mui/material/styles';
import { alpha, Box, IconButton, ListItem, Typography } from '@mui/material';
import { UploadProps } from './type';
import RejectionFiles from './RejectionFiles';
import BlockContent from './BlockContent';
import { m } from 'framer-motion';
import { varFade } from '../animate';
import Iconify from '../Iconify';
import getFileData from 'src/utils/getFileData';
import { useEffect, useState } from 'react';

// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  '&:hover': { opacity: 0.72, cursor: 'pointer' },
}));

// ----------------------------------------------------------------------

export default function UploadSingleFile({
  error = false,
  file,
  helperText,
  sx,
  onRemove,
  ...other
}: UploadProps) {
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple: false,
    ...other,
  });

  const [fileData, setFileData] = useState<any>(null);

  useEffect(() => {
    if (file) {
      let data = getFileData(file);
      setFileData(data);
    }
  }, [file]);
  
  return (
    <Box sx={{ width: '100%', ...sx }}>
      {!file && (
        <DropZoneStyle
          {...getRootProps()}
          sx={{
            ...(isDragActive && { opacity: 0.72 }),
            ...((isDragReject || error) && {
              color: 'error.main',
              borderColor: 'error.light',
              bgcolor: 'error.lighter',
            }),
          }}
        >
          <input {...getInputProps()} />

          <BlockContent />
        </DropZoneStyle>
      )}

      {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} />}

      {(file && fileData) && (
        <ListItem
          component={m.div}
          {...varFade().inRight}
          sx={{
            p: 1,
            m: 0.5,
            borderRadius: 1.25,
            overflow: 'hidden',
            position: 'relative',
            display: 'inline-flex',
            border: (theme) => `solid 1px ${theme.palette.divider}`,
          }}
        >
          {/* <Image alt="preview" src={fileData.preview} ratio="1/1" /> */}

          <Typography variant="subtitle2"> {fileData.name}</Typography>

          {onRemove && (
            <IconButton
              size="small"
              onClick={() => onRemove()}
              sx={{
                p: '2px',
                right: 6,
                position: 'absolute',
                color: 'common.white',
                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                '&:hover': {
                  bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
                },
              }}
            >
              <Iconify icon={'eva:close-fill'} />
            </IconButton>
          )}
        </ListItem>
      )}

      {helperText && helperText}
    </Box>
  );
}
