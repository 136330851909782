import {
    Checkbox,
    TableRow,
    TableCell,
    Typography,
    Tooltip,
    IconButton,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
  } from '@mui/material';
  import { useState } from 'react';
  import { DivisionList } from 'src/@types/master';
  import { Ads }  from 'src/@types/ads'
  import Iconify from 'src/components/Iconify';
  import AdView from './AdView';
  import moment from 'moment';
  // ----------------------------------------------------------------------
  
  type Props = {
    index: number;
    row: any;
    
  };
  
  export default function AdTableAnalyticsRow({
    index,
    row,
  }: Props) {
    const { adId, url, target, isClicked, userId, user_data } = row;
    const [open, setOpen] = useState(false);
    const [id, setId] = useState(0);
  
    const handleClose = () => {
      setOpen(false);
    };
  
    return (
      <TableRow hover>
        <TableCell padding="checkbox">
          <Checkbox />
        </TableCell>
        <TableCell align="center">{index + 1}</TableCell>
        <TableCell align="center">
          <Typography variant="subtitle2" noWrap>
            {adId}
          </Typography>
        </TableCell>
        <TableCell align="center">{url}</TableCell>
        <TableCell align="center">
          <Typography variant="subtitle2" noWrap>
            {target}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="subtitle2" noWrap>
            {isClicked}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="subtitle2" noWrap>
            {userId}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="subtitle2" noWrap>
            {user_data?.username}
          </Typography>
        </TableCell>
      </TableRow>
    );
  }
  