import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface TeamState {
  teams: any[];
  totalTeams: number;
}

const initialState: TeamState = {
  teams: [],
  totalTeams: 0,
};

export const teamSlice = createSlice({
  name: 'team',
  initialState,
  reducers: {
    teamListAction: (state, action: PayloadAction<TeamState>) => {
       if (!state.teams.length) {
        state.teams = action.payload.teams;
      } else {
         state.teams = [...state.teams, ...action.payload.teams];
      }

       state.totalTeams = action.payload.totalTeams;
    },
    resetAndListTeamsListAction: (state, action: PayloadAction<TeamState>) => {
      // Replace the existing racers array with the new one from the action's payload
    
      state.teams = action.payload.teams;
      // Update the totalRacers state with the new value from the action's payload
      state.totalTeams = action.payload.totalTeams;
    },    
    createTeamAction: (state, action: PayloadAction<TeamState>) => {
      state.teams.unshift(action.payload);
      state.totalTeams += 1;
    },
    deleteTeamAction: (state, action: PayloadAction<number>) => {
      const index = state.teams.findIndex((team) => team.id === action.payload);
      state.teams.splice(index, 1);
      state.totalTeams -= 1;
    },
    editTeamAction: (state, action: PayloadAction<any>) => {
      const index = state.teams.findIndex((team) => team.id === action.payload.id);
      state.teams[index] = action.payload;
    },
    emptyTeamListAction: (state) => {
      state.teams = [];
      state.totalTeams = 0;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  teamListAction,
  resetAndListTeamsListAction,
  createTeamAction,
  deleteTeamAction,
  editTeamAction,
  emptyTeamListAction
} = teamSlice.actions;

export const teamReducer = teamSlice.reducer;
