import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { ManufacturerList } from 'src/@types/master';

export interface ManufacturerState {
  manufacturers: ManufacturerList[];
  totalManufacturers: number;
}

const initialState: ManufacturerState = {
  manufacturers: [],
  totalManufacturers: 0,
};

export const manufacturerSlice = createSlice({
  name: 'manufacturer',
  initialState,
  reducers: {
    manufacturerListAction: (state, action: PayloadAction<ManufacturerState>) => {
      console.log('IN REDUX -', action.payload)
      if (!state.manufacturers.length) {
        state.manufacturers = action.payload.manufacturers;
      } else {
        state.manufacturers = [...state.manufacturers, ...action.payload.manufacturers];
      }

      state.totalManufacturers = action.payload.totalManufacturers;
    },

    resetAndListManufacturerListAction: (state, action: PayloadAction<ManufacturerState>) => {
      // Replace the existing racers array with the new one from the action's payload
    
      state.manufacturers = action.payload.manufacturers;
      // Update the totalRacers state with the new value from the action's payload
      state.totalManufacturers = action.payload.totalManufacturers;
    }, 
    createManufacturerAction: (state, action: PayloadAction<ManufacturerList>) => {
      state.manufacturers.unshift(action.payload);
      state.totalManufacturers += 1;
    },
    deleteManufacturerAction: (state, action: PayloadAction<number>) => {
      const index = state.manufacturers.findIndex(
        (manufacturer) => manufacturer.id === action.payload
      );
      state.manufacturers.splice(index, 1);
      state.totalManufacturers -= 1;
    },
    editManufacturerAction: (state, action: PayloadAction<any>) => {
      const index = state.manufacturers.findIndex(
        (manufacturer) => manufacturer.id === action.payload.id
      );

      state.manufacturers[index] = action.payload.manufacturer;
    },
    emptyManufacturerListAction: (state) => {
      state.manufacturers = [];
      state.totalManufacturers = 0;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  manufacturerListAction,
  createManufacturerAction,
  deleteManufacturerAction,
  editManufacturerAction,
  emptyManufacturerListAction,
  resetAndListManufacturerListAction
} = manufacturerSlice.actions;

export const manufacturerReducer = manufacturerSlice.reducer;
