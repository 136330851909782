import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Card, Chip, FormControlLabel, Grid, Stack, Switch, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useMemo, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router';
import { FormProvider, RHFSelect, RHFTextField } from 'src/components/hook-form';
import { PATH_MASTER } from 'src/routes/paths';
import { RacerList, RacerListResponseById } from 'src/@types/master';
import { racerService } from 'src/services/masters/racers';
import { createRacerAction, editRacerAction, RacerState } from 'src/store/racerSlice';
import { useDispatch, useSelector } from 'react-redux';
import LoadingScreen from 'src/components/LoadingScreen';
import Iconify from 'src/components/Iconify';
import CustomContainer from 'src/components/upload/CustomContainer';
import Label from 'src/components/Label';

import { sportsService } from 'src/services/sports';
import { setSelectedSportAction, setSportsAction } from 'src/store/sportsSlice';
import { SportsGetAllResponse } from 'src/@types/sports';
import { SportState } from 'src/store/sportsSlice';
import { TeamState, editTeamAction } from 'src/store/teamSlice';
import { teamService } from 'src/services/masters/teams';

export default function TeamCreate() {
  const [currentTeam, setCurrentTeam] = useState<any>();
  const [loader, setLoader] = useState(true);
  const dispatch = useDispatch();
  const teamList = useSelector((state: { team: TeamState }) => state.team.teams);

  const sports = useSelector((state: { sports: SportState }) => state.sports);
  const { pathname } = useLocation();
  const isEdit = pathname.includes('edit');

  const { id = '' } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {

    console.log('WHAT!!!!')

    console.log('isEdit - ', isEdit)

    console.log('currentTeam -', !currentTeam, currentTeam)

    console.log('Team List -', teamList)

    if (isEdit && !currentTeam) {

      const index = teamList.findIndex((team) => team.id === parseInt(id));

      console.log('index -', index)

      if (index !== -1) {
        setCurrentTeam(teamList[index]);
        setLoader(false);
      } else {
        setLoader(true);
        teamService
          .getTeamById(parseInt(id))
          .then((data: any) => {
            setCurrentTeam(data?.result);
            setLoader(false);
          })
          .catch((error) => {
            console.log(error)
            setLoader(false);
            enqueueSnackbar(error.response.data.message, { variant: 'error' });
            navigate(PATH_MASTER.team);
          });
      }
    }

    if (isEdit && currentTeam) {
      reset(defaultValues);
    }
    if (!isEdit) {
      setLoader(false);
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentTeam, sports.sportSelected]);

  const NewRacerSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .required('Name is required')
      .matches(/^[aA-zZ\s]+$/, 'Only letters are allowed'),
  });

  const defaultValues = useMemo(
    () => ({
      name: currentTeam?.name || '',
      isActive: currentTeam?.isActive && true,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentTeam]
  );

  const methods = useForm<any>({
    resolver: yupResolver(NewRacerSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const onSubmit = async (data: any) => {
    console.log("ADDING TEAM")
    const body = {
      name: data.name,
      sportsId: sports.sportSelected.id,
      isActive: data.isActive,
    };

    if (isEdit) {
      try {
        var result = await teamService.editTeam(parseInt(id), body);

        const editedRacer = {
          ...result.result
        };

        dispatch(editTeamAction(editedRacer));
        enqueueSnackbar('Update success!');
        navigate(PATH_MASTER.team);
      } catch (error) {
        console.error(error);
        reset();
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
      }
    } else {
      try {
        console.log("ADDING TEAM")
        const result = await teamService.createTeam(body);

        const newRacer = {
          ...result.data.result
        };

        if (result.status === 200) {
          reset();
          dispatch(createRacerAction(newRacer));
          enqueueSnackbar('Create success!');
          navigate(PATH_MASTER.team);
        }
      } catch (error) {
        console.error(error);
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
      }
    }
  };

  // useEffect(() => {z
  //   //gets all sports for drop down
  //   getAllSports()
  // }, []);

  // const getAllSports = () => {

  //   sportsService
  //     .getAllSports().then((data: SportsGetAllResponse) => {
  //       dispatch(setSportsAction(data.result))
  //     })
  //     .catch((error) => {
  //       enqueueSnackbar(error.response.data.message, { variant: 'error' });
  //       setLoader(false);
  //     });
  // }
  //console.log(sports)
  return !loader ? (
    <CustomContainer maxWidth={'xl'}>
      <div style={{ display: 'flex', alignItems: 'baseline' }}>
        <Iconify
          icon={'akar-icons:arrow-left'}
          sx={{ cursor: 'pointer', mr: 2, fontSize: 20 }}
          onClick={() => navigate(PATH_MASTER.team)}
        />
        <Typography variant="h3" component="h1" paragraph>
          {!isEdit ? 'Create Racer' : `Edit Racer - ${currentTeam?.name}`}
        </Typography>
      </div>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3} marginTop={5}>
          <Grid item xs={12} md={8}>
            <Card sx={{ p: 3 }}>

              <Chip label={`SPORT: ${sports?.sportSelected?.name}`} />
              <Label
                color={values.isActive ? 'success' : 'error'}
                sx={{ textTransform: 'uppercase', position: 'absolute', top: 24, right: 24 }}
              >
                {values.isActive ? 'Active' : 'Barred'}
              </Label>
              <Box
                sx={{
                  display: 'grid',
                  columnGap: 2,
                  rowGap: 2,
                  gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                  pt: 5,
                }}
              >
                <RHFTextField name="name" label="Team Name" maxRows={3} />

                <FormControlLabel
                  labelPlacement="start"
                  control={
                    <Controller
                      name="isActive"
                      control={control}
                      render={({ field }) => (
                        <Switch
                          {...field}
                          checked={field.value}
                          onChange={(event) => field.onChange(event.target.checked)}
                        />
                      )}
                    />
                  }
                  label={
                    <>
                      <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                        Status
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        Activate/Deactivate account
                      </Typography>
                    </>
                  }
                  sx={{ mx: 0, mb: 3, width: 1, justifyContent: 'space-between' }}
                />
              </Box>

              <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                  {!isEdit ? 'Create Team' : 'Save Changes'}
                </LoadingButton>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </FormProvider>
    </CustomContainer>
  ) : (
    <LoadingScreen />
  );
}
