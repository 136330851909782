import axios from 'axios';
import { LoginBody } from 'src/@types/auth';
import { UserList } from 'src/@types/user';
import { AuthState } from 'src/store/authSlice';
import { baseEndpoints } from './config';

const login = async (body: LoginBody) => {
  console.log(body)
  console.log(baseEndpoints.auth)
  const { data } = await axios.post(`${baseEndpoints.auth}/admin/login`, body)
  console.log(data)
  const userInfo: UserList = data.result;

  return userInfo;
};

const userDetails = localStorage.getItem('user');
const accessToken = localStorage.getItem('accessToken');

export const userDetailsParsed: AuthState = userDetails ? JSON.parse(userDetails) : '';

export const authorization = `Bearer ${accessToken}`;

export const authService = {
  login,
};
