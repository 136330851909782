import { Checkbox, TableRow, TableCell, Typography } from '@mui/material';
import { Results } from 'src/@types/events';

// ----------------------------------------------------------------------

type Props = {
  index: number;
  row: Results;
  selected: boolean;
  onSelectRow: VoidFunction;
};

export default function ResultTableRow({
  index,
  row,
  selected,
  onSelectRow,
}: Props) {
  const { ranking, privateer_data: racer, racerId } = row;

  return (
    <TableRow hover selected={selected}>
      <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell>

      <TableCell align="center">{ranking}</TableCell>

      <TableCell align="center">{racerId}</TableCell>

      <TableCell align="center">
        <Typography variant="subtitle2" noWrap>
          {racer?.name}
        </Typography>
      </TableCell>

      <TableCell align="center">{racer?.manufacturer_data?.name}</TableCell>
    </TableRow>
  );
}
