import { capitalCase } from 'change-case';
import { Container, Tab, Box, Tabs, Typography, Grid } from '@mui/material';
import useTabs from '../../hooks/useTabs';
import Iconify from '../../components/Iconify';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { userService } from 'src/services/users';
import UserPersonal from './UserPersonal';
import UserStripe from './UserStripe';
import LoadingScreen from 'src/components/LoadingScreen';
import { UserList, UserListResponseById } from 'src/@types/user';
import { PATH_USER } from 'src/routes/paths';
import { useSnackbar } from 'notistack';
import UserReferred from './UserReferred';
import { makeStyles } from '@material-ui/core';
import UserSubscription from './UserSubscription';

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  header: {
    position: 'fixed',
    top: '58px',
    zIndex: 1000,
    backgroundColor: 'white',
    paddingTop: '38px',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    width: '100%',
  },
}));

// ----------------------------------------------------------------------

export default function UserEdit() {
  const [currentUser, setCurrentUser] = useState<UserList>();
  const { currentTab, onChangeTab } = useTabs('general');
  const { pathname, state }: any = useLocation();
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const isEdit = pathname.includes('edit');

  const ACCOUNT_TABS = [
    {
      value: 'general',
      icon: <Iconify icon={'ic:round-account-box'} width={20} height={20} />,
      component: <UserPersonal isEdit={isEdit} currentUser={currentUser} />,
    },
    {
      value: 'billing',
      icon: <Iconify icon={'ic:round-receipt'} width={20} height={20} />,
      component: <UserStripe id={parseInt(id)} />,
    },
    {
      value: 'subscription',
      icon: <Iconify icon={'eva:bell-fill'} width={20} height={20} />,
      component: <UserSubscription id={parseInt(id)} />,
    },
    {
      value: 'referred friends',
      icon: <Iconify icon={'eva:share-fill'} width={20} height={20} />,
      component: <UserReferred id={parseInt(id)} />,
    },
  ];

  useEffect(() => {
    userService
      .getUserById(parseInt(id))
      .then((data: UserListResponseById) => {
        setCurrentUser(data?.result);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return currentUser ? (
    <Container maxWidth={'xl'}>
      <Grid className={classes.header}>
        <Grid item sx={{ display: 'flex', alignItems: 'baseline' }}>
          <Iconify
            icon={'akar-icons:arrow-left'}
            sx={{ cursor: 'pointer', mr: 2, fontSize: 20 }}
            onClick={() =>
              navigate(PATH_USER.root, {
                state: {
                  status: state ? state.status : null,
                },
              })
            }
          />
          <Typography variant="h4" component="h1" paragraph>
            {isEdit
              ? `Edit a User - ${currentUser.username}`
              : `User Details - ${currentUser.username}`}
          </Typography>
        </Grid>
        <Grid item sx={{ ml: 4.5 }}>
          <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={currentTab}
            onChange={onChangeTab}
          >
            {ACCOUNT_TABS.map((tab) => (
              <Tab
                disableRipple
                key={tab.value}
                label={capitalCase(tab.value)}
                icon={tab.icon}
                value={tab.value}
              />
            ))}
          </Tabs>
        </Grid>
      </Grid>

      {ACCOUNT_TABS.map((tab) => {
        const isMatched = tab.value === currentTab;
        return isMatched && <Box key={tab.value}>{tab.component}</Box>;
      })}
    </Container>
  ) : (
    <LoadingScreen />
  );
}
