import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Card, Chip, Grid, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useMemo, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router';
import { FormProvider, RHFTextField } from 'src/components/hook-form';
import { manufacturerService } from 'src/services/masters/manufacturers';
import { useDispatch, useSelector } from 'react-redux';
import { ManufacturerList, DivisionListResponseById, DivisionListBody } from 'src/@types/master';
import { PATH_MASTER } from 'src/routes/paths';
import {
  createManufacturerAction,
  editManufacturerAction,
  ManufacturerState,
} from 'src/store/manufacturerSlice';
import LoadingScreen from 'src/components/LoadingScreen';
import Iconify from 'src/components/Iconify';
import CustomContainer from 'src/components/upload/CustomContainer';
import { SportState } from 'src/store/sportsSlice';

export default function ManufacturerCreate() {
  const [currentManufacturer, setCurrentManufacturer] = useState<ManufacturerList>();
  const dispatch = useDispatch();
  const manufacturerList = useSelector(
    (state: { manufacturer: ManufacturerState }) => state.manufacturer.manufacturers
  );
  const sports = useSelector((state: { sports: SportState }) => state.sports);

  const { pathname } = useLocation();
  const isEdit = pathname.includes('edit');
  const [loader, setLoader] = useState(true);

  const { id = '' } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (isEdit && !currentManufacturer) {
      const index = manufacturerList.findIndex((man) => man.id === parseInt(id));

      if (index !== -1) {
        setCurrentManufacturer(manufacturerList[index]);
        setLoader(false);
      } else {
        manufacturerService
          .getManufacturerById(parseInt(id))
          .then((data) => {
            setCurrentManufacturer(data?.result);
            setLoader(false);
          })
          .catch((error) => {
            setLoader(false);
            enqueueSnackbar(error.response.data.message, { variant: 'error' });
            navigate(PATH_MASTER.manufacturer);
          });
      }
    }

    if (isEdit && currentManufacturer) {
      reset(defaultValues);
    }
    if (!isEdit) {
      setLoader(false);
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentManufacturer]);

  const NewManufacturerSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .required('Name is required'),
    description: Yup.string()
      .trim()
      .required('Description is required')
      .matches(/^[a-zA-Z0-9\s]+$/, 'Only letters and numbers are allowed')
      .max(250, 'Too long descritpion'),
  });

  const defaultValues = useMemo(
    () => ({
      name: currentManufacturer?.name || '',
      description: currentManufacturer?.description || '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentManufacturer]
  );

  const methods = useForm<ManufacturerList>({
    resolver: yupResolver(NewManufacturerSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: DivisionListBody) => {
    if (isEdit) {
      try {
        const result = await manufacturerService.editManufacturer(parseInt(id), {
          name: data.name,
          description: data.description,
          sportsId: sports.sportSelected.id
        });

        dispatch(editManufacturerAction({
          id: parseInt(id), manufacturer: result.result,
          sportsId: sports.sportSelected.id
        }));
        enqueueSnackbar('Update success!');
        navigate(PATH_MASTER.manufacturer);
      } catch (error) {
        console.error(error);
        reset();
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
      }
    } else {
      try {
        const result = await manufacturerService.createManufacturer({
          name: data.name,
          description: data.description,
          sportsId: sports.sportSelected.id
        });

        if (result.status === 200) {
          reset();
          dispatch(createManufacturerAction({
            ...result.data.result,
            sportsId: sports.sportSelected.id
          }));
          enqueueSnackbar(!isEdit ? 'Create success!' : 'Update success!');
          navigate(PATH_MASTER.manufacturer);
        }
      } catch (error) {
        console.error(error);
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
      }
    }
  };

  return !loader ? (
    <CustomContainer maxWidth={'xl'}>
      <div style={{ display: 'flex', alignItems: 'baseline' }}>
        <Iconify
          icon={'akar-icons:arrow-left'}
          sx={{ cursor: 'pointer', mr: 2, fontSize: 20 }}
          onClick={() => navigate(PATH_MASTER.manufacturer)}
        />
        <Typography variant="h3" component="h1" paragraph>
          {!isEdit ? 'Create Manufacturer' : `Edit Manufacturer - ${currentManufacturer?.name}`}
        </Typography>
      </div>

      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3} marginTop={5}>
          <Grid item xs={12} md={8}>
            <Card sx={{ p: 3 }}>
              <Chip label={`SPORT: ${sports?.sportSelected?.name}`} sx={{ mb: 2 }} />

              <Box
                sx={{
                  display: 'grid',
                  columnGap: 2,
                  rowGap: 3,
                  gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                }}
              >
                <RHFTextField name="name" label="Manufacturer Name" />
                <RHFTextField multiline maxRows={10} name="description" label="Description" />
              </Box>

              <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                  {!isEdit ? 'Create Manufacturer' : 'Save Changes'}
                </LoadingButton>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </FormProvider>
    </CustomContainer>
  ) : (
    <LoadingScreen />
  );
}
