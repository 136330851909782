import axios from 'axios';
import { RacerListBody } from 'src/@types/master';
import { baseEndpoints } from '../config';

const createTeam = async (body: any) => {
  const { data, status } = await axios.post(`${baseEndpoints.teams}/add`, body);

  return { data: data, status: status };
};

const getTeamList = async (body: any) => {
  console.log('sending shit rn -', body)
  const { data } = await axios.post(`${baseEndpoints.teams}/list`, body);

  return data;
};

const getTeamById = async (id: number) => {
  const { data } = await axios.get(`${baseEndpoints.teams}/get/${id}`);

  return data;
};

const deleteTeam = async (id: number) => {
  const { data } = await axios.delete(`${baseEndpoints.teams}/delete/${id}`);

  return data;
};

const editTeam = async (id: number, body: any) => {
  const { data } = await axios.post(`${baseEndpoints.teams}/edit/${id}`, body);

  return data;
};

const deleteMultipleTeams = async (body: { ids: number[] }) => {
  const { data } = await axios.post(`${baseEndpoints.teams}/multidelete`, body);

  return data;
};

const searchTeam = async (body: { search: string }) => {
  const { data } = await axios.post(`${baseEndpoints.teams}/search`, body);

  return data;
};

export const teamService = {
  createTeam,
  getTeamList,
  getTeamById,
  deleteTeam,
  editTeam,
  deleteMultipleTeams,
  searchTeam
};
