import { TableRow, TableCell, Typography, useTheme } from '@mui/material';
import Label from 'src/components/Label';
import { fDate } from 'src/utils/formatTime';
import { Subscription } from '../../@types/user';

// ----------------------------------------------------------------------

type Props = {
  row: Subscription;
  index: number;
};

export default function UserSubscriptionRow({ row, index }: Props) {
  const theme = useTheme();
  const { subscriptionId_data, endDate, type } = row;

  return (
    <TableRow hover>
      <TableCell align="center">{index + 1}</TableCell>

      <TableCell align="center">
        <Typography variant="subtitle2" noWrap>
          {subscriptionId_data.name}
        </Typography>
      </TableCell>

      <TableCell align="center"> {endDate ? fDate(endDate) : '-'}</TableCell>

      <TableCell align="center">
        <Label
          variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
          color={type === 'unsubscribed' ? 'error' : type === 'subscribed' ? 'success' : 'primary'}
          sx={{ textTransform: 'capitalize' }}
        >
          {type}
        </Label>
      </TableCell>
    </TableRow>
  );
}
