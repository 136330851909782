import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CommonState {
  currentPage: number;
  search: {
    user: string;
    manufacturer: string;
    multiplier: string;
    racer: string;
    team: string;
    event: string;
    request: string;
  };
}

const initialState: CommonState = {
  currentPage: 0,
  search: {
    user: '',
    manufacturer: '',
    multiplier: '',
    racer: '',
    team: '',
    event: '',
    request: '',
  },
};

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setSearch: (
      state,
      action: PayloadAction<{
        search: string;
        type: 'user' | 'manufacturer' | 'multiplier' | 'racer' | 'team' | 'event' | 'request';
      }>
    ) => {
      state.search[action.payload.type] = action.payload.search;
    },
  },
});

export const { setCurrentPage, setSearch } = commonSlice.actions;

export const commonReducer = commonSlice.reducer;
