export const baseEndpoints = {
  sports: `${process.env.REACT_APP_API_URL}/sports`,
  auth: `${process.env.REACT_APP_API_URL}/auth`,
  users: `${process.env.REACT_APP_API_URL}/users`,
  divisions: `${process.env.REACT_APP_API_URL}/divisions`,
  manufacturers: `${process.env.REACT_APP_API_URL}/manufacturers`,
  racers: `${process.env.REACT_APP_API_URL}/privateers`,
  teams: `${process.env.REACT_APP_API_URL}/teams`,
  multipliers: `${process.env.REACT_APP_API_URL}/multipliers`,
  events: `${process.env.REACT_APP_API_URL}/events`,
  wagers: `${process.env.REACT_APP_API_URL}/wagers`,
  results: `${process.env.REACT_APP_API_URL}/results`,
  chipBank: `${process.env.REACT_APP_API_URL}/chip-banks`,
  cms: `${process.env.REACT_APP_API_URL}/cms`,
  faqs: `${process.env.REACT_APP_API_URL}/faqs`,
  eventPrivateers: `${process.env.REACT_APP_API_URL}/event-privateers`,
  eventTeams: `${process.env.REACT_APP_API_URL}/event-teams`,
  awsResult : `${process.env.REACT_APP_AWS_URL}`,
  ads: `${process.env.REACT_APP_API_URL}/ads`
};
