import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Iconify from 'src/components/Iconify';
import { FormProvider, RHFTextField } from 'src/components/hook-form';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { RacerList } from 'src/@types/master';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Iconify icon={'eva:close-fill'} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

type RacerViewProps = {
  onClose: VoidFunction;
  open: boolean;
  currentTeam: any;
};

export default function TableView(props: any) {
  const defaultValues = useMemo(
    () => ({
      name: props.currentTeam?.name || '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.currentTeam]
  );

  const methods = useForm<any>({
    defaultValues,
  });

  return (
    <div>
      <BootstrapDialog
        onClose={props.onClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={props.onClose}>
          Team Details
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <FormProvider methods={methods}>
            <RHFTextField
              name="name"
              label="Team Name"
              sx={{ my: 2 }}
              InputProps={{
                readOnly: true,
              }}
            />
          </FormProvider>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
