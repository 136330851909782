import axios from 'axios';
import { baseEndpoints } from './config';

const addTeams = async (eventId: number, body: any) => {
    console.log("ADDING TEAMS")
    const { data } = await axios.post(`${baseEndpoints.eventTeams}/add-teams/${eventId}`, body);
    return data;
};

const getTeams = async (eventId: number) => {
    console.log("DIVISON EXISTS!!!")
    const { data } = await axios.get(
        `${baseEndpoints.eventTeams}/list-teams/${eventId}`
    );
    return data;

};

const getTeamsBySportId = async (eventId: number, sportId?: number,) => {
    console.log("DIVISON DEOSNT EXSITS!!!!!")
    const { data } = await axios.get(
        `${baseEndpoints.eventTeams}/list-teams/${eventId}/sport/${sportId}`
    );
    return data;

};

const updateTeams = async (eventId: number, body: any) => {
    const { data } = await axios.post(`${baseEndpoints.eventTeams}/update-teams/${eventId}`, body);
    return data;
};

export const eventTeamsService = {
    addTeams,
    getTeams,
    getTeamsBySportId,
    updateTeams
};
