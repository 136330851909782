import axios from 'axios';
import { MultiplierListBody } from 'src/@types/master';
import { baseEndpoints } from '../config';

const createMultiplier = async (body: MultiplierListBody) => {
  const { data, status } = await axios.post(`${baseEndpoints.multipliers}/add`, body);

  return { data: data, status: status };
};

const getMultiplierList = async (body: any) => {
  const { data } = await axios.post(`${baseEndpoints.multipliers}/list`, body);

  return data;
};

const getMultiplierById = async (id: number) => {
  const { data } = await axios.get(`${baseEndpoints.multipliers}/get/${id}`);

  return data;
};

const deleteMultiplier = async (id: number) => {
  const { data } = await axios.delete(`${baseEndpoints.multipliers}/delete/${id}`);

  return data;
};

const editMultiplier = async (id: number, body: MultiplierListBody) => {
  const { data } = await axios.post(`${baseEndpoints.multipliers}/edit/${id}`, body);

  return data;
};

const deleteMultipleMultiplier = async (body: { ids: number[] }) => {
  const { data } = await axios.post(`${baseEndpoints.multipliers}/multidelete`, body);

  return data;
};

const searchMultiplier = async (body: { search: string }) => {
  const { data } = await axios.post(`${baseEndpoints.multipliers}/search`, body);

  return data;
};

export const multiplierService = {
  createMultiplier,
  getMultiplierList,
  getMultiplierById,
  deleteMultiplier,
  editMultiplier,
  deleteMultipleMultiplier,
  searchMultiplier,
};
