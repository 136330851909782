import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { DivisionList } from 'src/@types/master';

export interface DivisionState {
  divisions: DivisionList[];
  totalDivisions: number;
}

const initialState: DivisionState = {
  divisions: [],
  totalDivisions: 0,
};

export const divisionSlice = createSlice({
  name: 'division',
  initialState,
  reducers: {
    divisionListAction: (state, action: PayloadAction<DivisionState>) => {
      if (!state.divisions.length) {
        state.divisions = action.payload.divisions;
      } else {
        state.divisions = [...state.divisions, ...action.payload.divisions];
      }

      state.totalDivisions = action.payload.totalDivisions;
    },
    createDivisionAction: (state, action: PayloadAction<DivisionList>) => {
      state.divisions.unshift(action.payload);
      state.totalDivisions += 1;
    },
    deleteDivisionAction: (state, action: PayloadAction<number>) => {
      const index = state.divisions.findIndex((division) => division.id === action.payload);
      state.divisions.splice(index, 1);
      state.totalDivisions -= 1;
    },
    editDivisionAction: (state, action: PayloadAction<any>) => {
      const index = state.divisions.findIndex((division) => division.id === action.payload.id);
      state.divisions[index] = action.payload.division;
    },
    emptyDivisionListAction: (state) => {
      state.divisions = [];
      state.totalDivisions = 0;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  divisionListAction,
  createDivisionAction,
  deleteDivisionAction,
  editDivisionAction,
  emptyDivisionListAction,
} = divisionSlice.actions;

export const divisionReducer = divisionSlice.reducer;
