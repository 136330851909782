import Grid from '@mui/material/Grid';
import { Box, Container, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { racerService } from 'src/services/masters/racers';
import { useSnackbar } from 'notistack';
import LoadingScreen from 'src/components/LoadingScreen';
import { divisionService } from 'src/services/masters/divisions';
import MultiSelectRacer from './MultiSelectRacer';
import { DivisionList } from 'src/@types/master';
import { useLocation } from 'react-router';
import { teamService } from 'src/services/masters/teams';

// ----------------------------------------------------------------------

export default function RacerList(props: any) {
  const [loader, setLoader] = useState(true);
  const [divisionList, setDivisionList] = useState<DivisionList[]>([]);
  const [racerList, setRacerList] = useState();
  const [teamList, setTeamList] = useState();
  const { enqueueSnackbar } = useSnackbar();
  console.log('penis -', props.event)
  useEffect(() => {
    console.log('')
    console.log('')
    console.log('')
    console.log('NEXT AOHAODITGHOAIEHG -', props.event)
    // THERE IS NO SPORTS DATA
    if (props.event?.sports_data?.type === 'event') {
      console.log("NEXT EVENT")
      getDivisionList();
      getAllRacerList();
    }
    else if (props.event?.sports_data?.type === 'match') {
      console.log("NEXT MATCH")
      getAllTeams()
    }

    console.log('')
    console.log('')
    console.log('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDivisionList = () => {
    divisionService
      .getDivisionList({})
      .then((data) => {
        const modifiedList = data.result.map((division: any) => ({
          id: division.id,
          name: division.name,
        }));
        setDivisionList(modifiedList);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        setLoader(false);
      });
  };

  const getAllRacerList = () => {
    racerService
      .getRacerList({ sportsId: props?.event?.sportsId })
      .then((data) => {
        console.log(data)
        const modifiedList = data.result.map((racer: any) => ({ id: racer.id, name: racer.name }));
        console.log(modifiedList)
        setRacerList(modifiedList);
        setLoader(false);
      })
      .catch((error) => {
        console.log(error)
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        setLoader(false);
      });
  };


  const getAllTeams = () => {
    teamService
      .getTeamList({ sportsId: props?.event?.sportsId })
      .then((data) => {
        console.log(data)
        const modifiedList = data.result.map((team: any) => ({ id: team.id, name: team.name }));
        setTeamList(modifiedList);
        setLoader(false);
      })
      .catch((error) => {
        console.log(error)
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        setLoader(false);
      });
  };

  console.log(teamList)
  console.log(racerList)
  console.log(props)
  return (
    <Container maxWidth={'xl'}>
      {loader && <LoadingScreen isDashboard={true} />}
      <Grid container spacing={3} sx={{ mt: 12 }}>
        <Grid item xs={12} md={10}>
          {!loader &&
            racerList &&
            !!racerList &&
            props.event.sportsId === 1
            &&
            divisionList?.map((division: DivisionList) => (
              <MultiSelectRacer
                key={division.id}
                division={division}
                racerList={racerList}
                event={props.event}
              />
            ))
          }

          {!loader &&
            racerList &&
            !!racerList &&
            props.event.sportsId != 1
            &&
            <MultiSelectRacer
              racerList={racerList}
              event={props.event}
            />
          }

          {!loader &&
            teamList &&
            !!teamList &&
            props.event.sportsId != 1
            &&
            <Box sx={{
              mt: 4,
              display: 'flex',
              flexDirection: 'column',
              gap: 3
            }}>
              <Typography variant='h5'>Select Home Team</Typography>
              <MultiSelectRacer
                racerList={teamList}
                event={props.event}
                homeOrAwayType={'home'}
              />
            </Box>
          }

          {!loader &&
            teamList &&
            !!teamList &&
            props.event.sportsId != 1
            &&
            <Box sx={{
              mt: 4,
              display: 'flex',
              flexDirection: 'column',
              gap: 3
            }}>
              <Typography variant='h5'>Select Away Team</Typography>
              <MultiSelectRacer
                racerList={teamList}
                event={props.event}
                homeOrAwayType={'away'}
              />
            </Box>
          }
        </Grid>
      </Grid>
    </Container>
  );
}

/*


            <MultiSelectRacer
              racerList={racerList}
              eventId={props.eventId}
              eventSportId={props.event.sports_data.id}
            />

            */