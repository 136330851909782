import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Card, Container, Grid, Paper, Stack, Typography } from '@mui/material';
import { BillingDetails } from '../../@types/user';
import { userService } from 'src/services/users';
import Image from '../../components/Image';
import LoadingScreen from 'src/components/LoadingScreen';

// ----------------------------------------------------------------------

type Props = {
  id: number;
};

export default function UserStripe({ id }: Props) {
  const [currentDetails, setCurrentDetails] = useState<BillingDetails | null>(null);
  const [loader, setLoader] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    userService
      .getBillingDetails(id)
      .then((data) => {
        setCurrentDetails(data.result);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log('cardDetails', currentDetails);

  return (
    <Container maxWidth={'xl'} sx={{ mt: 12 }}>
      {loader && <LoadingScreen isDashboard={true} />}
      {currentDetails ? (
        <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>
          <Grid item xs={12} md={6}>
            <Card sx={{ p: 3 }}>
              <Typography
                variant="overline"
                sx={{ mb: 3, display: 'block', color: 'text.secondary' }}
              >
                Payment Method
              </Typography>

              <Stack spacing={2} direction={{ xs: 'column', md: 'row' }}>
                <Paper
                  sx={{
                    p: 3,
                    width: 1,
                    position: 'relative',
                    border: (theme) => `solid 1px ${theme.palette.grey[500_32]}`,
                  }}
                >
                  <Image
                    alt="icon"
                    src={
                      currentDetails?.card.brand !== 'visa'
                        ? 'https://minimal-assets-api-dev.vercel.app/assets/icons/ic_mastercard.svg'
                        : 'https://minimal-assets-api-dev.vercel.app/assets/icons/ic_visa.svg'
                    }
                    sx={{ mb: 1, maxWidth: 36 }}
                  />
                  <Typography variant="subtitle2">
                    **** **** **** {currentDetails?.card.last4}
                  </Typography>
                </Paper>
              </Stack>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3} alignItems="flex-start">
                <Typography variant="overline" sx={{ color: 'text.secondary' }}>
                  Billing Info
                </Typography>

                <Paper
                  sx={{
                    p: 3,
                    width: 1,
                    bgcolor: 'background.neutral',
                  }}
                >
                  {/* <Typography variant="subtitle1" gutterBottom>
              {  address.name}
            </Typography> */}

                  <Typography variant="body2" gutterBottom>
                    <Typography variant="body2" component="span" sx={{ color: 'text.secondary' }}>
                      Address: &nbsp;
                    </Typography>
                    {`${
                      currentDetails.billing_details.address.line1
                        ? `${currentDetails.billing_details.address.line1},`
                        : ''
                    } ${
                      currentDetails.billing_details.address.city
                        ? `${currentDetails.billing_details.address.city},`
                        : ''
                    } ${
                      currentDetails.billing_details.address.state
                        ? `${currentDetails.billing_details.address.state},`
                        : ''
                    } ${
                      currentDetails.billing_details.address.country
                        ? `${currentDetails.billing_details.address.country},`
                        : ''
                    } ${
                      currentDetails.billing_details.address.postal_code
                        ? `${currentDetails.billing_details.address.postal_code}`
                        : ''
                    }`}
                  </Typography>

                  <Typography variant="body2" gutterBottom>
                    <Typography variant="body2" component="span" sx={{ color: 'text.secondary' }}>
                      Phone: &nbsp;
                    </Typography>
                    {currentDetails.billing_details.phone
                      ? currentDetails.billing_details.phone
                      : '-'}
                  </Typography>
                </Paper>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      ) : (
        <Card sx={{ p: 3 }}>
          <Typography variant="overline" sx={{ display: 'block', color: 'text.primary' }}>
            No Card Found
          </Typography>
        </Card>
      )}
    </Container>
  );
}
