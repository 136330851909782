import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Ads } from 'src/@types/ads';

export interface AdState {
  ads: Ads[];
  totalAds: number
}

const initialState: AdState = {
  ads: [],
  totalAds: 0
};

export const AdSlice = createSlice({
  name: 'ads',
  initialState,
  reducers: {
    adsListAction: (state, action: PayloadAction<AdState>) => {
      if (!state.ads.length) {
        state.ads = action.payload.ads;
      } else {
        state.ads = [...state.ads, ...action.payload.ads];
      }

      state.totalAds = action.payload.totalAds;
    },
    createAdAction: (state, action: PayloadAction<Ads>) => {
      state.ads.unshift(action.payload);
      state.totalAds += 1
    },
    deleteAdAction: (state, action: PayloadAction<number>) => {      
      const index = state.ads.findIndex((ads) => ads.id === action.payload);
      state.ads.splice(index, 1);
      state.totalAds -= 1;
    },
    editAdAction: (state, action: PayloadAction<any>) => {
      const index = state.ads.findIndex((ads) => ads.id === action.payload.id);
      state.ads[index] = action.payload;
    },
    // emptyListAction: (state) => {
    //   state.events = [];
    //   state.totalEvents = 0;
    // },
    // changeStatusAction: (state, action: PayloadAction<number>) => {
    //   const index = state.events.findIndex((event) => event.id === action.payload);
    //   state.events[index].resultStatus = 'completed'
    // }
    
  },
});

export const {
    createAdAction,
    adsListAction,
    deleteAdAction,
    editAdAction
} = AdSlice.actions;

export const adReducer = AdSlice.reducer;
