import {
  Container,
  Card,
  TableContainer,
  Tooltip,
  IconButton,
  Table,
  TableBody,
  TablePagination,
  FormControlLabel,
  Switch,
  Box,
  Divider,
  Tab,
  Tabs,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Results } from 'src/@types/events';
import { DivisionList } from 'src/@types/master';
import Iconify from 'src/components/Iconify';
import LoadingScreen from 'src/components/LoadingScreen';
import Scrollbar from 'src/components/Scrollbar';
import { TableSelectedActions, TableHeadCustom, TableNoData } from 'src/components/table';
import useTable, { getComparator } from 'src/hooks/useTable';
import { eventService } from 'src/services/events';
import { divisionService } from 'src/services/masters/divisions';
import UserTableToolbar from '../Users/UserTableToolbar';
import ResultTableRow from './ResultTableRow';

// ----------------------------------------------------------------------
const STATUS_OPTIONS = ['250cc', '450cc'];

const TABLE_HEAD = [
  { id: 'ranking', label: 'Position', align: 'center' },
  { id: 'racerId', label: 'Racer Id', align: 'center' },
  { id: 'racer_name', label: 'Racer Name', align: 'center' },
  { id: 'manufacturer', label: 'Manufacturer', align: 'center' },
];

// ----------------------------------------------------------------------

type Props = {
  id: number;
};

export default function EventResults({ id }: Props) {
  const { dense, order, orderBy, selected, onSelectRow, onSelectAllRows, onChangeDense } = useTable(
    { defaultOrderBy: 'ranking' }
  );

  const [tableData, setTableData] = useState<Results[]>([]);
  const [resultsCount, setResultsCount] = useState<number>(0);
  const [loader, setLoader] = useState(true);
  const [filterName, setFilterName] = useState('');
  const [filterStatus, setFilterStatus] = useState('250cc');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [divisionList, setDivisionList] = useState<DivisionList[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    divisionService
      .getDivisionList({})
      .then((data) => {
        let index = data.result.findIndex((div: DivisionList) => div.name === filterStatus);

        setDivisionList(data.result);
        eventService
          .getResultsByEventId(id, {
            skip: 0,
            limit: rowsPerPage,
            filter: {
              division: data.result[index].id,
            },
          })
          .then((data) => {
            setTableData(data.result);
            setResultsCount(data.count);
            setLoader(false);
          })
          .catch((error) => {
            setLoader(false);
            enqueueSnackbar(error.response.data.message, { variant: 'error' });
          });
      })
      .catch((error) => {
        setLoader(false);
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeFilterStatus = (event: React.SyntheticEvent<Element, Event>, newValue: string) => {
    setFilterName('');
    setFilterStatus(newValue);
    setLoader(true);

    let index = divisionList?.findIndex((div: DivisionList) => div.name === newValue);

    eventService
      .getResultsByEventId(id, {
        skip: 0,
        limit: rowsPerPage,
        filter: {
          division: divisionList[index].id,
        },
      })
      .then((data) => {
        setTableData(data.result);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        setTableData([]);
        setResultsCount(0);
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
      });

    setPage(0);
  };

  const onChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rows = parseInt(event.target.value);

    let index = divisionList?.findIndex((div: DivisionList) => div.name === filterStatus);

    setLoader(true);
    eventService
      .getResultsByEventId(id, {
        skip: 0,
        limit: rows,
        filter: {
          division: divisionList[index].id,
        },
      })
      .then((data) => {
        setTableData(data.result);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
      });

    setRowsPerPage(rows);
    setPage(0);
  };

  const onChangePage = (event: unknown, newPage: number) => {
    if (page < newPage) {
      let index = divisionList?.findIndex((div: DivisionList) => div.name === filterStatus);

      if (tableData.length > newPage * rowsPerPage) {
        setPage(newPage);
        return;
      }
      setLoader(true);
      eventService
        .getResultsByEventId(id, {
          skip: newPage * rowsPerPage,
          limit: rowsPerPage,
          filter: {
            division: divisionList[index].id,
          },
        })
        .then((data) => {
          setTableData((prevState) => [...prevState, ...data.result]);
          setLoader(false);
        })
        .catch((error) => {
          setLoader(false);
          enqueueSnackbar(error.response.data.message, { variant: 'error' });
        });
    }

    setPage(newPage);
  };

  const handleFilterName = (filterName: string) => {
    setFilterName(filterName);
    let index = divisionList?.findIndex((div: DivisionList) => div.name === filterStatus);

    if (filterName?.length >= 3) {
      setLoader(true);

      eventService
        .searchResultsByEventId(id, {
          search: filterName,
          filter: {
            division: divisionList[index].id,
          },
        })
        .then((data) => {
          setTableData(data.result);
          setResultsCount(data.result.length);
          setLoader(false);
        })
        .catch((error) => {
          setLoader(false);
          enqueueSnackbar(error.response.data.message, { variant: 'error' });
        });
    }

    if (!filterName) {
      setLoader(true);
      eventService
        .getResultsByEventId(id, {
          skip: 0,
          limit: rowsPerPage,
          filter: {
            division: divisionList[index].id,
          },
        })
        .then((data) => {
          setTableData(data.result);
          setResultsCount(data.count);
          setLoader(false);
        })
        .catch((error) => {
          setLoader(false);
          enqueueSnackbar(error.response.data.message, { variant: 'error' });
        });
    }

    setPage(0);
  };

  const dataFiltered = applySortFilter({
    resultList: tableData,
    comparator: getComparator(order, orderBy),
    filterName,
  });

  const isNotFound = !tableData.length;

  return (
    <Container maxWidth={'xl'} sx={{ mt: 12 }}>
      {loader && <LoadingScreen isDashboard={true} />}

      <Card>
        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={filterStatus}
          onChange={onChangeFilterStatus}
          sx={{ px: 2, bgcolor: 'background.neutral' }}
        >
          {STATUS_OPTIONS.map((tab) => (
            <Tab disableRipple key={tab} label={tab} value={tab} />
          ))}
        </Tabs>

        <Divider />

        <UserTableToolbar
          isSearch={false}
          filterName={filterName}
          onFilterName={handleFilterName}
        />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
            {selected.length > 0 && (
              <TableSelectedActions
                dense={dense}
                numSelected={selected.length}
                rowCount={tableData.length}
                onSelectAllRows={(checked) =>
                  onSelectAllRows(
                    checked,
                    tableData.map((row: { id: number }) => row.id)
                  )
                }
                actions={
                  <Tooltip title="Delete">
                    <IconButton color="primary">
                      <Iconify icon={'eva:trash-2-outline'} />
                    </IconButton>
                  </Tooltip>
                }
              />
            )}

            <Table size={dense ? 'small' : 'medium'}>
              <TableHeadCustom
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={tableData.length}
                numSelected={selected.length}
                onSelectAllRows={(checked) =>
                  onSelectAllRows(
                    checked,
                    tableData.map((row: { id: number }) => row.id)
                  )
                }
                minWidth={'128px'}
              />

              <TableBody>
                {dataFiltered
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <ResultTableRow
                      key={row.id}
                      index={page === 0 ? index : rowsPerPage * page + index}
                      row={row}
                      selected={selected.includes(row.id)}
                      onSelectRow={() => onSelectRow(row.id)}
                    />
                  ))}

                <TableNoData isNotFound={isNotFound} />
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        <Box sx={{ position: 'relative' }}>
          <TablePagination
            rowsPerPageOptions={[5, 15, 25]}
            component="div"
            count={resultsCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
          />

          <FormControlLabel
            control={<Switch checked={dense} onChange={onChangeDense} />}
            label="Dense"
            sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
          />
        </Box>
      </Card>
    </Container>
  );
}

// ----------------------------------------------------------------------

function applySortFilter({
  resultList,
  comparator,
  filterName,
}: {
  resultList: Results[];
  comparator: (a: any, b: any) => number;
  filterName: string;
}) {
  const stabilizedThis = resultList.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  resultList = stabilizedThis.map((el) => el[0]);

  return resultList;
}
