import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { Faqs } from 'src/@types/faqs';

// ----------------------------------------------------------------------

type Props = {
  faq: Faqs;
  onDelete: VoidFunction;
  openDelete: boolean;
  onCloseAlert: VoidFunction;
};

export default function FaqDeletePopup({ faq, openDelete, onCloseAlert, onDelete }: Props) {
  return (
    <Dialog open={openDelete}>
      <DialogTitle>Delete FAQ ? </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" paddingTop={3}>
          Are you sure that you want to delete FAQ ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onCloseAlert();
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={(e) => {
            onCloseAlert();
            onDelete();
          }}
          autoFocus
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
