import { useState, useEffect } from 'react';
import Page from 'src/components/Page';
import CustomContainer from 'src/components/upload/CustomContainer';
import { CommonState, setSearch } from 'src/store/commonSlice';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Stack, Typography, Table } from '@mui/material';
import Iconify from 'src/components/Iconify';
import LoadingScreen from 'src/components/LoadingScreen';
import { TableNoData } from 'src/components/table';
import {PATH_ADS} from 'src/routes/paths'
import AdListTable from './AdListTable';
import { adService } from 'src/services/ads';
import { AdState, adsListAction } from 'src/store/adSlice';
import { AdsListResponse } from 'src/@types/ads';
import { useSnackbar } from 'notistack';


export default function AdSystem() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [loader, setLoader] = useState(true);
  const [progress, setProgress] = useState(false);
  const adList = useSelector((state: { ads: AdState }) => state?.ads);


  useEffect(() => { 
    if (!adList?.ads?.length || adList?.ads?.length === 0) {
      adService
        .getAdsList({})
        .then((data: AdsListResponse) => {
          dispatch(adsListAction({ ads: data?.result, totalAds: data?.count }));
          setLoader(false);
        })
        .catch((error) => {
          enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
          setLoader(false);
        });
      } else {
        setLoader(false)
    }
  }, [])
  const handleLoading = (value: boolean, type?: string) => {
    if (type === 'LOADER') {
      setLoader(value);
      return;
    }
    setProgress(value);
  };

    return !loader ? (
        <Page title="Ads">
          <CustomContainer maxWidth={'xl'}>
            <Typography variant="h3" component="h1" paragraph>
              Ads
            </Typography>
            <Stack
              spacing={2}
              direction={{ xs: 'column', sm: 'row' }}
              sx={{ justifyContent: 'flex-end' }}
            >
              <Button
                variant="contained"
                startIcon={<Iconify icon={'eva:plus-fill'} />}
                sx={{ py: 1.5 }}
                component={RouterLink}
                to={PATH_ADS.new}
              >
                Add Ads
              </Button>
            </Stack>
          </CustomContainer>
    
          {/* <RequestsListTable progress={progress} onLoading={handleLoading} /> */}
         <AdListTable progress={progress} onLoading={handleLoading} />
        </Page>
      ) : (
        <LoadingScreen />
      );
}