import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Stripe } from 'src/@types/requests';

export interface StripeState {
  available:any
  pending:any
  connect_reserved:any
}

const initialState: StripeState = {
    available:[],
    pending: [],
    connect_reserved:[]
};

export const stripeSlice = createSlice({
  name: 'requests',
  initialState,
  reducers: {
    
    requestStripeBalance: (state, action: PayloadAction<any>) => {
      state.available = action.payload?.available
      state.pending = action.payload?.pending
      state.connect_reserved = action.payload?.connect_reserve

    }
  },
});

// Action creators are generated for each case reducer function
export const { requestStripeBalance } =
stripeSlice.actions;

export const stripeReducer = stripeSlice.reducer;
