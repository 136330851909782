import { useState } from 'react';
import {
  Box,
  Card,
  Table,
  Switch,
  Tooltip,
  TableBody,
  Container,
  IconButton,
  TableContainer,
  TablePagination,
  FormControlLabel,
  LinearProgress,
} from '@mui/material';
import RacerTableRow from './TeamTableRow';
import { TeamListResponse } from 'src/@types/master';
import Iconify from 'src/components/Iconify';
import Page from 'src/components/Page';
import Scrollbar from 'src/components/Scrollbar';
import { TableSelectedActions, TableHeadCustom, TableNoData } from 'src/components/table';
import useTable, { getComparator } from 'src/hooks/useTable';
import UserTableToolbar from 'src/views/Users/UserTableToolbar';
import { PATH_MASTER } from 'src/routes/paths';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { emptyTeamListAction, teamListAction, TeamState, resetAndListTeamsListAction, deleteTeamAction } from 'src/store/teamSlice';

import { racerService } from 'src/services/masters/racers';
import { useSnackbar } from 'notistack';
import MultiDeletePopup from 'src/components/MultiDeletePopup';
import { ceil } from 'lodash';
import { CommonState, setCurrentPage, setSearch } from 'src/store/commonSlice';

import { sportsService } from 'src/services/sports';
import { setSelectedSportAction, setSportsAction } from 'src/store/sportsSlice';
import { SportsGetAllResponse } from 'src/@types/sports';
import { SportState } from 'src/store/sportsSlice';
import { teamService } from 'src/services/masters/teams';
import TeamTableRow from './TeamTableRow';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'Sr.No', align: 'center' },
  { id: 'name', label: 'Racer', align: 'center' },
  { id: 'isActive', label: 'Status', align: 'center' },
  { id: '', label: 'Actions', align: 'center' },
];

// ----------------------------------------------------------------------

type RacerListProps = {
  progress: boolean;
  onLoading: Function;
};

export default function TeamListTable({ progress, onLoading }: RacerListProps) {
  const {
    dense,
    order,
    orderBy,
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    onChangeDense,
  } = useTable();

  const dispatch = useDispatch();
  const teamList = useSelector((state: { team: TeamState }) => state.team.teams);
  const totalLength = useSelector((state: { team: TeamState }) => state.team.totalTeams);
  const storePage = useSelector((state: { common: CommonState }) => state.common.currentPage);
  const storeSearch = useSelector((state: { common: CommonState }) => state.common.search.racer);
  const sports = useSelector((state: { sports: SportState }) => state.sports);

  const [filterName, setFilterName] = useState(storeSearch || '');
  const [page, setPage] = useState(storePage || 0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openAlert, setOpenAlert] = useState(false);
  const [openMultiAlert, setOpenMultiAlert] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const onChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    const rows = parseInt(event.target.value);
    const teamArrLength = teamList.length;

    const condition = page === 0 ? teamArrLength < rows : teamArrLength < page * rows;
    const skipCondition = teamArrLength <= totalLength ? teamArrLength : 0;

    if (condition && teamArrLength !== totalLength) {
      onLoading(true);
      teamService
        .getTeamList({ skip: skipCondition, limit: rows - teamArrLength, sportsId: sports.sportSelected.id })
        .then((data: TeamListResponse) => {
          console.log('retriving teams list - ', data)
          dispatch(teamListAction({ teams: data?.result, totalTeams: data?.count }));
          onLoading(false);
        })
        .catch((error) => {
          onLoading(false);
          enqueueSnackbar(error.response.data.message, { variant: 'error' });
        });
    }

    setRowsPerPage(rows);
    setPage(0);
  };

  const onChangePage = (event: unknown, newPage: number) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    console.log("ONCHANGE PAFE")
    if (page < newPage) {
      if (teamList.length > newPage * rowsPerPage) {
        setSelected([]);
        setPage(newPage);
        return;
      }
      onLoading(true);
      teamService
        .getTeamList({
          skip: newPage * rowsPerPage,
          limit: rowsPerPage,
          sportsId: sports.sportSelected.id
        })
        .then((data: TeamListResponse) => {
          dispatch(teamListAction({ teams: data?.result, totalTeams: data?.count }));
          onLoading(false);
        })
        .catch((error) => {
          onLoading(false);
          enqueueSnackbar(error.response.data.message, { variant: 'error' });
        });
    }

    setSelected([]);
    setPage(newPage);
  };

  const handleFilterName = (filterName: string) => {
    setFilterName(filterName);
    if (filterName?.trim().length >= 3) {
      onLoading(true);
      teamService
        .searchTeam({ search: filterName.trim() })
        .then((data) => {
          onLoading(false);
          dispatch(emptyTeamListAction());
          dispatch(teamListAction({ teams: data?.result, totalTeams: data?.count }));
        })
        .catch((error) => {
          onLoading(false);
          enqueueSnackbar(error.response.data.message, { variant: 'error' });
        });
    }

    if (!filterName) {
      onLoading(true);
      teamService
        .getTeamList({
          skip: 0,
          limit: rowsPerPage,
          sportsId: sports.sportSelected.id
        })
        .then((data: TeamListResponse) => {
          dispatch(emptyTeamListAction());
          dispatch(teamListAction({ teams: data?.result, totalTeams: data?.count }));
          onLoading(false);
        })
        .catch((error) => {
          onLoading(false);
          enqueueSnackbar(error.response.data.message, { variant: 'error' });
        });
    }

    setPage(0);
  };

  const handleBlur = (filterName: string) => {
    dispatch(setSearch({ search: filterName, type: 'racer' }));
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleDeleteRow = (id: number, type: string) => {
    if (type === 'POPUP') {
      setOpenAlert(true);
    } else {
      onLoading(true);
      teamService
        .deleteTeam(id)
        .then((data) => {
          dispatch(deleteTeamAction(id));
          enqueueSnackbar('Deleted Successfully');
          onLoading(false);
        })
        .catch((error) => {
          enqueueSnackbar(error.response.data.message, { variant: 'error' });
          onLoading(false);
        });
    }
  };

  const handleMultiAlert = () => {
    setOpenMultiAlert(true);
  };

  const handleDeleteRows = async () => {
    try {

      console.log(" DELETE")

      onLoading(true, 'LOADER');

      setSelected([]);

      const data = await racerService.deleteMultipleRacer({ ids: selected });

      const updatedData = await racerService.getRacerList({
        skip: 0,
        limit: 5,
        sportsId: sports.sports.length > 0 ? sports.sportSelected.id : 1
      });

      dispatch(emptyTeamListAction());
      dispatch(
        teamListAction({
          teams: updatedData?.result,
          totalTeams: updatedData?.count,
        })
      );

      enqueueSnackbar(data.message);
      onLoading(false, 'LOADER');
    } catch (error) {
      onLoading(false, 'LOADER');
      enqueueSnackbar(error.response.data.message, { variant: 'error' });
    }
  };

  const handleEditRow = (id: number) => {
    dispatch(setCurrentPage(page));
    navigate(PATH_MASTER.teamEdit(id));
  };

  const dataFiltered = applySortFilter({
    teamList: teamList,
    comparator: getComparator(order, orderBy),
    filterName,
    sportsId: !!sports?.sportSelected.id ? sports?.sportSelected?.id : 1
  });

  console.log('racer -', teamList)
  console.log('filtered -', dataFiltered)
  console.log('sports id -', sports.sportSelected.id)
  const isNotFound = !totalLength;

  return (
    <Page title="Racer: List">
      <Container maxWidth={'xl'} sx={{ mt: 17 }}>
        <Card>
          {progress && <LinearProgress sx={{ mx: 1, mt: 0.1 }} />}

          <UserTableToolbar
            isSearch={false}
            filterName={filterName}
            onFilterName={handleFilterName}
            onBlurField={handleBlur}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              {selected.length > 0 && (
                <TableSelectedActions
                  dense={dense}
                  numSelected={selected.length}
                  rowCount={
                    page + 1 === ceil(totalLength / rowsPerPage) && totalLength % rowsPerPage !== 0
                      ? totalLength % rowsPerPage
                      : rowsPerPage
                  }
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      teamList
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => row.id)
                    )
                  }
                  actions={
                    <Tooltip title="Delete">
                      <IconButton color="primary" onClick={handleMultiAlert}>
                        <Iconify icon={'eva:trash-2-outline'} />
                      </IconButton>
                    </Tooltip>
                  }
                />
              )}

              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={
                    page + 1 === ceil(totalLength / rowsPerPage) && totalLength % rowsPerPage !== 0
                      ? totalLength % rowsPerPage
                      : rowsPerPage
                  }
                  numSelected={selected.length}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      teamList
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => row.id)
                    )
                  }
                />

                <TableBody>
                  {dataFiltered
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <TeamTableRow
                        key={row.id}
                        index={page === 0 ? index : rowsPerPage * page + index}
                        row={row}
                        selected={selected.includes(row.id)}
                        onSelectRow={() => onSelectRow(row.id)}
                        onDeleteRow={(type: string) => handleDeleteRow(row.id, type)}
                        onEditRow={() => handleEditRow(row.id)}
                        openAlert={openAlert}
                        onCloseAlert={() => handleCloseAlert()}
                      />
                    ))}

                  <TableNoData isNotFound={isNotFound} />
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[5, 15, 25]}
              component="div"
              count={isNotFound ? 0 : filterName ? dataFiltered.length : totalLength}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />

            <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
            />
          </Box>
        </Card>
      </Container>
      {openMultiAlert && (
        <MultiDeletePopup
          openMultiAlert={openMultiAlert}
          text={'Racers'}
          onMultiCloseAlert={() => setOpenMultiAlert(false)}
          onMultiDelete={handleDeleteRows}
        />
      )}
    </Page>
  );
}

// ----------------------------------------------------------------------

function applySortFilter({
  teamList,
  comparator,
  filterName,
  sportsId,
}: {
  teamList: any[];
  comparator: (a: any, b: any) => number;
  filterName: string;
  sportsId?: number;
}) {
  if (sportsId !== undefined) {
    teamList = teamList?.filter(el => el?.sports_data?.id === sportsId);
  }
  const stabilizedThis = teamList.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  teamList = stabilizedThis.map((el) => el[0]);

  return teamList;
}
