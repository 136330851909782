import { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { AdState, adsAnalyticListAction } from 'src/store/adAnalyticsSlice';
import { adService } from 'src/services/ads';
import { useParams } from 'react-router';
import AdAnalyticListTable from './AdAnalyticListTable';
import LoadingScreen from 'src/components/LoadingScreen';

export default function AdAnalytics() {
    const dispatch = useDispatch();
    const { id = '' } = useParams();
    const [loader, setLoader] = useState(true);
    const [progress, setProgress] = useState(false);

    const adList = useSelector((state: { adAnalytics: AdState }) => state?.adAnalytics);
    useEffect(() => {
        adService
            .adsAnalytics(parseInt(id))
            .then((data: any) => {
                dispatch(adsAnalyticListAction({ adsAn: data?.data }));
                setLoader(false);
            })
            .catch((error) => {
                setLoader(false);
            });
    }, [])
    const handleLoading = (value: boolean, type?: string) => {
        if (type === 'LOADER') {
            setLoader(value);
            return;
        }
        setProgress(value);
    };

    return !loader ? (
        
        <>
            <h1>Ad Analytics</h1>
            <AdAnalyticListTable progress={progress} onLoading={handleLoading} />
        </>
    ) : (
        <LoadingScreen />
    )
}