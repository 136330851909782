import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useMemo, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router';
import { FormProvider, RHFTextField } from 'src/components/hook-form';
import { PATH_MASTER } from 'src/routes/paths';
import { DivisionList, DivisionListBody, DivisionListResponseById } from 'src/@types/master';
import { divisionService } from 'src/services/masters/divisions';
import { createDivisionAction, DivisionState, editDivisionAction } from 'src/store/divisionSlice';
import { useDispatch, useSelector } from 'react-redux';
import LoadingScreen from 'src/components/LoadingScreen';
import Iconify from 'src/components/Iconify';
import CustomContainer from 'src/components/upload/CustomContainer';
import { SportState } from 'src/store/sportsSlice';

export default function DivisionCreate() {
  const [currentdivision, setCurrentDivision] = useState<DivisionList>();
  const dispatch = useDispatch();
  const divisionList = useSelector(
    (state: { division: DivisionState }) => state.division.divisions
  );
  const sports = useSelector((state: { sports: SportState }) => state.sports);

  const { pathname } = useLocation();
  const isEdit = pathname.includes('edit');
  const [loader, setLoader] = useState(true);

  const { id = '' } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (isEdit && !currentdivision) {
      const index = divisionList.findIndex((division) => division.id === parseInt(id));

      if (index !== -1) {
        setCurrentDivision(divisionList[index]);
        setLoader(false);
      } else {
        divisionService
          .getDivisionById(parseInt(id))
          .then((data: DivisionListResponseById) => {
            setCurrentDivision(data?.result);
            setLoader(false);
          })
          .catch((error) => {
            setLoader(false);
            enqueueSnackbar(error.response.data.message, { variant: 'error' });
            navigate(PATH_MASTER.division);
          });
      }
    }

    if (isEdit && currentdivision) {
      reset(defaultValues);
    }
    if (!isEdit) {
      setLoader(false);
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentdivision]);

  const NewDivisionSchema = Yup.object().shape({
    name: Yup.string().trim().required('Name is required'),
    description: Yup.string()
      .trim()
      .required('Description is required')
      .matches(/^[a-zA-Z0-9\s]+$/, 'Only letters and numbers are allowed')
      .max(250, 'Too long descritpion'),
  });

  const defaultValues = useMemo(
    () => ({
      name: currentdivision?.name || '',
      description: currentdivision?.description || '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentdivision]
  );

  const methods = useForm<DivisionList>({
    resolver: yupResolver(NewDivisionSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: DivisionListBody) => {
    if (isEdit) {
      try {
        const result = await divisionService.editDivision(parseInt(id), {
          name: data.name,
          description: data.description,
          sportsId: sports.sportSelected.id
        });

        dispatch(editDivisionAction({ id: parseInt(id), division: result.result }));
        enqueueSnackbar('Update success!');
        navigate(PATH_MASTER.division);
      } catch (error) {
        console.error(error);
        reset();
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
      }
    } else {
      try {
        const result = await divisionService.createDivision({
          name: data.name,
          description: data.description,
          sportsId: sports.sportSelected.id
        });

        if (result.status === 200) {
          reset();
          dispatch(createDivisionAction(result.data.result));
          enqueueSnackbar('Create success!');
          navigate(PATH_MASTER.division);
        }
      } catch (error) {
        console.error(error);
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
      }
    }
  };

  return !loader ? (
    <CustomContainer maxWidth={'xl'}>
      <div style={{ display: 'flex', alignItems: 'baseline' }}>
        <Iconify
          icon={'akar-icons:arrow-left'}
          sx={{ cursor: 'pointer', mr: 2, fontSize: 20 }}
          onClick={() => navigate(PATH_MASTER.division)}
        />
        <Typography variant="h3" component="h1" paragraph>
          {!isEdit ? 'Create Class' : 'Edit Class'}
        </Typography>
      </div>

      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3} marginTop={5}>
          <Grid item xs={12} md={8}>
            <Card sx={{ p: 3 }}>
              <Box
                sx={{
                  display: 'grid',
                  columnGap: 2,
                  rowGap: 3,
                  gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                }}
              >
                <RHFTextField name="name" label="Class Name" />
                <RHFTextField multiline maxRows={10} name="description" label="Description" />
              </Box>

              <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                  {!isEdit ? 'Create Class' : 'Save Changes'}
                </LoadingButton>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </FormProvider>
    </CustomContainer>
  ) : (
    <LoadingScreen />
  );
}
