import { TableRow, TableCell, Typography } from '@mui/material';
import { RacerRow } from './EventRacersTable';

// ----------------------------------------------------------------------

type Props = {
  index: number;
  row: RacerRow;
  selected: boolean;
  onSelectRow: VoidFunction;
};

export default function RacerTableRow({ index, row, selected, onSelectRow }: Props) {
  const { privateer_data: racer } = row;

  return (
    <TableRow hover>
      <TableCell align="center">{index + 1}</TableCell>

      <TableCell align="center">{racer?.name}</TableCell>

      <TableCell align="center">
        <Typography variant="subtitle2" noWrap>
          {racer?.manufacturer_data.name}
        </Typography>
      </TableCell>

      <TableCell align="center">{racer?.description}</TableCell>
    </TableRow>
  );
}
