import {
  Checkbox,
  TableRow,
  TableCell,
  Typography,
  Tooltip,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useState } from 'react';
import { DivisionList } from 'src/@types/master';
import { Ads } from 'src/@types/ads'
import Iconify from 'src/components/Iconify';
import AdView from './AdView';
import moment from 'moment';
// ----------------------------------------------------------------------

type Props = {
  index: number;
  row: Ads;
  selected: boolean;
  onEditRow: VoidFunction;
  onViewAnalytics: VoidFunction;
  onSelectRow: VoidFunction;
  onDeleteRow: Function;
  openAlert: boolean;
  onCloseAlert: VoidFunction;
};

export default function AdTableRow({
  index,
  row,
  selected,
  onEditRow,
  onViewAnalytics,
  onSelectRow,
  onDeleteRow,
  openAlert,
  onCloseAlert,
}: Props) {
  const { name, url, image, startTime, endTime, target } = row;
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(0);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <TableRow hover selected={selected}>
      <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell>

      <TableCell align="center">{index + 1}</TableCell>

      <TableCell align="center">
        <Typography variant="subtitle2" noWrap>
          {name}
        </Typography>
      </TableCell>


      <TableCell align="center">{url}</TableCell>
      <TableCell align="center">
        <Typography variant="subtitle2" noWrap>
          {image}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography variant="subtitle2" noWrap>
          {target}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography variant="subtitle2" noWrap>
          {/* {moment(startTime).format('MMMM Do YYYY, h:mm:ss a')} */}
          {new Date(startTime).toLocaleString().split(',')[0]} <br />{' '}
          {new Date(startTime).toLocaleString().split(',')[1]}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography variant="subtitle2" noWrap>
          {/* {moment(endTime).format('MMMM Do YYYY, h:mm:ss a')} */}
          {new Date(endTime).toLocaleString().split(',')[0]} <br />{' '}
          {new Date(endTime).toLocaleString().split(',')[1]}
        </Typography>
      </TableCell>

      <TableCell align="center" sx={{ display: 'flex', justifyContent: 'center' }}>
        <Tooltip title="Analytics">
          <IconButton
            onClick={() => {
              onViewAnalytics();
            }}
          >
            <Iconify icon={'eva:eye-fill'} sx={{ fontSize: '20px' }} />
          </IconButton>
        </Tooltip>
        {/* {open && <AdView onClose={handleClose} open={open} currentDivision={row} />} */}
        <Tooltip title="Edit">
          <IconButton
            onClick={() => {
              onEditRow();
            }}
          >
            <Iconify icon={'eva:edit-2-fill'} sx={{ fontSize: '20px' }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton
            onClick={() => {
              onDeleteRow('POPUP');
              setId(row.id);
            }}
          >
            <Iconify color="error.main" icon={'eva:trash-2-fill'} sx={{ fontSize: '20px' }} />
          </IconButton>
        </Tooltip>

        {!!id && (
          <Dialog open={openAlert}>
            <DialogTitle>Delete Ad ? </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description" paddingTop={3}>
                Are you sure that you want to delete Ad<strong> "{row.name}" </strong>?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  onCloseAlert();
                  setId(0);
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  onDeleteRow('DELETE');
                  onCloseAlert();
                  setId(0);
                }}
                autoFocus
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </TableCell>
    </TableRow>
  );
}
