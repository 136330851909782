import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Box,
  IconButton,
  Tooltip,
  Button,
  Stack,
} from '@mui/material';
import { makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import CustomConatiner from 'src/components/upload/CustomContainer';
import Iconify from 'src/components/Iconify';
import { useEffect, useState } from 'react';
import { cmsService } from 'src/services/cms';
import LoadingScreen from 'src/components/LoadingScreen';
import { useSnackbar } from 'notistack';
import { Faqs } from 'src/@types/faqs';
import FaqEditPopup from './FaqEditPopup';
import FaqDeletePopup from './FaqDeletePopup';

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  customAccordion: {
    padding: theme.spacing(0, 2.5),
    transition: theme.transitions.create('all'),
    border: `solid 1px ${theme.palette.divider}`,
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    margin: '5px 0px',
  },

  customAccordionSummary: {
    paddingLeft: 0,
    '& .MuiAccordionSummary-content': {
      display: 'flex',
      alignItems: 'center',
    },
  },
}));

// ----------------------------------------------------------------------

export default function FAQ() {
  const classes = useStyles();
  const [faqs, setFaqs] = useState<Faqs[]>([]);
  const [loader, setLoader] = useState(true);
  const [progress, setProgress] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [currentFaq, setCurrentFaq] = useState<Faqs | undefined>();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    cmsService
      .getFaqs({ skip: 0, limit: 30 })
      .then((data) => {
        setFaqs(data.result);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDelete = () => {
    setProgress(true);

    cmsService
      .deleteFaq(currentFaq?.id || 0)
      .then((data) => {
        const updatedFaq = faqs.filter((faq) => faq.id !== currentFaq?.id);
        setFaqs(updatedFaq);
        setProgress(false);
        enqueueSnackbar(data.message);
      })
      .catch((error) => {
        setProgress(false);
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
      });
  };

  const handleEdit = async (data: any) => {
    setProgress(true);

    const body = {
      question: data.question,
      answer: data.answer,
    };

    if (currentFaq) {
      try {
        const data = await cmsService.editFaq(currentFaq.id, body);

        setProgress(false);
        setOpenEdit(false);

        const faqList = await cmsService.getFaqs({ skip: 0, limit: 30 });

        setFaqs(faqList.result);
        enqueueSnackbar(data.message);
      } catch (error) {
        setProgress(false);
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
      }
    } else {
      cmsService
        .addFaq(body)
        .then((data) => {
          setFaqs((prevState) => [data.result, ...prevState]);
          setProgress(false);
          setOpenEdit(false);
          enqueueSnackbar(data.message);
        })
        .catch((error) => {
          setProgress(false);
          enqueueSnackbar(error.response.data.message, { variant: 'error' });
        });
    }
  };

  return !loader ? (
    <Page title="FAQ">
      {progress && <LoadingScreen isDashboard={progress} />}
      <CustomConatiner maxWidth={'xl'}>
        <Typography variant="h4" sx={{ mb: 2 }}>
          Frequently Asked Questions
        </Typography>
        <Stack
          spacing={2}
          direction={{ xs: 'column', sm: 'row' }}
          sx={{ justifyContent: 'flex-end' }}
        >
          <Button
            variant="contained"
            startIcon={<Iconify icon={'eva:plus-fill'} />}
            sx={{ py: 1.5 }}
            onClick={() => {
              setOpenEdit(true);
            }}
          >
            Add FAQ
          </Button>
        </Stack>
      </CustomConatiner>
      <Box sx={{ px: 3, mt: 17 }}>
        {faqs.map((faq, index) => (
          <Accordion
            className={classes.customAccordion}
            key={index}
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <AccordionSummary
              expandIcon={<Iconify icon={'dashicons:arrow-down-alt2'} />}
              className={classes.customAccordionSummary}
            >
              <Typography variant="subtitle1" sx={{ pr: 2 }}>
                {index + 1}. {faq.question}
              </Typography>
              <Tooltip title="Edit">
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenEdit(true);
                    setCurrentFaq(faq);
                  }}
                >
                  <Iconify icon={'clarity:edit-solid'} sx={{ fontSize: '18px' }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenDelete(true);
                    setCurrentFaq(faq);
                  }}
                >
                  <Iconify color="error.main" icon={'eva:trash-2-fill'} sx={{ fontSize: '18px' }} />
                </IconButton>
              </Tooltip>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{faq.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}

        {openDelete && currentFaq && (
          <FaqDeletePopup
            faq={currentFaq}
            openDelete={openDelete}
            onDelete={onDelete}
            onCloseAlert={() => {
              setOpenDelete(false);
              setCurrentFaq(undefined);
            }}
          />
        )}

        {openEdit && (
          <FaqEditPopup
            faq={currentFaq}
            openEdit={openEdit}
            onCloseAlert={() => {
              setOpenEdit(false);
              setCurrentFaq(undefined);
            }}
            onEdit={handleEdit}
            isLoading={progress}
          />
        )}
      </Box>
    </Page>
  ) : (
    <LoadingScreen />
  );
}
