import { useState } from 'react';
import {
  Box,
  Tab,
  Tabs,
  Card,
  Table,
  Switch,
  TableBody,
  Container,
  TableContainer,
  TablePagination,
  FormControlLabel,
  LinearProgress,
  Divider,
} from '@mui/material';
import Page from 'src/components/Page';
import Scrollbar from 'src/components/Scrollbar';
import { TableHeadCustom, TableNoData } from 'src/components/table';
import useTable, { getComparator } from 'src/hooks/useTable';
import UserTableToolbar from 'src/views/Users/UserTableToolbar';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { CommonState, setSearch } from 'src/store/commonSlice';
import RequestsTableRow from './RequestsTableRow';
import {
  changeTabAction,
  editRequestAction,
  emptyRequestListAction,
  requestListAction,
  RequestState,
} from 'src/store/requestSlice';
import { WithdrawRequest } from 'src/@types/requests';
import { requestService } from 'src/services/requests';
import { requestStripeBalance, StripeState } from 'src/store/stripeSlice';


// ----------------------------------------------------------------------

const STATUS_OPTIONS = ['all', 'Pending', 'Approved', 'InProcess', 'Declined', 'Failed'];

const TABLE_HEAD = [
  { id: 'id', label: 'Sr.No', align: 'center' },
  { id: 'username', label: 'Username', align: 'center' },
  { id: 'email', label: 'Email', align: 'center' },
  { id: 'walletBalance', label: 'Available Chips', align: 'center' },
  { id: 'chips', label: 'Requested Chips', align: 'center' },
  { id: 'beforeReqBalance', label: 'Chips Before', align: 'center' },
  { id: 'createdAt', label: 'Requested Date', align: 'center' },
  { id: 'status', label: 'Status', align: 'center' },
];

// ----------------------------------------------------------------------

type RacerListProps = {
  progress: boolean;
  onLoading: Function;
};

export default function RequestsListTable({ progress, onLoading }: RacerListProps) {
  const { dense, order, orderBy, selected, onChangeDense } = useTable();

  const dispatch = useDispatch();
  const requestsList = useSelector((state: { requests: RequestState }) => state.requests.requests);
  const totalLength = useSelector(
    (state: { requests: RequestState }) => state.requests.totalRequests
  );
  const storePage = useSelector((state: { common: CommonState }) => state.common.currentPage);
  const storeSearch = useSelector((state: { common: CommonState }) => state.common.search.request);
  const storeStatus = useSelector(
    (state: { requests: RequestState }) => state.requests.tabSelected
  );
  const stripeAvailable = useSelector((state: { stripe: StripeState }) => state.stripe?.available);
  // const available = stripeList?.available


  const [filterName, setFilterName] = useState(storeSearch || '');
  const [filterStatus, setFilterStatus] = useState(storeStatus || 'all');
  const [page, setPage] = useState(storePage || 0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { enqueueSnackbar } = useSnackbar();

  const onChangeFilterStatus = (event: React.SyntheticEvent<Element, Event>, newValue: string) => {
    setFilterName('');
    setFilterStatus(newValue);
    onLoading(true);
    dispatch(emptyRequestListAction());
    dispatch(changeTabAction(newValue));
    if (newValue !== 'all') {
      requestService
        .getWithdrawalRequests({
          skip: 0,
          limit: rowsPerPage,
          filter: {
            status: newValue,
          },
        })
        .then((data) => {
          dispatch(
            requestListAction({
              requests: data?.result,
              totalRequests: data?.count,
              pendingRequests: data?.pendingCount,
            })
          );
          onLoading(false);
        })
        .catch((error) => {
          onLoading(false);
          enqueueSnackbar(error.response.data.message, { variant: 'error' });
        });
    } else {
      requestService
        .getWithdrawalRequests({
          skip: 0,
          limit: rowsPerPage,
        })
        .then((data) => {
          dispatch(
            requestListAction({
              requests: data?.result,
              totalRequests: data?.count,
              pendingRequests: data?.pendingCount,
            })
          );
          onLoading(false);
        })
        .catch((error) => {
          onLoading(false);
          enqueueSnackbar(error.response.data.message, { variant: 'error' });
        });
    }
    setPage(0);
  };

  const onChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    const rows = parseInt(event.target.value);
    const requestArrLength = requestsList.length;
    const condition = page === 0 ? requestArrLength < rows : requestArrLength < page * rows;

    const skipCondition = requestArrLength <= totalLength ? requestArrLength : 0;

    if (condition && requestArrLength !== totalLength) {
      onLoading(true);

      const body = { skip: skipCondition, limit: rows - requestArrLength, filter: {} };

      if (filterStatus !== 'all') {
        body.filter = { status: filterStatus };
      }
      requestService
        .getWithdrawalRequests(body)
        .then((data) => {
          dispatch(
            requestListAction({
              requests: data?.result,
              totalRequests: data?.count,
              pendingRequests: data?.pendingCount,
            })
          );
          onLoading(false);
        })
        .catch((error) => {
          onLoading(false);
          enqueueSnackbar(error.response.data.message, { variant: 'error' });
        });
    }

    setRowsPerPage(rows);
    setPage(0);
  };

  const onChangePage = (event: unknown, newPage: number) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    if (page < newPage) {
      if (requestsList.length > newPage * rowsPerPage) {
        setPage(newPage);
        return;
      }
      onLoading(true);

      const body = { skip: newPage * rowsPerPage, limit: rowsPerPage, filter: {} };

      if (filterStatus !== 'all') {
        body.filter = { status: filterStatus };
      }

      requestService
        .getWithdrawalRequests(body)
        .then((data) => {
          dispatch(
            requestListAction({
              requests: data?.result,
              totalRequests: data?.count,
              pendingRequests: data?.pendingCount,
            })
          );
          onLoading(false);
        })
        .catch((error) => {
          onLoading(false);
          enqueueSnackbar(error.response.data.message, { variant: 'error' });
        });
    }
    setPage(newPage);
  };

  const handleFilterName = (filterName: string) => {
    setFilterName(filterName);

    if (filterName?.trim().length >= 3) {
      onLoading(true);

      const body = {
        search: filterName.trim(),
        filter: {},
      };

      if (filterStatus !== 'all') {
        body.filter = {
          conductedBy: filterStatus,
        };
      }

      requestService
        .searchWithdrawalRequests(body)
        .then((data) => {
          onLoading(false);
          dispatch(emptyRequestListAction());
          dispatch(
            requestListAction({
              requests: data?.result,
              totalRequests: data?.count,
              pendingRequests: data?.pendingCount,
            })
          );
        })
        .catch((error) => {
          onLoading(false);
          enqueueSnackbar(error.response.data.message, { variant: 'error' });
        });
    }

    if (!filterName) {
      onLoading(true);
      requestService
        .getWithdrawalRequests({
          skip: 0,
          limit: rowsPerPage,
        })
        .then((data) => {
          dispatch(emptyRequestListAction());
          dispatch(
            requestListAction({
              requests: data?.result,
              totalRequests: data?.count,
              pendingRequests: data?.pendingCount,
            })
          );
          onLoading(false);
        })
        .catch((error) => {
          onLoading(false);
          enqueueSnackbar(error.response.data.message, { variant: 'error' });
        });
    }
    setPage(0);
  };

  const handleBlur = (filterName: string) => {
    dispatch(setSearch({ search: filterName, type: 'request' }));
  };

  const handleApprove = (row: any, id: number, status: string) => {
    onLoading(true);
    if (row.chips < stripeAvailable[0]?.amount || status === 'Decline') {
      requestService
        .requestApproval(id, { status: status })
        .then((data) => {
          dispatch(editRequestAction(data.result));
          onLoading(false);
          enqueueSnackbar(data.message);
        })
        .catch((error) => {
          onLoading(false);
          enqueueSnackbar(error.response.data.message, { variant: 'error' });
        });
    } else {
      onLoading(false);
      enqueueSnackbar('Requested Chips amount is greater then available stripe amount!', { variant: 'error' });
    }
  };

  const dataFiltered = applySortFilter({
    requestsList: requestsList,
    comparator: getComparator(order, orderBy),
    filterName,
  });

  const isNotFound = !totalLength;

  return (
    <Page title="Requests: List">
      <Container maxWidth={'xl'} sx={{ mt: 17 }}>
        <Card>
          {progress && <LinearProgress sx={{ mx: 1, mt: 0.1 }} />}

          <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={filterStatus}
            onChange={onChangeFilterStatus}
            sx={{ px: 2, bgcolor: 'background.neutral' }}
          >
            {STATUS_OPTIONS.map((tab) => (
              <Tab disableRipple key={tab} label={tab} value={tab} />
            ))}
          </Tabs>

          <Divider />

          <UserTableToolbar
            isSearch={false}
            filterName={filterName}
            onFilterName={handleFilterName}
            onBlurField={handleBlur}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom order={order} orderBy={orderBy} headLabel={TABLE_HEAD} />

                <TableBody>
                  {dataFiltered
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <RequestsTableRow
                        key={row.id}
                        index={page === 0 ? index : rowsPerPage * page + index}
                        row={row}
                        selected={selected.includes(row.id)}
                        onApprove={(status: string) => handleApprove(row, row.id, status)}
                      />
                    ))}

                  <TableNoData isNotFound={isNotFound} />
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[5, 15, 25]}
              component="div"
              count={isNotFound ? 0 : filterName ? dataFiltered.length : totalLength}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />

            <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
            />
          </Box>
        </Card>
      </Container>
    </Page>
  );
}

// ----------------------------------------------------------------------

function applySortFilter({
  requestsList,
  comparator,
  filterName,
}: {
  requestsList: WithdrawRequest[];
  comparator: (a: any, b: any) => number;
  filterName: string;
}) {
  const stabilizedThis = requestsList.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  requestsList = stabilizedThis.map((el) => el[0]);

  return requestsList;
}
