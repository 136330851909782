import { Typography, Stack, Button, Badge } from '@mui/material';
import Iconify from '../../components/Iconify';

import Page from '../../components/Page';
import { useEffect, useState } from 'react';
import LoadingScreen from 'src/components/LoadingScreen';
import CustomContainer from 'src/components/upload/CustomContainer';
import RequestsListTable from './RequestsListTable';
import { emptyRequestListAction, requestListAction, RequestState } from 'src/store/requestSlice';
import { requestStripeBalance, StripeState } from 'src/store/stripeSlice';
import { useDispatch, useSelector } from 'react-redux';
import { requestService } from 'src/services/requests';
import { useSnackbar } from 'notistack';
import { CommonState, setSearch } from 'src/store/commonSlice';
// ----------------------------------------------------------------------

export default function Requests() {
  const dispatch = useDispatch();
  const requestsList = useSelector((state: { requests: RequestState }) => state.requests);
  const storeSearch = useSelector((state: { common: CommonState }) => state.common.search.request);
  const stripeList = useSelector((state: { stripe: StripeState }) => state.stripe);
  const [progress, setProgress] = useState(false);
  const [loader, setLoader] = useState(true);
  const [stripeAvailableAmount, setStripeAvailableAmount] = useState()
  const [stripePending, setStripePending] = useState()
  const [stripeConnectReserved, setStripeConnectReserved] = useState()


  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!stripeList?.available?.length) {
      requestService.getStripeBalance({})
        .then((result) => {
          setStripeAvailableAmount(result?.balance?.available[0]?.amount)
          setStripePending(result?.balance?.pending[0]?.amount)
          setStripeConnectReserved(result?.balance?.connect_reserved[0]?.amount)
          dispatch(requestStripeBalance(result?.balance))
        })
        .catch((error) => {
          enqueueSnackbar(error.response.data.message, { variant: 'error' });
          setLoader(false);
        });
    } else {
      setLoader(false);
    }
  }, [])


  useEffect(() => {
    if (!requestsList.requests.length || storeSearch.length) {
      if (storeSearch.length) {
        dispatch(setSearch({ search: '', type: 'request' }));
        dispatch(emptyRequestListAction());
      }
      requestService
        .getWithdrawalRequests({ skip: 0, limit: 5 })
        .then((data) => {
          dispatch(
            requestListAction({
              requests: data?.result,
              totalRequests: data?.count,
              pendingRequests: data?.pendingCount,
            })
          );
          setLoader(false);
        })
        .catch((error) => {
          enqueueSnackbar(error.response.data.message, { variant: 'error' });
          setLoader(false);
        });
    }
    else {
      setLoader(false);
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLoading = (value: boolean, type: string) => {
    if (type === 'LOADER') {
      setLoader(value);
      return;
    }
    setProgress(value);
  };

  return !loader ? (
    <Page title="Requests">
      <CustomContainer maxWidth={'xl'}>
        <Typography variant="h3" component="h1" paragraph>
          Withdrawal Requests
        </Typography>
        <Stack
          spacing={2}
          direction={{ xs: 'column', sm: 'row' }}
          sx={{ justifyContent: 'space-between' }}
        >
          <Button
            variant="outlined"
            size="large"
          // startIcon={<Iconify icon={'heroicons-solid:users'} />}
          >
            Stripe Available Amount
            <Badge
              badgeContent={(stripeAvailableAmount && stripeAvailableAmount / 100 )}
              max={999999999999999}
              color="primary"
              sx={{
                ml: 1.5,
                '& .MuiBadge-anchorOriginTopRight': { position: 'relative', transform: 'none' },
              }}
            />
          </Button>
          <Button
            variant="outlined"
            size="large"
          // startIcon={<Iconify icon={'heroicons-solid:users'} />}
          >
            Stripe Pending
            <Badge
              badgeContent={stripePending && stripePending/100}
              max={999999999999999}
              color="primary"
              sx={{
                ml: 1.5,
                '& .MuiBadge-anchorOriginTopRight': { position: 'relative', transform: 'none' },
              }}
            />
          </Button>
          <Button
            variant="outlined"
            size="large"
          // startIcon={<Iconify icon={'heroicons-solid:users'} />}
          >
            Stripe Connect Reserve
            <Badge
              badgeContent={stripeConnectReserved && stripeConnectReserved/100}
              max={999999999999999}
              color="primary"
              sx={{
                ml: 1.5,
                '& .MuiBadge-anchorOriginTopRight': { position: 'relative', transform: 'none' },
              }}
            />
          </Button>
        </Stack>

      </CustomContainer>

      <RequestsListTable progress={progress} onLoading={handleLoading} />
    </Page>
  ) : (
    <LoadingScreen />
  );
}
