import axios from 'axios';
import { baseEndpoints } from './config';

const addRacers = async (eventId: number, body: any) => {
  console.log("ADDING RACERS")
  const { data } = await axios.post(`${baseEndpoints.eventPrivateers}/add-racers/${eventId}`, body);

  return data;
};

const getRacers = async (eventId: number, divisionId: number) => {

  console.log("DIVISON EXISTS!!!")
  const { data } = await axios.get(
    `${baseEndpoints.eventPrivateers}/list-racers/${eventId}/${divisionId}`
  );
  return data;

};

const getRacersBySportId = async (eventId: number, sportId?: number,) => {
  console.log("DIVISON DEOSNT EXSITS!!!!!")
  const { data } = await axios.get(
    `${baseEndpoints.eventPrivateers}/list-racers/${eventId}/sport/${sportId}`
  );
  console.log(data)
  return data;

};

const updateRacers = async (eventId: number, body: any) => {
  const { data } = await axios.post(`${baseEndpoints.eventPrivateers}/update-racers/${eventId}`, body);

  return data;
};

export const eventPrivateerService = {
  addRacers,
  getRacers,
  updateRacers,
  getRacersBySportId
};
