import axios from 'axios';
import { baseEndpoints } from './config';

const getWithdrawalRequests = async (body: any) => {
  const { data } = await axios.post(`${baseEndpoints.chipBank}/list/withdraw-requests`, body);

  return data;
};

const requestApproval = async (id: number, body: { status: string }) => {
  const { data } = await axios.post(`${baseEndpoints.chipBank}/request-approval/${id}`, body);

  return data;
};

const searchWithdrawalRequests = async (body: { search: string, filter : {} }) => {
  const { data } = await axios.post(`${baseEndpoints.chipBank}/search/withdraw-requests`, body);

  return data;
};
const getStripeBalance = async (body: any) => {
  const { data } = await axios.get(`${baseEndpoints.chipBank}/getstripebalance`, body);

  return data;
};

export const requestService = {
  getWithdrawalRequests,
  requestApproval,
  searchWithdrawalRequests,
  getStripeBalance
};
