import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Card, Chip, FormControlLabel, Grid, Stack, Switch, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useMemo, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router';
import { FormProvider, RHFSelect, RHFTextField } from 'src/components/hook-form';
import { PATH_MASTER } from 'src/routes/paths';
import { ManufacturerList, RacerList, RacerListResponseById } from 'src/@types/master';
import { racerService } from 'src/services/masters/racers';
import { createRacerAction, editRacerAction, RacerState } from 'src/store/racerSlice';
import { useDispatch, useSelector } from 'react-redux';
import { manufacturerService } from 'src/services/masters/manufacturers';
import LoadingScreen from 'src/components/LoadingScreen';
import Iconify from 'src/components/Iconify';
import CustomContainer from 'src/components/upload/CustomContainer';
import Label from 'src/components/Label';

import { sportsService } from 'src/services/sports';
import { setSelectedSportAction, setSportsAction } from 'src/store/sportsSlice';
import { SportsGetAllResponse } from 'src/@types/sports';
import { SportState } from 'src/store/sportsSlice';

type ManufacturerSelect = [
  {
    id: number;
    name: string;
  }
];

export default function RacerCreate() {
  const [currentRacer, setCurrentRacer] = useState<RacerList>();
  const [manufacturerList, setManufacturerList] = useState<ManufacturerSelect>();
  const [loader, setLoader] = useState(true);
  const dispatch = useDispatch();
  const racerList = useSelector((state: { racer: RacerState }) => state.racer.racers);

  const sports = useSelector((state: { sports: SportState }) => state.sports);
  const { pathname } = useLocation();
  const isEdit = pathname.includes('edit');

  const { id = '' } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!manufacturerList) {
      manufacturerService.getManufacturerList({ sportsId: sports.sportSelected.id }).then((data) => {
        console.log("GETTING IT -", data)
        const list = data.result;
        const arr: ManufacturerSelect = list.map((manufacturer: ManufacturerList) => ({
          id: manufacturer.id,
          name: manufacturer.name,
        }));
        setManufacturerList(arr);
        setLoader(false);
      });
    }

    if (isEdit && !currentRacer) {
      const index = racerList.findIndex((racer) => racer.id === parseInt(id));

      if (index !== -1) {
        setCurrentRacer(racerList[index]);
      } else {
        setLoader(true);
        racerService
          .getRacerById(parseInt(id))
          .then((data: RacerListResponseById) => {
            setCurrentRacer(data?.result);
            setLoader(false);
          })
          .catch((error) => {
            setLoader(false);
            enqueueSnackbar(error.response.data.message, { variant: 'error' });
            navigate(PATH_MASTER.racer);
          });
      }
    }

    if (isEdit && currentRacer) {
      reset(defaultValues);
    }
    if (!isEdit) {
      setLoader(false);
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentRacer, sports.sportSelected]);

  const NewRacerSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .required('Name is required')
      .matches(/^[aA-zZ\s]+$/, 'Only letters are allowed'),
    description: Yup.string()
      .trim()
      .required('Description is required')
      .max(250, 'Too long descritpion'),
    //manufacturerId: Yup.number().min(1, 'Manufacturer is required'),
  });

  const defaultValues = useMemo(
    () => ({
      name: currentRacer?.name || '',
      description: currentRacer?.description || '',
      manufacturerId: currentRacer?.manufacturerId || 0,
      isActive: currentRacer?.isActive && true,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentRacer]
  );

  const methods = useForm<RacerList>({
    resolver: yupResolver(NewRacerSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const onSubmit = async (data: RacerList) => {
    console.log("ADDING RACER")
    const man = manufacturerList?.find((man) => man.id === data.manufacturerId);
    console.log(man)

    console.log("ADDING RACER")
    const body = {
      name: data.name,
      description: data.description,
      manufacturerId: data.manufacturerId,
      sportsId: sports.sportSelected.id,
      isActive: data.isActive,
    };

    if (isEdit) {
      try {
        var result = await racerService.editRacer(parseInt(id), body);

        const editedRacer = {
          ...result.result,
          manufacturer_data: {
            name: man?.name,
          },
        };

        dispatch(editRacerAction(editedRacer));
        enqueueSnackbar('Update success!');
        navigate(PATH_MASTER.racer);
      } catch (error) {
        console.error(error);
        reset();
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
      }
    } else {
      try {
        console.log("ADDING RACER")
        const result = await racerService.createRacer(body);

        const newRacer = {
          ...result.data.result,
          manufacturer_data: {
            name: man?.name,
          },
        };

        if (result.status === 200) {
          reset();
          dispatch(createRacerAction(newRacer));
          enqueueSnackbar('Create success!');
          navigate(PATH_MASTER.racer);
        }
      } catch (error) {
        console.error(error);
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
      }
    }
  };

  // useEffect(() => {z
  //   //gets all sports for drop down
  //   getAllSports()
  // }, []);

  // const getAllSports = () => {

  //   sportsService
  //     .getAllSports().then((data: SportsGetAllResponse) => {
  //       dispatch(setSportsAction(data.result))
  //     })
  //     .catch((error) => {
  //       enqueueSnackbar(error.response.data.message, { variant: 'error' });
  //       setLoader(false);
  //     });
  // }
  //console.log(sports)
  console.log('manufacturerList - ', manufacturerList)
  return !loader ? (
    <CustomContainer maxWidth={'xl'}>
      <div style={{ display: 'flex', alignItems: 'baseline' }}>
        <Iconify
          icon={'akar-icons:arrow-left'}
          sx={{ cursor: 'pointer', mr: 2, fontSize: 20 }}
          onClick={() => navigate(PATH_MASTER.racer)}
        />
        <Typography variant="h3" component="h1" paragraph>
          {!isEdit ? 'Create Racer' : `Edit Racer - ${currentRacer?.name}`}
        </Typography>
      </div>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3} marginTop={5}>
          <Grid item xs={12} md={8}>
            <Card sx={{ p: 3 }}>

              <Chip label={`SPORT: ${sports?.sportSelected?.name}`} />
              <Label
                color={values.isActive ? 'success' : 'error'}
                sx={{ textTransform: 'uppercase', position: 'absolute', top: 24, right: 24 }}
              >
                {values.isActive ? 'Active' : 'Barred'}
              </Label>
              <Box
                sx={{
                  display: 'grid',
                  columnGap: 2,
                  rowGap: 2,
                  gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                  pt: 5,
                }}
              >
                <RHFTextField name="name" label="Racer Name" maxRows={3} />
                <RHFTextField multiline name="description" label="Description" maxRows={5} />
                {
                  sports.sportSelected.id != 5 &&
                  <RHFSelect name="manufacturerId" label="Manufacturer">
                    <option key={0} value={0} disabled>
                      Select Manufacturer
                    </option>
                    {manufacturerList?.map((man) => (
                      <option key={man.id} value={man.id}>
                        {man.name}
                      </option>
                    ))}
                  </RHFSelect>
                }

                <FormControlLabel
                  labelPlacement="start"
                  control={
                    <Controller
                      name="isActive"
                      control={control}
                      render={({ field }) => (
                        <Switch
                          {...field}
                          checked={field.value}
                          onChange={(event) => field.onChange(event.target.checked)}
                        />
                      )}
                    />
                  }
                  label={
                    <>
                      <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                        Status
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        Activate/Deactivate account
                      </Typography>
                    </>
                  }
                  sx={{ mx: 0, mb: 3, width: 1, justifyContent: 'space-between' }}
                />
              </Box>

              <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                  {!isEdit ? 'Create Racer' : 'Save Changes'}
                </LoadingButton>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </FormProvider>
    </CustomContainer>
  ) : (
    <LoadingScreen />
  );
}
