import { TableRow, TableCell, Tooltip, IconButton, Table, TableBody, TableHead } from '@mui/material';
import { capitalCase } from 'change-case';
import { Wagers } from 'src/@types/events';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import Iconify from 'src/components/Iconify';
import { eventService } from 'src/services/events';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { useState } from 'react';
import { FormProvider, RHFTextField } from 'src/components/hook-form';
import Multiplier from '../Masters/Multipliers/Multiplier';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiDialog-paperScrollPaper': { boxShadow: '0px 40px 80px -8px rgb(0 0 0 / 24%)' },
}));
export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}
const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Iconify icon={'eva:close-fill'} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};


// ----------------------------------------------------------------------

type Props = {
  index: number;
  row: Wagers;
  selected: boolean;
  onVoid: Function;
};

export default function LinkedWagerTableRow({ index, row, selected, onVoid }: Props) {
  const { type, wagerType_data, createdBy_data, chipsBet, createdAt, users, multiplier_data, multiplierId } = row;
  const [open, setOpen] = useState(false)
  function onClose() {
    setOpen(false)

  }


  // export default function DivisionView(props: DivisionViewProps) {
  const defaultValues = useMemo(
    () => ({
      chipsBet: chipsBet * users?.length * Number(multiplierId) || 0,
      createdAt: new Date(createdAt).toLocaleString(),
      createdBy_data: createdBy_data?.username || {},
      users: users || []
      // description: props.currentDivision?.description || '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [row]
  );
  const methods = useForm<Wagers>({
    defaultValues

  });

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell align="center">{index + 1}</TableCell>

        <TableCell align="center">{capitalCase(type)}</TableCell>

        <TableCell align="center">
          {wagerType_data.name === '1v1' && 'Rider vs Rider'}
          {wagerType_data.name === 'placement' && 'Finish Line'}
          {wagerType_data.name === 'holeshot' && 'Holeshot'}
          {wagerType_data.name === 'spread' && 'Spread'}

        </TableCell>

        <TableCell align="center">{createdBy_data.username}</TableCell>

        <TableCell align="center">{chipsBet * users?.length * Number(multiplierId)}</TableCell>
        <TableCell align="center">
          <Tooltip title="View">
            <IconButton
              onClick={() => {
                setOpen(true);
              }}
            >
              <Iconify icon={'eva:eye-fill'} sx={{ fontSize: '20px' }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Void">
            <IconButton
              onClick={() => {
                onVoid(row?.id)
                // setId(row.id);
              }}
            >
              <Iconify color="error.main" icon={'eva:minus-circle-fill'} sx={{ fontSize: '20px' }} />
            </IconButton>
          </Tooltip>
        </TableCell>

      </TableRow>
      <BootstrapDialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
          Wager Details
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <FormProvider methods={methods}>
            <RHFTextField
              name="chipsBet"
              label=" Chips Amount"
              sx={{ my: 2 }}
              InputProps={{
                readOnly: true,
              }}
            />

            <RHFTextField
              multiline
              name="createdBy_data"
              label="Created By"
              sx={{ my: 2 }}
              InputProps={{
                readOnly: true,
              }}
            />
            <Table style={{ border: '1px solid lightgrey', borderRadius: '8px', borderCollapse: 'unset' }}>
              <TableHead style={{ borderBottom: '1px solid lightgrey' }}>
                <td style={{ textAlign: 'left', padding: '11px', color: 'grey', fontSize: '13px' }}>Accepted by</td>
                <td style={{ textAlign: 'left', padding: '11px', color: 'grey', fontSize: '13px' }}>Amount bet</td>
              </TableHead>
              <TableBody>
              {users?.length > 0 ?
              <>
                {users?.map((val, idx) =>
                  <tr key = {idx}>
                    <td style={{ textAlign: 'left', padding: '11px' }}>
                      {val?.userName}
                    </td>
                    <td style={{ textAlign: 'left', padding: '11px' }}>
                      {val?.chips}
                    </td>
                  </tr>
                )}
                </>
                :
                <><tr><td>No user accepted this wager</td></tr></>
              }
              </TableBody>
            </Table>
            <RHFTextField
              multiline
              name="createdAt"
              label="Placed On"
              sx={{ my: 2 }}
              InputProps={{
                readOnly: true,
              }}
            />
          </FormProvider>
        </DialogContent>
      </BootstrapDialog>

    </>
  );
}
