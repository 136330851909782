import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import { authReducer } from './authSlice';
import { commonReducer } from './commonSlice';
import { divisionReducer } from './divisionSlice';
import { eventReducer } from './eventSlice';
import { manufacturerReducer } from './manufacturerSlice';
import { multiplierReducer } from './multiplierSlice';
import { racerReducer } from './racerSlice';
import { userReducer } from './userSlice';
import { requestReducer } from './requestSlice';
import { adReducer } from './adSlice';
import { stripeReducer } from './stripeSlice';
import { adAnalyticReducer } from './adAnalyticsSlice';
import { sportsReducer } from './sportsSlice';
import { teamReducer } from './teamSlice';

// Combine all reducers
const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  division: divisionReducer,
  manufacturer: manufacturerReducer,
  racer: racerReducer,
  team: teamReducer,
  multiplier: multiplierReducer,
  events: eventReducer,
  common: commonReducer,
  requests: requestReducer,
  ads: adReducer,
  stripe: stripeReducer,
  adAnalytics: adAnalyticReducer,
  sports: sportsReducer
});

// Configuration for redux-persist
const persistConfig = {
  key: 'root',
  storage,
  // Optionally, you can add a whitelist or blacklist to persist specific reducers
};

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure store with the persisted reducer
export const store = configureStore({
  reducer: persistedReducer,
});

// Create a persistor
export const persistor = persistStore(store);

// Export RootState type
export type RootState = ReturnType<typeof store.getState>;
