import axios from 'axios';
import { baseEndpoints } from './config';

const uploadResult = async (division: string, id: string, url: string, file: any) => {
  var bodyFormData = new FormData();

  bodyFormData.append('result', file);
  bodyFormData.append('division', division);
  bodyFormData.append('eventId', id);
  bodyFormData.append('url', url);
  console.log('')
  console.log('INSIDE UPLOAD RESULT SERVICE!!!')
  console.log(bodyFormData)
  const { data } = await axios.post(`${baseEndpoints.results}/upload-result`, bodyFormData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return data;
};

const computeResult = async (id: number) => {
  const { data } = await axios.get(`${baseEndpoints.results}/compute-results/${id}`);

  return data;
};

export const resultService = {
  uploadResult,
  computeResult,
};
