import axios from 'axios';
import { RacerListBody } from 'src/@types/master';
import { baseEndpoints } from '../config';

const createRacer = async (body: RacerListBody) => {
  const { data, status } = await axios.post(`${baseEndpoints.racers}/add`, body);

  return { data: data, status: status };
};

const getRacerList = async (body: any) => {
  console.log('sending shit rn -', body)
  const { data } = await axios.post(`${baseEndpoints.racers}/list`, body);

  return data;
};

const getRacerById = async (id: number) => {
  const { data } = await axios.get(`${baseEndpoints.racers}/get/${id}`);

  return data;
};

const deleteRacer = async (id: number) => {
  const { data } = await axios.delete(`${baseEndpoints.racers}/delete/${id}`);

  return data;
};

const editRacer = async (id: number, body: RacerListBody) => {
  const { data } = await axios.post(`${baseEndpoints.racers}/edit/${id}`, body);

  return data;
};

const deleteMultipleRacer = async (body: { ids: number[] }) => {
  const { data } = await axios.post(`${baseEndpoints.racers}/multidelete`, body);

  return data;
};

const searchRacer = async (body: { search: string }) => {
  const { data } = await axios.post(`${baseEndpoints.racers}/search`, body);

  return data;
};

export const racerService = {
  createRacer,
  getRacerList,
  getRacerById,
  deleteRacer,
  editRacer,
  deleteMultipleRacer,
  searchRacer,
};
