function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_USERS = '/users';
const ROOTS_MASTER = '/master';
const ROOTS_EVENTS = '/events';
const ROOTS_REQUESTS = '/withdrawal-requests';
const ROOTS_CMS = '/cms';
const ROOTS_ADS = '/ads';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
};

export const PATH_PAGE = {
  page404: '/404',
  page500: '/500',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
};

export const PATH_USER = {
  root: ROOTS_USERS,
  edit: (id: number) => path(ROOTS_USERS, `/${id}/edit`),
  view: (id: number) => path(ROOTS_USERS, `/${id}/view`),
};

export const PATH_MASTER = {
  root: ROOTS_MASTER,

  division: path(ROOTS_MASTER, '/classes'),
  divisionNew: path(ROOTS_MASTER, '/classes/new'),
  divisionEdit: (id: number) => path(ROOTS_MASTER, `/classes/${id}/edit`),

  manufacturer: path(ROOTS_MASTER, '/manufacturers'),
  manufacturerNew: path(ROOTS_MASTER, '/manufacturers/new'),
  manufacturerEdit: (id: number) => path(ROOTS_MASTER, `/manufacturers/${id}/edit`),

  racer: path(ROOTS_MASTER, '/racers'),
  racerNew: path(ROOTS_MASTER, '/racers/new'),
  racerEdit: (id: number) => path(ROOTS_MASTER, `/racers/${id}/edit`),


  team: path(ROOTS_MASTER, '/teams'),
  teamNew: path(ROOTS_MASTER, '/teams/new'),
  teamEdit: (id: number) => path(ROOTS_MASTER, `/teams/${id}/edit`),

  multiplier: path(ROOTS_MASTER, '/multipliers'),
  multiplierNew: path(ROOTS_MASTER, '/multipliers/new'),
  multiplierEdit: (id: number) => path(ROOTS_MASTER, `/multipliers/${id}/edit`),
};

export const PATH_EVENTS = {
  root: ROOTS_EVENTS,
  edit: (id: number) => path(ROOTS_EVENTS, `/${id}/edit`),
  view: (id: number) => path(ROOTS_EVENTS, `/${id}/view`),
  new: path(ROOTS_EVENTS, `/new`),
};

export const PATH_ADS = {
  root: ROOTS_ADS,
  edit: (id: number) => path(ROOTS_ADS, `/${id}/edit`),
  view: (id: number) => path(ROOTS_ADS, `/${id}/view`),
  new: path(ROOTS_ADS, `/new`),
  analytics: (id: number) => path(ROOTS_ADS, `/${id}/ads-analytics`)
};

export const PATH_REQUESTS = {
  root: ROOTS_REQUESTS,
};

export const PATH_CMS = {
  root: ROOTS_CMS,
  privacyPolicy: path(ROOTS_CMS, '/privacy-policy'),
  termsConditions: path(ROOTS_CMS, '/terms-and-conditions'),
  faqs: path(ROOTS_CMS, '/faqs'),
};
