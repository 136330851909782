import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdAnalyticsResponse, AdAnalytics } from 'src/@types/ads';

export interface AdState {
  adsAn: AdAnalytics[];
}

const initialState: AdState = {
  adsAn: [],
};

export const AdAnalyticSlice = createSlice({
  name: 'adsAnalytics',
  initialState,
  reducers: {
    adsAnalyticListAction: (state, action: PayloadAction<AdState>) => {
        state.adsAn = action.payload.adsAn;
    },
    
    
  },
});

export const {
    adsAnalyticListAction
} = AdAnalyticSlice.actions;

export const adAnalyticReducer = AdAnalyticSlice.reducer;
