import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface AuthState {
  email: string;
  username: string;
  userLogo: string;
  refreshToken?: string;
}

const initialState: AuthState = {
  email: '',
  username: '',
  userLogo: '',
  refreshToken: '',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAdminAction: (state, action: PayloadAction<AuthState>) => {
      state.email = action.payload.email;
      state.username = action.payload.username;
      state.userLogo = action.payload.userLogo;
      state.refreshToken = action.payload.refreshToken;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAdminAction } = authSlice.actions;

export const authReducer = authSlice.reducer;
