import { useEffect, useState } from 'react';
import {
  Box,
  Card,
  Table,
  Switch,
  TableBody,
  Container,
  TableContainer,
  TablePagination,
  FormControlLabel,
  Typography,
} from '@mui/material';
import Scrollbar from 'src/components/Scrollbar';
import { TableHeadCustom, TableNoData } from 'src/components/table';
import useTable, { getComparator } from 'src/hooks/useTable';
import { Subscription } from 'src/@types/user';
import UserSubscriptionRow from './UserSubscriptionRow';
import { useSnackbar } from 'notistack';
import { userService } from 'src/services/users';
import LoadingScreen from 'src/components/LoadingScreen';
import moment from 'moment';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'Sr.No', align: 'center' },
  { id: 'plan', label: 'Plan', align: 'center' },
  { id: 'expiry', label: 'Expiry Dt.', align: 'center' },
  { id: 'type', label: 'Status', align: 'center' },
];

// ----------------------------------------------------------------------

type Props = {
  id: number;
};

export default function UserSubscription({ id }: Props) {
  const { dense, order, orderBy, onChangeDense } = useTable({});

  const [tableData, setTableData] = useState<Subscription[]>([]);
  const [currentPlan, setCurrentPlan] = useState<Subscription>();
  const [subscriptionCount, setSubscriptionCount] = useState<number>(0);
  const [loader, setLoader] = useState(true);
  const [filterName, setFilterName] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!filterName) {
      setLoader(true);

      userService
        .listSubscriptionHistory(id, {
          skip: 0,
          limit: rowsPerPage,
        })
        .then((data) => {
          data.result.forEach((plan: Subscription) => {
            const startDate = moment(plan.startDate);
            const condition = startDate.isBetween(
              startDate.clone().startOf('month'),
              startDate.clone().endOf('month')
            );
            if (condition) {
              setCurrentPlan(plan);
            }
          });
          setTableData(data.result);
          setSubscriptionCount(data.count);
          setLoader(false);
        })
        .catch((error) => {
          setLoader(false);
          enqueueSnackbar(error.response.data.message, { variant: 'error' });
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, filterName]);

  const onChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    const rows = parseInt(event.target.value);

    setFilterName('');
    setRowsPerPage(rows);
    setPage(0);
  };

  const onChangePage = (event: unknown, newPage: number) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    if (page < newPage) {
      if (tableData.length > newPage * rowsPerPage) {
        setPage(newPage);
        return;
      }
      setLoader(true);

      userService
        .listSubscriptionHistory(id, {
          skip: 0,
          limit: rowsPerPage * newPage + rowsPerPage,
        })
        .then((data) => {
          setTableData(data.result);
          setLoader(false);
        })
        .catch((error) => {
          setLoader(false);
          enqueueSnackbar(error.response.data.message, { variant: 'error' });
        });
    }

    setPage(newPage);
  };

  const dataFiltered = applySortFilter({
    subscriptionList: tableData,
    comparator: getComparator(order, orderBy),
    filterName,
  });

  const isNotFound = !tableData.length;

  return (
    <Container maxWidth={'xl'} sx={{ mt: 12 }}>
      {loader && <LoadingScreen isDashboard={true} />}

      <Card>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800, position: 'relative', pt: 2 }}>
            <Table size={dense ? 'small' : 'medium'}>
              <TableHeadCustom
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={tableData.length}
              />

              <TableBody>
                {dataFiltered
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <UserSubscriptionRow
                      key={row.id}
                      row={row}
                      index={page === 0 ? index : rowsPerPage * page + index}
                    />
                  ))}

                <TableNoData isNotFound={isNotFound} />
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        <Box sx={{ position: 'relative' }}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={subscriptionCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
          />

          <FormControlLabel
            control={<Switch checked={dense} onChange={onChangeDense} />}
            label="Dense"
            sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
          />
        </Box>
      </Card>
      {currentPlan ? (
        <Card sx={{ p: 4, mt: 4, maxWidth: '300px', float: 'right' }}>
          <Typography variant="h6">Current plan: {currentPlan.subscriptionId_data.name}</Typography>
        </Card>
      ) : (
        <></>
      )}
    </Container>
  );
}

// ----------------------------------------------------------------------

function applySortFilter({
  subscriptionList,
  comparator,
  filterName,
}: {
  subscriptionList: Subscription[];
  comparator: (a: any, b: any) => number;
  filterName: string;
}) {
  const stabilizedThis = subscriptionList.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  subscriptionList = stabilizedThis.map((el) => el[0]);

  return subscriptionList;
}
