import { Button, MenuItem, Stack, TextField, Typography } from '@mui/material';
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import TeamListTable from './TeamListTable';
import { PATH_MASTER } from 'src/routes/paths';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { emptyTeamListAction, teamListAction, TeamState, resetAndListTeamsListAction } from 'src/store/teamSlice';
import { useEffect, useState } from 'react';
import { teamService } from 'src/services/masters/teams';
import { TeamListResponse } from 'src/@types/master';
import LoadingScreen from 'src/components/LoadingScreen';
import { useSnackbar } from 'notistack';
import CustomContainer from 'src/components/upload/CustomContainer';
import { CommonState, setSearch } from 'src/store/commonSlice';

import { sportsService } from 'src/services/sports';
import { setSelectedSportAction, setSportsAction } from 'src/store/sportsSlice';
import { SportsGetAllResponse } from 'src/@types/sports';
import { SportState } from 'src/store/sportsSlice';
// ----------------------------------------------------------------------

export default function Team() {
  const dispatch = useDispatch();
  const teamList = useSelector((state: { team: TeamState }) => state.team);
  const storeSearch = useSelector((state: { common: CommonState }) => state.common.search.team);

  const sports = useSelector((state: { sports: SportState }) => state.sports);
  const [progress, setProgress] = useState(false);
  const [loader, setLoader] = useState(true);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    //gets all sports for drop down
    getAllSports()
  }, []);

  // useEffect(() => {
  //   if (!teamList.teams.length || storeSearch.length) {
  //     if (storeSearch.length) {
  //       dispatch(setSearch({ search: '', type: 'team' }));
  //       dispatch(emptyTeamListAction());
  //     }
  //     // .getTeamList({ sportsId: props.event.sports_data.id })
  //     teamService
  //       .getTeamList({ skip: 0, limit: 5, sportsId: sports.sportSelected?.id  })
  //       .then((data: TeamListResponse) => {
  //         dispatch(teamListAction({ teams: data?.result, totalTeams: data?.count }));
  //         setLoader(false);
  //       })
  //       .catch((error) => {
  //         enqueueSnackbar(error.response.data.message, { variant: 'error' });
  //         setLoader(false);
  //       });
  //   } else {
  //     setLoader(false);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [sports.sports]);


  useEffect(() => {
    teamService
        .getTeamList({ skip: 0, limit: 5, sportsId: sports.sportSelected?.id  })
        .then((data: TeamListResponse) => {
          dispatch(teamListAction({ teams: data?.result, totalTeams: data?.count }));
          setLoader(false);
        })
        .catch((error) => {
          enqueueSnackbar(error.response.data.message, { variant: 'error' });
          setLoader(false);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sports.sports]);

  const getAllSports = () => {

    sportsService
      .getAllSports().then((data: SportsGetAllResponse) => {
        dispatch(setSportsAction(data.result))
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        setLoader(false);
      });
  }

  const handleLoading = (value: boolean, type: string) => {
    if (type === 'LOADER') {
      setLoader(value);
      return;
    }
    setProgress(value);
  };
  console.log('checking STATE -', sports.sports.length > 0 ? sports.sportSelected.id : 1)
  return !loader && sports.sports.length > 0 ? (
    <Page title="teams">
      <CustomContainer maxWidth={'xl'}>
        <Typography variant="h3" component="h1" paragraph>
          Teams
        </Typography>
        <Stack
          spacing={2}
          direction={{ xs: 'column', sm: 'row' }}
          sx={{ justifyContent: 'flex-end' }}
        >

          <TextField
            sx={{
              width: '200px'
            }}
            value={sports?.sportSelected?.id}
            onChange={(e) => {
              console.log('')
              console.log('')
              console.log('CHANGING THE FOLLOWINGF -', e.target.value)
              dispatch(setSelectedSportAction(sports.sports[Number(e.target.value) - 1]))
              teamService
                .getTeamList({ skip: 0, limit: 5, sportsId: Number(e.target.value) })
                .then((data: TeamListResponse) => {
                  console.log('team service data -', data)
                  dispatch(resetAndListTeamsListAction({ teams: data?.result, totalTeams: data?.count,  }));
                  setLoader(false);
                })
              console.log('')
              console.log('')
            }}
            select
            label="Sport Selected"
          >
            {
              sports.sports.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))
            }
          </TextField>

          <Button
            variant="contained"
            startIcon={<Iconify icon={'eva:plus-fill'} />}
            sx={{ py: 1.5 }}
            component={RouterLink}
            to={PATH_MASTER.teamNew}
          >
            Add Team
          </Button>
        </Stack>
      </CustomContainer>

      <TeamListTable progress={progress} onLoading={handleLoading} />
    </Page>
  ) : (
    <LoadingScreen />
  );
}
