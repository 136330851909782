import {
  Container,
  Card,
  TableContainer,
  Table,
  TableBody,
  TablePagination,
  FormControlLabel,
  Switch,
  Box,
  Divider,
  Tab,
  Tabs,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { DivisionList } from 'src/@types/master';
import LoadingScreen from 'src/components/LoadingScreen';
import Scrollbar from 'src/components/Scrollbar';
import { TableHeadCustom, TableNoData } from 'src/components/table';
import useTable, { getComparator } from 'src/hooks/useTable';
import { eventPrivateerService } from 'src/services/eventPrivateer';
import { divisionService } from 'src/services/masters/divisions';
import UserTableToolbar from '../Users/UserTableToolbar';
import RacerTableRow from './RacerTableRow';

// ----------------------------------------------------------------------
const STATUS_OPTIONS = ['250cc', '450cc'];

const TABLE_HEAD = [
  { id: 'id', label: 'Sr.No', align: 'center' },
  { id: 'racer_name', label: 'Racer Name', align: 'center' },
  { id: 'manufacturer', label: 'Manufacturer', align: 'center' },
  { id: 'description', label: 'Description', align: 'center' },
];

// ----------------------------------------------------------------------

export type RacerRow = {
  id: number;
  eventId: number;
  racerId: number;
  divisionId: number;
  privateer_data: { name: string; description: string; manufacturer_data: { name: string } };
};

type Props = {
  id: number;
};

export default function EventRacersTable({ id }: Props) {
  const {
    dense,
    order,
    orderBy,
    selected,
    onSelectRow,
    rowsPerPage,
    page,
    setPage,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({ defaultOrderBy: 'ranking' });

  const [tableData, setTableData] = useState<RacerRow[]>([]);
  const [resultsCount, setResultsCount] = useState<number>(0);
  const [loader, setLoader] = useState(true);
  const [filterName, setFilterName] = useState('');
  const [filterStatus, setFilterStatus] = useState('250cc');
  const [divisionList, setDivisionList] = useState<DivisionList[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    divisionService
      .getDivisionList({})
      .then((data) => {
        let division = data.result.find((div: DivisionList) => div.name === filterStatus);

        setDivisionList(data.result);

        eventPrivateerService
          .getRacers(id, division.id)
          .then((data) => {
            setTableData(data.result);
            setResultsCount(data?.result?.length);
            setLoader(false);
          })
          .catch((error) => {
            setLoader(false);
            enqueueSnackbar(error.response.data.message, { variant: 'error' });
          });
      })
      .catch((error) => {
        setLoader(false);
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeFilterStatus = (event: React.SyntheticEvent<Element, Event>, newValue: string) => {
    setFilterName('');
    setTableData([]);
    setFilterStatus(newValue);
    setLoader(true);

    let division = divisionList.find((div: DivisionList) => div.name === newValue);

    if (division) {
      eventPrivateerService
        .getRacers(id, division?.id)
        .then((data) => {
          setTableData(data.result);
          setResultsCount(data?.result?.length);
          setLoader(false);
        })
        .catch((error) => {
          setLoader(false);
          setTableData([]);
          setResultsCount(0);
          enqueueSnackbar(error.response.data.message, { variant: 'error' });
        });
    }

    setPage(0);
  };

  const handleFilterName = (filterName: string) => {
    setFilterName(filterName);
    setPage(0);
  };

  const dataFiltered = applySortFilter({
    racerList: tableData,
    comparator: getComparator(order, orderBy),
    filterName,
  });

  const isNotFound = !tableData.length || (!dataFiltered.length && !!filterName);

  return (
    <Container maxWidth={'xl'} sx={{ mt: 15 }}>
      {loader && <LoadingScreen isDashboard={true} />}

      <Card>
        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={filterStatus}
          onChange={onChangeFilterStatus}
          sx={{ px: 2, bgcolor: 'background.neutral' }}
        >
          {STATUS_OPTIONS.map((tab) => (
            <Tab disableRipple key={tab} label={tab} value={tab} />
          ))}
        </Tabs>

        <Divider />

        <UserTableToolbar
          placeholder="Search by Racer..."
          isSearch={false}
          filterName={filterName}
          onFilterName={handleFilterName}
        />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
            <Table size={dense ? 'small' : 'medium'}>
              <TableHeadCustom
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={tableData.length}
                minWidth={'128px'}
              />

              <TableBody>
                {dataFiltered
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <RacerTableRow
                      key={row.id}
                      index={page === 0 ? index : rowsPerPage * page + index}
                      row={row}
                      selected={selected.includes(row.id)}
                      onSelectRow={() => onSelectRow(row.id)}
                    />
                  ))}

                <TableNoData isNotFound={isNotFound} />
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        <Box sx={{ position: 'relative' }}>
          <TablePagination
            rowsPerPageOptions={[5, 15, 25]}
            component="div"
            count={resultsCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
          />

          <FormControlLabel
            control={<Switch checked={dense} onChange={onChangeDense} />}
            label="Dense"
            sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
          />
        </Box>
      </Card>
    </Container>
  );
}

// ----------------------------------------------------------------------

function applySortFilter({
  racerList,
  comparator,
  filterName,
}: {
  racerList: RacerRow[];
  comparator: (a: any, b: any) => number;
  filterName: string;
}) {
  const stabilizedThis = racerList.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  racerList = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    racerList = racerList.filter(
      (item: Record<string, any>) =>
        item.privateer_data.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    );
  }

  return racerList;
}
