import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';

type MultiDeleteProps = {
  text: string;
  openMultiAlert: boolean;
  onMultiCloseAlert: Function;
  onMultiDelete: Function;
};

export default function MultiDeletePopup({
  text,
  openMultiAlert,
  onMultiCloseAlert,
  onMultiDelete,
}: MultiDeleteProps) {
  return (
    <Dialog open={openMultiAlert}>
      <DialogTitle>Delete Division ? </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" paddingTop={3}>
          Are you sure that you want to delete all selected
          <strong> "{text}" </strong>?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onMultiCloseAlert();
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            onMultiDelete();
            onMultiCloseAlert();
          }}
          autoFocus
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
